
import { Component, OnInit, OnChanges, AfterViewInit, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppTranslationService } from '../../services/app-translation.service';
import { AlertService, DialogType } from '../../services/alert.service';
import { PersonCalendarService } from "../../services/personcalendar.service";
import { Employee } from '../../models/employee.model';
import { PersonCalendar, CalendarColumns } from '../../models/personcalendar.model';
import { AuthService } from '../../services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { BaseCalendar } from '../../models/basecalendar.model';
import { BaseCalendarService } from '../../services/basecalendar.service';
import { SharedDataService } from '../../services/shareddata.service';
import { LocalStoreManager } from '../../services/local-store-manager.service';

@Component({
  selector: 'app-persons-calendarlist',
  templateUrl: './persons-calendarlist.component.html',
  styleUrls: ['./persons-calendarlist.component.scss']
})
export class PersonsCalendarListComponent implements OnInit, AfterViewInit {
  @ViewChild('form', { static: true })

  //private form: NgForm;
  public isNewEmployee = false;
  employees: Employee[] = [];
  displayedColumns: string[] = CalendarColumns.map((col) => col.key)
  columnsSchema: any = CalendarColumns
  dataSource = new MatTableDataSource<PersonCalendar>();
  public personcalendars: MatTableDataSource<PersonCalendar>;
  personcals: PersonCalendar[] = [];
  basecalendars: BaseCalendar[] = [];
  sourceCalendar: PersonCalendar;
/*  editingCalendarName: { name: string };*/
  public show: boolean = true;
  defaultPageIndex: number = 0;
  defaultPageSize: number = 10;
  public defaultSortColumn: string = "startdate";
  public defaultSortOrder: string = "desc";
  siteName: string;
  defaultFilterColumn: string = "startdate";
  filterQuery: string = null;
  tableNumber: number = 0;
  isEdit: boolean = false;
  tableIndex: number;
  valid: any = {};
  adding: boolean = false;
  empId: string = null;
  writeRight: boolean = false;

  @Input() employee: Employee = new Employee();
  @Input() wr: boolean;
  @Input() isEditMode = true;
  personCalendarForm: FormGroup ;
 
  get employeeId() {
    return this.personCalendarForm.get('employeeId');
  }

  get employee_id() {
    return this.personCalendarForm.get('employee_id');
  }

  get surname() {
    return this.personCalendarForm.get('surname');
  }

  get forename() {
    return this.personCalendarForm.get('forename');
  }

  get employeeNo() {
    return this.personCalendarForm.get('employeeNo');
  }

  get floatLabels(): string {
    return this.isEditMode ? 'auto' : 'always';
  }

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private translationService: AppTranslationService,
    public personCalendarService: PersonCalendarService,
    private baseCalendarService: BaseCalendarService,
    private sharedDataService: SharedDataService,
    private fb: FormBuilder,
    public localStorage: LocalStoreManager
  ) {
    this.buildForm();
    this.writeRight = this.wr;
  }

  ngOnInit() {
    this.sharedDataService.currentFormData.subscribe(formData => {
      const rowId = this.employeeId.value;
      if (formData[rowId]) {
        for (const field in formData[rowId]) {
          if (formData[rowId].hasOwnProperty(field) && this.personCalendarForm.controls[field]) {
            this.personCalendarForm.controls[field].setValue(formData[rowId][field], { emitEvent: false });
          }
        }
      }
    });
    CalendarColumns[0].label = this.translationService.getTranslation('persons.StartDate');
    CalendarColumns[1].label = this.translationService.getTranslation('persons.EndDate');
    CalendarColumns[2].label = this.translationService.getTranslation('persons.Calendar');
    CalendarColumns[3].label = this.translationService.getTranslation('persons.CalendarDescr');
    CalendarColumns[4].label = this.translationService.getTranslation('persons.WorkTimePercent');
    this.loadCalendarData()
    this.baseCalendarService.getBaseCalendars()
      .subscribe(data => {
        this.basecalendars = data;
      },
        (error: any) => {
          //console.log(error);
        });

  }

  ngOnChanges() {
    if (this.employee) {
      this.isNewEmployee = false;
    } else {
      this.isNewEmployee = true;
      this.employee = new Employee();
    }
    if (this.wr) {
      this.writeRight = this.wr;
    }
    this.resetForm();
  }

  loadCalendarData() {
    this.personCalendarService.getCalendars(this.employee_id.value).subscribe((res: any) => {
      this.dataSource.data = res;
      this.personcals = res;
    })
  }

  ngAfterViewInit() {
    //this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
  }

  private cancel() {
    this.resetForm();
  }

  private buildForm() {
    this.personCalendarForm = this.fb.group({
      employeeId: null,
      employee_id: 0,
      surname: ['', Validators.required],
      forename: ['', Validators.required],
      employeeNo: ['', Validators.required]
    });
  }

  get canManagePersonCalendars() {
    return true;
  }

  public confirmDeleteCalendar(row: PersonCalendar) {
    let delQ = this.translationService.getTranslation('notifications.DeleteRecord');
    this.alertService.showDialog(delQ + '?',
      DialogType.confirm, () => this.removePersonCalendar(row));
  }

  private resetForm(stopEditing = false) {
    if (stopEditing) {
      this.isEditMode = false;
    }

    if (!this.employee) {
      this.isNewEmployee = true;
      this.employee = new Employee();
    }
    //console.log("CAL ResetForm empl " + this.isNewEmployee + " ssn " + this.employee.ssn + " pwd " + this.employee.guserPwd);

    this.personCalendarForm.reset({
      employeeId: this.employee.employeeId || '',
      employee_id: this.employee.employee_id || 0,
      surname: this.employee.surname || '',
      forename: this.employee.forename || '',
      employeeNo: this.employee.employeeNo || ''
    });
  }

  //editCalendar(row: PersonCalendar) {
  //  row.isEdit = true;
  //}

  editRow(row: PersonCalendar) {
    this.adding = false;
    row.employeeId = this.employeeId.value;
    row.employee_id = this.employee_id.value;
    if (row.employeeId == null || row.employeeId == '')
      row.employeeId = this.localStorage.getDataObject("employee_id");
    //this.empId = this.localStorage.getDataObject("employee_id");
    //row.employeeId = this.empId;
    let index = this.basecalendars.findIndex(x => x.basecalendarId === row.baseCalendarId)
    row.baseCalendarDescr = this.basecalendars[index].description;
    if (row.id === -1) {
      this.personCalendarService.addPersonCalendar(row)
        .subscribe(response => this.saveSuccessHelper(true, row),
          error => {
            if (error == "Unauthorized")
              this.authService.logout();
            else
              this.loadCalendarData();
          });
      }
    else {
      this.personcals[row.id].baseCalendarDescr = this.basecalendars[index].description;
      this.personCalendarService.updatePersonCalendar(row)
        .subscribe(response => this.saveSuccessHelper(false, row),
          error => {
            if (error == "Unauthorized")
              this.authService.logout();
            else
              this.loadCalendarData();
          });
    }
  }


  private saveSuccessHelper(newCal, row) {
    let mess: string;
    row.isEdit = false;
    if (newCal) {
      mess = this.translationService.getTranslation('notifications.InsertRecordSucceeded');
    } else {
      mess = this.translationService.getTranslation('notifications.UpdateRecordSucceeded');
    }
    this.alertService.successMessage(mess);
  }

  addRow() {
    this.adding = true;
    const newRow: PersonCalendar = {
      id: -1,
      startDate: null,
      endDate: null,
      baseCalendarId: 0,
      baseCalendarDescr: null,
      worktimePercentage: '',
      employeeId: null,
      employee_id: 0,
      isEdit: true
    }
    this.dataSource.data = [newRow, ...this.dataSource.data]
  }

  inputHandler(e: any, id: number, key: string) {
    if (!this.valid[id]) {
      this.valid[id] = {}
    }
    this.valid[id][key] = e.target.validity.valid
  }

  disableSubmit(id: number) {
    if (this.valid[id]) {
      return Object.values(this.valid[id]).some((item) => item === false)
    }
    return false
  }

  removePersonCalendar(row: PersonCalendar) {
      this.personCalendarService.deletePersonCalendar(this.employee_id.value, row.startDate, row.baseCalendarId)
      .subscribe(results => {
        this.alertService.successMessage(this.translationService.getTranslation('notifications.DeleteOk'));
        this.loadCalendarData();
      },
        error => {
        });
  }

  cancelCalendar() {
  }

  get canManageEmployees() {
    return true;
  }
}
