<form (keydown.enter)="loadData()">
  <div class="page-content">
    <h3>{{'menu.Devices' | translate}}</h3>
    <div fxLayout="row" fxLayoutGap="10px">
      <div fxFlex.gt-sm="40%" fxLayout="row" fxLayoutAlign="start align-center" fxLayoutGap="10px">
        <mat-form-field fxFlex="grow" [formGroup]="searchForm">
          <input matInput formControlName="searchName" placeholder="{{'common.FilterByName' | translate}}">
          <button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchForm.get('searchName').value" (click)="clearSearchField()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <button mat-raised-button color="primary" [disabled]="isNewDevice" class="custom-button" (click)="loadData()">{{'common.Refresh' | translate}}</button>
      </div>
      <div fxFlex.gt-sm="60%" fxLayout="row" fxLayoutAlign="start align-center" fxLayoutGap="10px">
        <button mat-raised-button (click)="showFirstRecord()" [disabled]="isNewDevice || (currentIndex === 0)"><span class="material-icons">first_page</span></button>
        <button mat-raised-button (click)="showPreviousRecord()" [disabled]="isNewDevice || (currentIndex === 0)"><span class="material-icons">navigate_before</span></button>
        <button mat-raised-button (click)="showNextRecord()" [disabled]="isNewDevice || (currentIndex === devices.length - 1)"><span class="material-icons">navigate_nextr</span></button>
        <button mat-raised-button (click)="showLastRecord()" [disabled]="isNewDevice || (currentIndex === devices.length - 1)"><span class="material-icons">last_page</span></button>
        <button mat-raised-button color="primary" [disabled]="!writeRight" (click)="createDevice()">{{'device.NewDevice' | translate}}</button>
        <button mat-raised-button color="warn" [disabled]="!writeRight || isNewDevice|| !devIsTablet" (click)="confirmDelete(device)">{{'device.DeleteDevice' | translate}}</button>
      </div>
    </div>
    <div [formGroup]="deviceForm">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="2px">
        <div fxFlex.gt-sm="50%">
          <div fxLayout="column" fxLayoutGap="2px">
            <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
              <input type="hidden" formControlName="deviceId">
              <mat-form-field [floatLabel]="floatLabels" fxFlex="50">
                <mat-label>{{'device.Address' | translate}}</mat-label>
                <input matInput formControlName="address" [readonly]="!isNewDevice" [attr.tabindex]="!isNewDevice ? -1 : null" />
              </mat-form-field>
              <mat-form-field fxFlex="50">
                <mat-label>{{'device.Name' | translate}}</mat-label>
                <input matInput formControlName="name" />
              </mat-form-field>
              <mat-form-field fxFlex="50">
                <mat-label>{{'device.DeviceType' | translate}}</mat-label>
                <input matInput type="number" formControlName="devicetype" min="2" max="3" />
              </mat-form-field>
              <mat-slide-toggle formControlName="isTablet">Is Tablet</mat-slide-toggle>
            </div>
            <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
              <mat-form-field [floatLabel]="floatLabels" fxFlex>
                <mat-label>{{'common.Descr' | translate}}</mat-label>
                <input matInput formControlName="description" />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>&nbsp;</div>
    <div mat-dialog-actions style="display: flex; justify-content: space-between; align-items: center;">
      <div>
        <button mat-raised-button (click)="saveDevice();" [disabled]="!writeRight || !deviceForm.dirty || !devIsTablet" color="primary">{{'common.Save' | translate}}</button>
        <button mat-raised-button (click)="cancel()" color="warn"> {{'common.Cancel' | translate}}</button>
      </div>
      <div class="record-count">
        {{'common.Record' | translate}} {{currentIndex + 1}} / {{devices.length}}
      </div>
    </div>
  </div>
</form>
















