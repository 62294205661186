import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export abstract class BaseService {
  constructor(
    public http: HttpClient,
    public baseUrl: string
  ) {
  }

  abstract getData<ApiResult>(
    tableNumber: number,
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: string,
    filterColumn: string,
    filterQuery: string): Observable<ApiResult>;

  get<T>(id: number): any { };
  put<T>(item: T): any { };
  post<T>(item: T): any { };
}

export interface ApiResult<T> {
  data: T[];
  pageIndex: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
  sortColumn: string;
  sortOrder: string;
  filterColumn: string;
  filterQuery: string;
}
