<form [formGroup]="hierarchyForm" fxLayout="column" autocomplete="off">
  <div class="container">
    <h3>{{'menu.OrganizationHierarchy' | translate}}</h3>
  </div>
  <mat-grid-list cols="2" rowHeight="600px" class="myGridList" style="width: 100%;">
    <mat-grid-tile class="my-grid-tile">
      <div class="tree-container">
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
          <mat-tree-node *matTreeNodeDef="let node" [ngClass]="{'selected': selectedNode === node}" (click)="selectNode(node)">
            {{node.name}}
          </mat-tree-node>
          <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
            <div>
              <button mat-icon-button [attr.aria-label]="'Toggle ' + node.name" matTreeNodeToggle>
                <mat-icon class="mat-icon-rtl-mirror">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              <span [ngClass]="{ 'selected-node': selectedNode === node }" (click)="selectNode(node)">
                {{ node.name }}
              </span>
              <div [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                <ul>
                  <ng-container matTreeNodeOutlet></ng-container>
                </ul>
              </div>
            </div>
          </mat-nested-tree-node>
        </mat-tree>
      </div>
    </mat-grid-tile>
    <mat-grid-tile class="my-grid-tile">
      <div class="form-controls-container">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
          <input type="hidden" formControlName="companyId">
          <mat-form-field fxFlex="100px">
            <mat-label>{{'common.Company' | translate}}</mat-label>
            <input matInput type="number" min="1" max="9999" formControlName="company" />
            <mat-error *ngIf="company.hasError('min') || company.hasError('max')">{{'notifications.ValueBetween1_9999' | translate}}"</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="200px">
            <mat-label>{{'common.Descr' | translate}}</mat-label>
            <input matInput maxlength="40" formControlName="companyName" />
          </mat-form-field>
          <div fxFlex="100px" class="button-container">
            <!-- Set a fixed width for the button container -->
            <button mat-icon-button matTooltip="{{'common.Edit' | translate}}" (click)="editCompany()" [disabled]="!writeRight || hierarchyForm.controls['company'].disabled">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button matTooltip="{{'common.Delete' | translate}}" (click)="deleteCompany()" [disabled]="!writeRight || hierarchyForm.controls['company'].disabled">
              <mat-icon color="warn">delete</mat-icon>
            </button>
            <button mat-icon-button matTooltip="{{'common.Add' | translate}}" (click)="createCompany()" [disabled]="!writeRight || hierarchyForm.controls['company'].disabled">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
          <input type="hidden" formControlName="factoryId">
          <mat-form-field fxFlex="100px">
            <mat-label>{{'common.Factory' | translate}}</mat-label>
            <input matInput type="number" min="1" max="9999" formControlName="factory" />
            <mat-error *ngIf="factory.hasError('min') || factory.hasError('max')">{{'notifications.ValueBetween1_9999' | translate}}"</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="200px">
            <mat-label>{{'common.Descr' | translate}}</mat-label>
            <input matInput maxlength="40" formControlName="factoryName" />
          </mat-form-field>
          <div fxFlex="100px" class="button-container">
            <!-- Set a fixed width for the button container -->
            <button mat-icon-button matTooltip="{{'common.Edit' | translate}}" (click)="editFactory()" [disabled]="!writeRight || hierarchyForm.controls['factory'].disabled">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button matTooltip="{{'common.Delete' | translate}}" (click)="deleteFactory()" [disabled]="!writeRight || hierarchyForm.controls['factory'].disabled">
              <mat-icon color="warn">delete</mat-icon>
            </button>
            <button mat-icon-button matTooltip="{{'common.Add' | translate}}" (click)="createFactory()" [disabled]="!writeRight || hierarchyForm.controls['factory'].disabled">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
          <input type="hidden" formControlName="departmentId">
          <mat-form-field fxFlex="100px">
            <mat-label>{{'common.Department' | translate}}</mat-label>
            <input matInput type="number" min="1" max="9999" formControlName="department" />
            <mat-error *ngIf="department.hasError('min') || department.hasError('max')">{{'notifications.ValueBetween1_9999' | translate}}"</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="200px">
            <mat-label>{{'common.Descr' | translate}}</mat-label>
            <input matInput maxlength="40" formControlName="departmentName" />
          </mat-form-field>
          <div fxFlex="100px" class="button-container">
            <!-- Set a fixed width for the button container -->
            <button mat-icon-button matTooltip="{{'common.Edit' | translate}}" (click)="editDepartment()" [disabled]="!writeRight || hierarchyForm.controls['department'].disabled">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button matTooltip="{{'common.Delete' | translate}}" (click)="deleteDepartment()" [disabled]="!writeRight || hierarchyForm.controls['department'].disabled">
              <mat-icon color="warn">delete</mat-icon>
            </button>
            <button mat-icon-button matTooltip="{{'common.Add' | translate}}" (click)="createDepartment()" [disabled]="!writeRight || hierarchyForm.controls['department'].disabled">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
          <input type="hidden" formControlName="sectionId">
          <mat-form-field fxFlex="100px">
            <mat-label>{{'common.Section' | translate}}</mat-label>
            <input matInput type="number" min="1" max="9999" formControlName="section" />
            <mat-error *ngIf="section.hasError('min') || section.hasError('max')">{{'notifications.ValueBetween1_9999' | translate}}"</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="200px">
            <mat-label>{{'common.Descr' | translate}}</mat-label>
            <input matInput maxlength="40" formControlName="sectionName" />
          </mat-form-field>
          <div fxFlex="100px" class="button-container">
            <!-- Set a fixed width for the button container -->
            <button mat-icon-button matTooltip="{{'common.Edit' | translate}}" (click)="editSection()" [disabled]="!writeRight || hierarchyForm.controls['section'].disabled">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button matTooltip="{{'common.Delete' | translate}}" (click)="deleteSection()" [disabled]="!writeRight || hierarchyForm.controls['section'].disabled">
              <mat-icon color="warn">delete</mat-icon>
            </button>
            <button mat-icon-button matTooltip="{{'common.Add' | translate}}" (click)="createSection()" [disabled]="!writeRight || hierarchyForm.controls['section'].disabled">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</form>

