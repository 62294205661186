export class SetPassword {
  sitename: string;
  employeeid: string;
  username: string;
  oldpasswd: string;
  newpasswd: string;
  language: string;
  gUser: boolean;
  onlyCheck: boolean;
}
