import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'persons-tooltip.component',
  templateUrl: 'persons-tooltip.component.html',
  styleUrls: ['./persons-tooltip.component.scss']

})
export class PersonsToolTipDialog {
  constructor(
    public dialogRef: MatDialogRef<PersonsToolTipDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.myComment = data.myComment;
  }
  myComment: string;
}
