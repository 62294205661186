import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStoreManager } from './local-store-manager.service';
import { Profession } from '../models/profession.model';

@Injectable()
export class ProfessionService {
  private gendersUrl: string = 'api/professions';

  constructor(
    private http: HttpClient,
    private localStorage: LocalStoreManager) { }

  getProfessions(): Observable<Profession[]> {
    let sitename = this.localStorage.getDataObject("sitename");
    let endpointUrl = `${this.gendersUrl}/${sitename}`;
    return this.http.get<Profession[]>(endpointUrl);
  }

}

