import { NgModule } from '@angular/core';
import { SharedModule } from '../shared.module';
import { SharedMaterialModule } from '../shared-mat.module';
import { TimeAttendanceRoutingModule } from './timeattendance-routing.module';
import { TimeAttendanceComponent } from './timeattendance.component';
import { TaBasetableComponent } from './tabasetable/tabasetable.component';
import { TaBasetableEditorComponent } from './tabasetable/tabasetable-editor.component';
import { TabasetableDialogComponent } from './tabasetable/tabasetable-dialog.component';
import { AbsenceCodeEditorComponent } from './tabasetable/absencecode-editor.component';
import { WorkNumberEditorComponent } from './tabasetable/worknumber-editor.component';

import { AcceptLevelService } from '../services/acceptlevel.service';
import { SaldoTypeService } from "../services/saldotype.service";
import { CalcRuleService } from "../services/calcrule.service";
import { AbsenceCodeService } from "../services/absencecode.service";
import { WorkNumberService } from "../services/worknumber.service";
import { OrganizationService } from '../services/organization.service';
/*import { TaBasetableService } from '../services/tabasetable.service';*/
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  imports: [
    SharedModule,
    SharedMaterialModule,
    TimeAttendanceRoutingModule
    //CommonModule,
    //MatDialogModule,
    //MatFormFieldModule,
    //MatInputModule      
  ],
  declarations: [
    TimeAttendanceComponent,
    TaBasetableEditorComponent,
    TabasetableDialogComponent,
    AbsenceCodeEditorComponent,
    WorkNumberEditorComponent,
    TaBasetableComponent
  ],
  providers: [
    AcceptLevelService,
    SaldoTypeService,
    CalcRuleService,
    WorkNumberService,
    AbsenceCodeService
/*    TaBasetableService*/
  ]
})
export class TimeAttendanceModule {
}
