<form (keydown.enter)="loadData()">
  <div class="page-content">
    <h3>{{'menu.UserTypes' | translate}}</h3>
    <div fxLayout="row" fxLayoutGap="10px">
      <div fxFlex.gt-sm="40%" fxLayout="row" fxLayoutAlign="start align-center" fxLayoutGap="10px">
        <mat-form-field fxFlex="grow" [formGroup]="searchForm">
          <input matInput formControlName="searchName" placeholder="{{'common.nameSearch' | translate}}">
          <button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchForm.get('searchName').value" (click)="clearSearchField()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <button mat-raised-button color="primary" class="custom-button" (click)="loadData()">{{'common.Refresh' | translate}}</button>
      </div>
      <div fxFlex.gt-sm="60%" fxLayout="row" fxLayoutAlign="start align-center" fxLayoutGap="10px">
        <button mat-raised-button (click)="showFirstRecord()" [disabled]="currentIndex === 0"><span class="material-icons">first_page</span></button>
        <button mat-raised-button (click)="showPreviousRecord()" [disabled]="currentIndex === 0"><span class="material-icons">navigate_before</span></button>
        <button mat-raised-button (click)="showNextRecord()" [disabled]="currentIndex === userTypes.length - 1"><span class="material-icons">navigate_nextr</span></button>
        <button mat-raised-button (click)="showLastRecord()" [disabled]="currentIndex === userTypes.length - 1"><span class="material-icons">last_page</span></button>
        <button mat-raised-button color="primary" [disabled]="!writeRight" (click)="createUserType()">{{'usertype.NewUserType' | translate}}</button>
        <button mat-raised-button color="warn" [disabled]="!writeRight || isNewUserType" (click)="confirmDelete(usertype)">{{'usertype.DeleteUserType' | translate}}</button>
      </div>
    </div>
    <div [formGroup]="userTypeForm">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="2px">
        <div fxLayout="column" fxLayoutGap="2px">
          <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
            <input type="hidden" formControlName="user_type_id">
            <mat-form-field [floatLabel]="floatLabels" fxFlex="25%">
              <mat-label>{{'usertype.Usertype' | translate}}</mat-label>
              <input matInput formControlName="title" [readonly]="!isNewUserType" [attr.tabindex]="!isNewUserType ? -1 : null" />
              <mat-error *ngIf="title.hasError('required')">
                {{'notifications.FieldRequired' | translate}}
              </mat-error>
            </mat-form-field>
            <mat-form-field [floatLabel]="floatLabels" fxFlex="25%">
              <mat-label>{{'common.Descr' | translate}}</mat-label>
              <input matInput formControlName="descr" />
            </mat-form-field>
            <mat-form-field [floatLabel]="floatLabels" fxFlex="50%">
              <mat-label>{{'usertype.AdditionalInfo' | translate}}</mat-label>
              <input matInput formControlName="text" />
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
            <mat-form-field>
              <mat-label>{{'usertype.AdminUser' | translate}}</mat-label>
              <mat-select formControlName="admin_rights">
                <mat-option *ngFor="let yesno of yesnos" [value]="yesno.id">
                  {{ yesno.descr }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{'usertype.GeneralUser' | translate}}</mat-label>
              <mat-select formControlName="guser_type">
                <mat-option *ngFor="let yesno of yesnos" [value]="yesno.id">
                  {{ yesno.descr }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>


    <div fxLayout="row" fxLayoutGap="10px">
      <button mat-raised-button color="primary" [disabled]="isNewUserType" (click)="AddUserRights()">{{'usertype.UserRights' | translate}}</button>
      <button mat-raised-button color="accent"[disabled]="isNewUserType"(click)="AddAccessRights()">{{'usertype.AccessRights' | translate}}</button>
    </div>
    <div>&nbsp;</div>
    <div mat-dialog-actions style="display: flex; justify-content: space-between; align-items: center;">
      <div>
        <button mat-raised-button (click)="saveUserType();" [disabled]="!writeRight || !userTypeForm.dirty" color="primary">{{'common.Save' | translate}}</button>
        <button mat-raised-button (click)="cancel()" color="warn"> {{'common.Cancel' | translate}}</button>
      </div>
      <div class="record-count">
        {{'common.Record' | translate}} {{currentIndex + 1}} / {{userTypes.length}}
      </div>
    </div>
  </div>
</form>
















