export class Organization {
  type: number;
  companyNumber: number;
  factoryNumber: number;
  departmentNumber: number;
  sectionNumber: number;
  description: string;
  isAllowed: number;
  id: number;
  user: string;
  system: string;
  showSvHierarchy: boolean;
}
