<form #form="ngForm" [formGroup]="personAuxdataForm" novalidate (ngSubmit)="save()" fxLayout="column" [fxLayoutGap]="isEditMode ? '1em' : '0'" autocomplete="off">
  <mat-form-field class="invisible example-full-width">
    <input matInput formControlName="tablenumber" [readonly]="true" />
  </mat-form-field>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field [floatLabel]="floatLabels" fxFlex="150px">
      <mat-label>{{'common.Number' | translate}}</mat-label>
      <input matInput max="32767" formControlName="number" [readonly]="!isNewTable" [attr.tabindex]="!isNewTable ? -1 : null" />
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field [floatLabel]="floatLabels" fxFlex="400px">
      <mat-label>{{'common.Descr' | translate}}</mat-label>
      <input matInput formControlName="description" maxlength="40"/>
    </mat-form-field>
    <mat-error *ngIf="description.hasError('required')">
      {{'notifications.FieldRequired' | translate}}
    </mat-error>
  </div>
  <div>&nbsp;</div>
</form>

