import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmComponent } from '../components/confirm/confirm.component';
import { ConfirmDialogData } from '../models/confirm-dialog-data';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  confirmDialog(data: ConfirmDialogData): Observable<boolean> {
    return this.dialog
      .open(ConfirmComponent, {
        data,
        width: '400px',
        disableClose: true,
      })
      .afterClosed();
  }

  confirmDialogMessage(message: string): Observable<boolean> {
    return this.dialog
      .open(ConfirmComponent, {
        data: {
          title: "Otsikko",
          message: message,
          confirmCaption: 'Yes',
          cancelCaption: 'Cancel',
        },
        width: '400px',
        disableClose: true,
      })
      .afterClosed();
  }

  //public confirmFullDelete(abText: string) {
  //  const dialogRef = this.dialog.open(ConfirmComponent,
  //    {
  //      data: {
  //        title: "Otsikko2",
  //        message: abText,
  //        confirmCaption: 'Yes',
  //        cancelCaption: 'Cancel',
  //      },
  //      width: '400px',
  //      disableClose: true,
  //    });
  //  dialogRef.afterClosed().subscribe((result: number) => {
  //    console.log("OK" + result);
  //    if (result == 4)
  //      this.confirmFullStampDelete(employee);
  //    else if (result > 1)
  //      this.deleteStamp(employee, result);
  //    console.log("OK" + result);
  //  });
  //}
}
