
import { Component, OnDestroy, Input, ViewChild, OnChanges } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { AppTranslationService } from '../../services/app-translation.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { PersonNameService } from "../../services/personname.service";
import { ProjectService } from '../../services/project.service';
import { Project } from '../../models/project.model';
import { ProjectPerson } from '../../models/projectperson.model';
import { Person } from '../../models/person.model';

@Component({
  selector: 'app-projectperson-editor',
  templateUrl: './projectperson-editor.component.html',
  styleUrls: ['./projectperson-editor.component.scss']
})
export class ProjectPersonEditorComponent implements OnChanges {
  @ViewChild('form', { static: true })
  private form: NgForm;

  public isNewProject = false;
  private onProjectSaved = new Subject<ProjectPerson>();
  persons: Person[] = [];
  employee_id: string ;

  @Input() projectPerson: ProjectPerson = new ProjectPerson();
  @Input() projectNumber: number;
  @Input() isEditMode = true;

  projectForm: FormGroup;
  projectSaved$ = this.onProjectSaved.asObservable();

  get employeeId() {
    return this.projectForm.get('employeeId');
  }

  get name() {
    return this.projectForm.get('name');
  }

  get startDate() {
    return this.projectForm.get('startDate');
  }

  get endDate() {
    return this.projectForm.get('endDate');
  }

  get targetTime() {
    return this.projectForm.get('targetTime');
  }

  get floatLabels(): string {
    return this.isEditMode ? 'auto' : 'always';
  }

  constructor(
    private alertService: AlertService,
    private projectService: ProjectService,
    private translationService: AppTranslationService,
    public personNameService: PersonNameService,
    private formBuilder: FormBuilder
  ) {
    this.buildForm();
  }

  ngOnInit() {
    this.getPerson();
  }

  ngOnChanges() {
    console.log("PPEdit " + this.projectPerson.id + " PROJ " + this.projectNumber + " empId " + this.projectPerson.employeeId );
    if (this.projectPerson.id != null) {
      console.log("OLD Proj " + this.projectPerson.employeeId + " " + this.projectPerson.name);
      this.isNewProject = false;
      this.projectForm.get('employeeId')?.disable();
    } else {
      console.log("NEW Proj ");
      this.isNewProject = true;
      this.projectForm.get('employeeId')?.enable(); 
      this.projectPerson = new ProjectPerson();
    }

    this.resetForm();
  }

  public save() {
    if (!this.form.submitted) {
      this.form.onSubmit(null);
      return;
    }

    if (!this.projectForm.valid) {
/*      this.alertService.showValidationError();*/
      return;
    }

    var editedProject = this.getEditedProject();
    if (editedProject.targetTime != null )
      editedProject.targetTime = Number(editedProject.targetTime);
    editedProject.name = this.projectPerson.name;
    editedProject.projectNumber = this.projectNumber;
    if (this.isNewProject) {
      this.projectService.newProjectPerson(editedProject).subscribe(
        projectPerson => this.saveSuccessHelper(projectPerson),
        error => this.saveFailedHelper(error));

    } else {
      this.projectService.updateProjectPerson(editedProject).subscribe(
        response => this.saveSuccessHelper(editedProject),
        error => this.saveFailedHelper(error));
    }
  }

  getPerson() {
    this.personNameService.getpersonnames("")
      .subscribe(results => {
        this.persons = results;
      },
        (error: any) => {
          console.log(error);
        });
  }


  private getEditedProject(): ProjectPerson {
    const formModel = this.projectForm.value;

    return {
      id: this.projectPerson.id,
      projectNumber: this.projectPerson.projectNumber,
      projectName: formModel.projectName,
      startDate: formModel.startDate,
      endDate: formModel.endDate,
      employeeId: formModel.employeeId,
      name: formModel.personName,
      targetTime: formModel.targetTime
    };
  }

  private saveSuccessHelper(projectperson?: ProjectPerson) {
    let mess: string;
    if (this.isNewProject) {
      mess = this.translationService.getTranslation('notifications.InsertRecordSucceeded');
    } else {
      mess = this.translationService.getTranslation('notifications.UpdateRecordSucceeded');
    }
    this.alertService.successMessage(mess);
    this.onProjectSaved.next(projectperson);
  }

  private saveFailedHelper(error: any) {
    //this.alertService.stopLoadingMessage();
    //this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
    //this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  private cancel() {
    this.resetForm();

    //this.alertService.resetStickyMessage();
  }

  private buildForm() {
    this.projectForm = this.formBuilder.group({
      id: '',
      employeeId: null,
      name: null,
      projectName: null,
      startDate: null,
      endDate: null,
      targetTime: null
    });
  }

  private resetForm(stopEditing = false) {
    if (stopEditing) {
      this.isEditMode = false;
    }

    if (!this.projectPerson) {
      this.isNewProject = true;
      this.projectPerson = new ProjectPerson();
    }

    this.projectForm.reset({
      id: this.projectPerson.id || '',
      employeeId: this.projectPerson.employeeId || null,
      projectName: this.projectPerson.projectName || null,
      name: this.projectPerson.name || null,
      startDate: this.projectPerson.startDate || null,
      endDate: this.projectPerson.endDate || null,
      targetTime: this.projectPerson.targetTime || null
    });
  }

  get canManageProjectPersons() {
    return true; //this.accountService.userHasPermission(Permission.manageRolesPermission);
  }
}
