import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AlertService, DialogType } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { PersonAuxDataService } from '../../services/personauxdata.service';
import { BaseTable } from '../../models/basetable.model';
import { PersonAuxDataDialogComponent } from './personauxdata-dialog.component';
import { ApiResult } from '../../base.service';
import { LocalStoreManager } from '../../services/local-store-manager.service';

@Component({
  selector: 'personauxdata',
  templateUrl: './personauxdata.component.html',
  styleUrls: ['./personauxdata.component.scss']
})
export class PersonAuxDataComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['number', 'description', 'actions'];
  public basetables: MatTableDataSource<BaseTable>;
  baseTable: BaseTable = new BaseTable();
  editingBaseTableName: { name: string };
  public show: boolean = true;
  defaultPageIndex: number = 0;
  defaultPageSize: number = 10;
  public defaultSortColumn: string = "number";
  public defaultSortOrder: string = "asc";
  siteName: string;
  userName: string;
  defaultFilterColumn: string = "description";
  filterQuery: string = null;
  tableNumber: number = 0;
  createNew: boolean = false;
  newTableText: string = null;
  editTableText: string = null;
  tableText: string = null;
  message: string = null;
  numberOfRows: number = 0;
  rights: number = 0;
  writeRights: number = 0;
  writeRight: boolean = false;



  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  filterTextChanged: Subject<string> = new Subject<string>();

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private localStorage: LocalStoreManager,
    private personAuxDataService: PersonAuxDataService,
    private dialog: MatDialog,
    private router: Router
  ) {
    //this.dataSource = new MatTableDataSource();
  }

  get canManageTables() {
    return true;
  }

  ngOnInit() {
    var hrefStr = this.router.url;
    this.tableNumber = Number(hrefStr.substr(hrefStr.length - 1));
    switch (this.tableNumber) {
      case 0:
        this.tableText = this.translationService.getTranslation('auxdata.EmployeeGroup');
        this.newTableText = this.translationService.getTranslation('auxdata.NewEmployeeGroup');
        this.editTableText = this.translationService.getTranslation('auxdata.EditEmployeeGroup');
        break;
      case 1:
        this.tableText = this.translationService.getTranslation('auxdata.WorkPos');
        this.newTableText = this.translationService.getTranslation('auxdata.NewWorkPos');
        this.editTableText = this.translationService.getTranslation('auxdata.EditWorkPos');
        break;
      case 2:
        this.tableText = this.translationService.getTranslation('auxdata.Profession');
        this.newTableText = this.translationService.getTranslation('auxdata.NewProfession');
        this.editTableText = this.translationService.getTranslation('auxdata.EdiProfession');
        break;
    }
    this.loadData();
    this.rights = this.localStorage.getDataObject("readrights");
    this.writeRights = this.localStorage.getDataObject("userrights");
    this.writeRight = (this.writeRights & 512) == 512;
  }

  onFilterTextChanged(filterText: string) {
    if (this.filterTextChanged.observers.length === 0) {
      this.filterTextChanged
        .pipe(debounceTime(1000), distinctUntilChanged())
        .subscribe(query => {
          this.loadData(query);
        });
    }
    this.filterTextChanged.next(filterText);
  }

  loadData(query: string = null) {
    var pageEvent = new PageEvent();
    pageEvent.pageIndex = this.defaultPageIndex;
    pageEvent.pageSize = this.defaultPageSize;
    if (query) {
      this.filterQuery = query;
    }
    else
      this.filterQuery = null;
    this.getData(pageEvent);
  }

  getData(event: PageEvent) {

    var sortColumn = (this.sort)
      ? this.sort.active
      : this.defaultSortColumn;

    var sortOrder = (this.sort)
      ? this.sort.direction
      : this.defaultSortOrder;

    var filterColumn = (this.filterQuery)
      ? this.defaultFilterColumn
      : null;

    var filterQuery = (this.filterQuery)
      ? this.filterQuery
      : null;

    this.personAuxDataService.getData<ApiResult<BaseTable>>(
      this.tableNumber,
      event.pageIndex,
      event.pageSize,
      sortColumn,
      sortOrder,
      filterColumn,
      filterQuery)
      .subscribe(result => {
        this.paginator.length = result.totalCount;
        this.paginator.pageIndex = result.pageIndex;
        this.paginator.pageSize = result.pageSize;
        this.basetables = new MatTableDataSource<BaseTable>(result.data);
        this.numberOfRows = this.paginator.length;
        if (this.numberOfRows == 0) {
          var mess = this.translationService.getTranslation('notifications.NoDataAvailable');
          this.alertService.errorMessage(mess);
        }
      }, error => console.error(error));
  }


  ngAfterViewInit() {
    //this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
  }

  private handleMessage(updType: number, data: string) {
    if (data != null) {
      switch (updType) {
        case 0: //insert
          this.alertService.showDialog(this.translationService.getTranslation('notifications.InsertRecordFailed'));
          break;
        case 1: //update
          this.alertService.showDialog(this.translationService.getTranslation('notifications.UpdateRecordFailed'));
          break;
        case 2: //delete
          this.alertService.showDialog(this.translationService.getTranslation('notifications.DeleteRecordFailed'));
          break;
      }
    }
  }

  public createBaseTable() {
    this.createNew = true;
    const dialogRef = this.dialog.open(PersonAuxDataDialogComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { baseTable: null, tableNumber: this.tableNumber, createNew: this.createNew, editText: this.editTableText, newText: this.newTableText },
      });
    dialogRef.afterClosed().subscribe(r => {
      this.loadData();
    });
  }

  public editBaseTable(baseTable?: BaseTable) {
    if (!this.writeRight)
      return;
    baseTable.tableNumber = this.tableNumber;
    this.createNew = false;
    const dialogRef = this.dialog.open(PersonAuxDataDialogComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { baseTable, tableNumber: this.tableNumber, createNew: this.createNew, editText: this.editTableText, newText: this.newTableText }
      });
    dialogRef.afterClosed().subscribe(r => {
      if (r && this.canManageTables) {
        r.tableNumber = this.tableNumber;
        this.personAuxDataService.updateBaseTable(r).subscribe(data => {
          this.handleMessage(1, data)
        });
      }
      this.loadData();
    });
  }

  public confirmDelete(baseTable: BaseTable) {
    baseTable.tableNumber = this.tableNumber;
    let delQ = this.translationService.getTranslation('notifications.DeleteRecord');
    this.alertService.showDialog(delQ + '?',
      DialogType.confirm, () => this.deleteStamp(baseTable));
  }

  deleteStamp(baseTable) {
    let id = baseTable.number.toString();
    this.personAuxDataService.deleteBaseTable(id, this.tableNumber)
      .subscribe(results => {
        var mess = this.translationService.getTranslation('notifications.DeleteRecordSucceeded');
        this.alertService.successMessage(mess);
        this.loadData();
      },
        error => {
          //var mess = this.translationService.getTranslation('notifications.DeleteRecordFailed');
          //this.alertService.errorMessage(mess);
        });
  }
}

