export class Substitute {
  id: number;
  username: string;
  substitute: string;
  startDate: Date;
  endDate: Date;
  isEdit: boolean;
}

export const ReleasedRightsColumns = [
  {
    key: 'substitute',
    type: 'select'
  },
  {
    key: 'startDate',
    type: 'startdate'
  },
  {
    key: 'endDate',
    type: 'enddate'
  },
  {
    key: 'isEdit',
    type: 'isEdit',
    label: '',
  }
];

export const ObtainedRightsColumns = [
  {
    key: 'username',
    type: 'text',
    readonly: true,
    label:''
  },
  {
    key: 'startDate',
    type: 'startdate',
    readonly: true,
    label: ''
  },
  {
    key: 'endDate',
    type: 'enddate',
    readonly: true,
    label: ''
  }
];
