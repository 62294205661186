import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ReportParam } from '../models/reportparam.model';
import { Organization } from '../models/organization.model';
import { CorrectionJob } from '../models/correctionjob.model';
import { Observable } from 'rxjs';
import { LocalStoreManager } from './local-store-manager.service';

@Injectable()
export class CorrectionJobService {
  private corrJobUrl: string = 'api/correctionjob';

  constructor(
    private http: HttpClient,
    private localStorage: LocalStoreManager) { }

  execcorrectionjob(params: ReportParam, organization: Organization[]): Observable<CorrectionJob[]> {
    params.showSvHierarchy = this.localStorage.getDataObject("showSvHierarchy");
    const requestBody = {
      reportParams: params,
      organization: organization
    };
    return this.http.post<CorrectionJob[]>(this.corrJobUrl, requestBody);
  }

  //getSaldoReport(params: ReportParam, organization: Organization[]): Observable<SaldoReport[]> {
  //  params.showSvHierarchy = this.localStorage.getDataObject("showSvHierarchy");
  //  const requestBody = {
  //    reportParams: params,
  //    organization: organization
  //  };
  //  return this.http.post<SaldoReport[]>(this.reportUrl, requestBody);
  //}

}


