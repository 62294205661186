import { Component, OnInit, OnDestroy, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AuthService } from "../../services/auth.service";
import { SiteService } from '../../services/site.service';
import { UserLogin } from '../../models/user-login.model';
import { EsmiSite } from '../../models/esmisite.model';
import { AppTranslationService } from "../../services/app-translation.service";
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-login-control',
  templateUrl: './login-control.component.html',
  styleUrls: ['./login-control.component.scss']
})
export class LoginControlComponent implements OnInit, OnDestroy {
  modalClosedCallback: () => void;
  loginStatusSubscription: any;

  loginForm: FormGroup;

  @ViewChild('form', { static: true })
  private form: NgForm;

  @Input()
  isModal = false;

  @Output()
  enterKeyPress = new EventEmitter();

  sites: EsmiSite[] = [];
  site: EsmiSite;
  siteName: string;
  isTrue: boolean = false;
  userLogin = new UserLogin();

  constructor(
    public dataservice: DataService,
    private alertService: AlertService,
    private authService: AuthService,
    private translationService: AppTranslationService,
    private siteService: SiteService,
    private localStorage: LocalStoreManager,
    private formBuilder: FormBuilder) {
    this.buildForm();
  }

  ngOnInit() {
    this.loadSites();
    if (this.localStorage.getDataObject("username"))
      this.userLogin.username = this.localStorage.getDataObject("username");
    if (this.localStorage.getDataObject("password"))
      this.userLogin.password = this.localStorage.getDataObject("password");
    if (this.localStorage.getDataObject("rememberme"))
      this.userLogin.rememberMe = this.localStorage.getDataObject("rememberme")??false;
    this.loginForm.setValue({
      userName: this.userLogin.username??'',
      password: this.userLogin.password??'',
      rememberMe: this.userLogin.rememberMe??false
    });

    if (this.getShouldRedirect()) {
      this.authService.redirectLoginUser();
    } else {
      this.loginStatusSubscription = this.authService.getLoginStatusEvent()
        .subscribe(isLoggedIn => {
          if (this.getShouldRedirect()) {
            this.authService.redirectLoginUser();
          }
        });
    }
    this.dataservice.PersonIndex = -1;
    this.dataservice.PersonData = null;
    this.dataservice.StampDate = null;
  }

  loadSites(): void {
    this.siteService.getSites()
      .subscribe(results => {
        if (results != null) {
          this.sites = results as EsmiSite[];
          this.site = results[0];
          this.siteName = this.site.siteName;
          this.translationService.setDefaultLanguage(this.site.siteLang);
        }
        else {
          this.alertService.error(this.translationService.getTranslation('notifications.SystemNotDefined'));
        }
      },
        error => {
        });
  }

  ngOnDestroy() {
    if (this.loginStatusSubscription) {
      this.loginStatusSubscription.unsubscribe();
    }
  }

  buildForm() {
    this.loginForm = this.formBuilder.group({
      userName: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: this.localStorage.getDataObject("rememberme") ?? false
    });
  }

  get userName() { return this.loginForm.get('userName'); }

  get password() { return this.loginForm.get('password'); }

  getShouldRedirect() {
    return !this.isModal && this.authService.isLoggedIn && !this.authService.isSessionExpired;
  }

  closeModal() {
    if (this.modalClosedCallback) {
      this.modalClosedCallback();
    }
  }

  getUserLogin(): UserLogin {
    const formModel = this.loginForm.value;
    return new UserLogin(this.siteName, formModel.userName, formModel.password, formModel.rememberMe);
  }

  login() {
    if (!this.form.submitted) {
      this.form.onSubmit(null);
      return;
    }

    if (!this.loginForm.valid) {
      let errmess = this.translationService.getTranslation('notifications.NotValid');
      this.alertService.error(errmess);
      return;
    }

    this.authService.login(this.getUserLogin())
      .subscribe(results => {
        this.authService.store_userdata(results);
        //this.authService.systemName = (this.localStorage.getDataObject("sitename"));
        //this.authService.systemNameChange.next(this.authService.systemName);
        this.translationService.changeLanguage(this.localStorage.getDataObject("language"));
      },
        error => {
          this.alertService.error(error);
        });
  }

  enterKeyDown() {
    this.enterKeyPress.emit();
  }
}
