<form #form="ngForm" [formGroup]="worknumberForm" novalidate (ngSubmit)="save()" fxLayout="column" [fxLayoutGap]="isEditMode ? '1em' : '0'" autocomplete="off">
  <mat-form-field class="invisible example-full-width">
    <input matInput formControlName="tablenumber" [readonly]="true" />
  </mat-form-field>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field [floatLabel]="floatLabels" fxFlex="150px">
      <mat-label>{{'common.Number' | translate}}</mat-label>
      <input matInput formControlName="tasknumber" [readonly]="!isNewTable" [attr.tabindex]="!isNewTable ? -1 : null" />
    </mat-form-field>
    <mat-form-field [floatLabel]="floatLabels" fxFlex="400px">
      <mat-label>{{'common.Descr' | translate}}</mat-label>
      <input matInput formControlName="description" maxlength="40" />
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field [floatLabel]="floatLabels">
      <mat-label>{{'stampreport.EndDate' | translate}}</mat-label>
      <input matInput formControlName="closedate" [matDatepicker]="picker1" (click)="picker1.open()" />
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>
    <mat-form-field [floatLabel]="floatLabels" fxFlex="400px">
      <mat-label>{{'common.Company'|translate}}</mat-label>
      <mat-select id="company_id" formControlName="company_id">
        <mat-option *ngFor="let cmp of companies" [value]="cmp.company_id">
          {{cmp.number}} {{cmp.descr}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field [floatLabel]="floatLabels" fxFlex="200px">
      <mat-label>{{'JobTask.ExtraDataA' | translate}}</mat-label>
      <input matInput formControlName="costplaceA" />
    </mat-form-field>
    <mat-form-field [floatLabel]="floatLabels" fxFlex="200px">
      <mat-label>{{'JobTask.ExtraDataB' | translate}}</mat-label>
      <input matInput formControlName="costplaceB" maxlength="40" />
    </mat-form-field>
  </div>

</form>

