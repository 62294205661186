import { UserTypeAright } from "./usertypearight.model";
import { UserTypeUright } from "./usertypeuright.model";

export class UserType {
  user_type_id: number;
  title: string;
  descr: string;
  text: string;
  guser_type: boolean;
  admin_rights: boolean;
}
