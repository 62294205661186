
import { Component, AfterViewInit, Input, ViewChild, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { AppTranslationService } from '../../services/app-translation.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { TaBasetableService } from '../../services/tabasetable.service';
import { BaseTable } from '../../models/basetable.model';
import { Daytype } from '../../models/daytype.model';
import { Baldisptype } from '../../models/baldisptype.model';
import { EditorComponent } from './editor-component.interface'; 

@Component({
  selector: 'app-tabasetable-editor',
  templateUrl: './tabasetable-editor.component.html',
  styleUrls: ['./tabasetable-editor.component.scss']
})
export class TaBasetableEditorComponent implements EditorComponent, AfterViewInit, OnChanges {
  @Input() tableData: BaseTable = new BaseTable(); 
  @Input() tableNumber: number;
  @ViewChild('form', { static: true }) form: NgForm;
  public isFormDirty$ = new Subject<boolean>();

  public isNewTable = false;
  private onTableSaved = new Subject<any>();
  public extraText: string;
/*  isFormDirty = false;*/

  daytypes: Daytype[] = [
    { itemNumber: 1, description: this.translationService.getTranslation('common.Default') },
    { itemNumber: 2, description: this.translationService.getTranslation('common.Holiday') },
    { itemNumber: 3, description: this.translationService.getTranslation('common.Weekday') }
  ];

  baldisptypes: Baldisptype[] = [
    { itemNumber: 1, description: this.translationService.getTranslation('baldisptype.Baldisptype0'), textstring: this.translationService.getTranslation('baldisptype.BaldisptypeStr0') },
    { itemNumber: 2, description: this.translationService.getTranslation('baldisptype.Baldisptype1'), textstring: this.translationService.getTranslation('baldisptype.BaldisptypeStr1') },
    { itemNumber: 3, description: this.translationService.getTranslation('baldisptype.Baldisptype2'), textstring: this.translationService.getTranslation('baldisptype.BaldisptypeStr2') },
    { itemNumber: 6, description: this.translationService.getTranslation('baldisptype.Baldisptype3'), textstring: this.translationService.getTranslation('baldisptype.BaldisptypeStr3') },
    { itemNumber: 7, description: this.translationService.getTranslation('baldisptype.Baldisptype4'), textstring: this.translationService.getTranslation('baldisptype.BaldisptypeStr4') },
  ];

  @Input() createNew: boolean = false;
  @Input() isEditMode = true;

  taBasetableForm: FormGroup;
  tableSaved$ = this.onTableSaved.asObservable();

  ngAfterViewInit() {
    if (this.taBasetableForm) {
      // Subscribe to changes in the form's values or status
      this.taBasetableForm.valueChanges.subscribe(() => {
        this.updateFormDirtyStatus();
      });
    }
  }

  updateFormDirtyStatus() {
    const formIsDirty = this.taBasetableForm.dirty;
    this.isFormDirty$.next(formIsDirty); // Emit the dirty state
    this.cdr.detectChanges();
    console.log('Form dirty status:', formIsDirty);
  }

  get tablenumber() {
    return this.taBasetableForm.get('tablenumber');
  }

  get number() {
    return this.taBasetableForm.get('number');
  }

  get description() {
    return this.taBasetableForm.get('description');
  }
  get extraDate() {
    return this.taBasetableForm.get('extraDate');
  }
  get extraNumber() {
    return this.taBasetableForm.get('extraNumber');
  }
   
  get floatLabels(): string {
    return this.isEditMode ? 'auto' : 'always';
  }

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private tabasetableService: TaBasetableService,
    private translationService: AppTranslationService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.buildForm();
  }

  ngOnInit() {
    this.extraText = this.translationService.getTranslation('tabasetable.CloseDate'); '';
    console.log('Editor ngOnInit:', this.tableData, " Number ", this.tableNumber);
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('TableData received in TaBasetableEditorComponent:', changes);
    if (changes['tableData']) {
      console.log('Previous:', changes['tableData'].previousValue);
      console.log('Current:', changes['tableData'].currentValue);
    }
    if (this.tableData) {
      this.isNewTable = false;
      this.resetForm(); // Ensure form is reset with the current tableData
    }
  }

  updateTableData(changes: { previousValue: any; currentValue: any; firstChange: boolean }) {
    console.log('Manually triggered updateTableData:', changes);
    if (changes.currentValue) {
      this.isNewTable = this.createNew;;
      this.resetForm(); // Reset the form with the new tableData
    }
  }

  public save() {
    if (!this.form.submitted) {
      this.form.onSubmit(null);
      return;
    }

    if (!this.taBasetableForm.valid) {
      /*      this.alertService.showValidationError();*/
      return;
    }

    var editedTaBasetable = this.getEditedTaBasetable();
    editedTaBasetable.number = Number(editedTaBasetable.number);
    editedTaBasetable.tableNumber = Number(editedTaBasetable.tableNumber);

    if (this.isNewTable) {
      this.tabasetableService.newBaseTable(editedTaBasetable)
        .subscribe(response => this.saveSuccessHelper(0, response),
          error => {
            if (error == "Unauthorized")
              this.authService.logout();
            else
              this.saveFailedHelper(error);
          });
  }
    else {
      this.tabasetableService.updateBaseTable(editedTaBasetable).subscribe(
        response => this.saveSuccessHelper(1, response),
        error => {
          if (error == "Unauthorized")
            this.authService.logout();
          else
            this.saveFailedHelper(error);
        });
    }
  }

  private getEditedTaBasetable(): BaseTable {
    const formModel = this.taBasetableForm.value;

    return {
      number: formModel.number,
      numberStr: formModel.numberStr,
      description: formModel.description,
      tableNumber: formModel.tablenumber,
      extraDate: formModel.extraDate,
      extraNumber: formModel.extraNumber
    };
  }

  private saveSuccessHelper(eType: number, mess: string) {
    const response = {
      message: eType === 0
        ? this.translationService.getTranslation('notifications.InsertRecordSucceeded')
        : this.translationService.getTranslation('notifications.UpdateRecordSucceeded'),
      tableNumber: this.tableData.tableNumber
    };
    this.alertService.successMessage(response.message);
    this.onTableSaved.next(response);
  }

  private saveFailedHelper(mess: string) {
    this.alertService.errorMessage(mess);
    //this.onTableSaved.next(mess);
  }

  private cancel() {
    this.resetForm();

    //this.alertService.resetStickyMessage();
  }

  private buildForm() {
    this.taBasetableForm = this.formBuilder.group({
      tablenumber: [''],
      number: [''],
      numberStr: [''],
      description: ['', Validators.required],
      extraDate: null,
      extraNumber: null
    });
  }

  private resetForm() {
    if (!this.tableData) {
      this.isNewTable = true;
      this.tableData = new BaseTable();
    }

    this.taBasetableForm.reset({
      tablenumber: this.tableNumber,
      number: this.tableData.number || '',
      numberStr: this.tableData.numberStr,
      description: this.tableData.description,
      extraDate: this.tableData.extraDate || null,
      extraNumber: this.tableData.extraNumber || null
    });
  }


  get canManageTaDataa() {
    return true; //this.accountService.userHasPermission(Permission.manageRolesPermission);
  }
}
