import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SetPassword } from '../../models/setpassword.model';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { AppTranslationService } from "../../services/app-translation.service";

export interface DialogData {
  employeeId: number;
  password: string;
}

@Component({
  selector: 'setpassword',
  templateUrl: './setpassword.component.html',
  styleUrls: ['./setpassword.component.scss']

})
export class ChangePassword {
  sitename: string;
  username: string;
  password1: string = null;
  password2: string = null;
  Passwd1: string;
  Passwd2: string;
  passwdStr: string;
  setPasswd = new (SetPassword);

  constructor(
    private localStorage: LocalStoreManager,
    private translationService: AppTranslationService,
    public dialogRef: MatDialogRef<ChangePassword>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.Passwd1 = this.translationService.getTranslation('helps.Passwd1');
    this.Passwd2 = this.translationService.getTranslation('helps.Passwd2');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onZeroClick(): { password1: string, password2: string } {
    this.setPasswd.oldpasswd = this.password1;
    this.setPasswd.newpasswd = this.password2;
    this.dialogRef.close();
    return { password1: this.password1, password2: this.password2 };
  }



  public save() {
    this.setPasswd.username = this.localStorage.getDataObject("username");
    this.setPasswd.oldpasswd = this.password1;
    this.setPasswd.newpasswd = this.password2;
  }

}
