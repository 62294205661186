<form #form="ngForm" [formGroup]="loginForm" (ngSubmit)="login()" novalidate class="app-login-form">
  <div fxLayout="row">
    <mat-form-field class="app-validate">
      <input matInput formControlName="userName" placeholder="{{'login.Username' | translate}}" autocomplete="username" (keydown.enter)="enterKeyDown()">
      <mat-error *ngIf="userName.hasError('required')">
        {{'login.UsernameRequired' | translate}}
      </mat-error>
    </mat-form-field>
  </div>
  <div fxLayout="row" >
    <mat-form-field class="app-validate">
      <input matInput formControlName="password" placeholder="{{'login.Password' | translate}}" autocomplete="current-password" (keydown.enter)="enterKeyDown()" type="password">
      <mat-error *ngIf="password.hasError('required')">
        {{'login.PasswordRequired' | translate}}
      </mat-error>
    </mat-form-field>
  </div>
  <div fxLayout="row" >
    <mat-checkbox formControlName="rememberMe" (keydown.enter)="enterKeyDown()">{{'login.RememberMe' | translate}}</mat-checkbox>
  </div>
    <div>&nbsp;</div>
</form>
