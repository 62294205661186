import { Component, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PersonAuxDataService } from '../../services/personauxdata.service';
import { BaseTable } from '../../models/basetable.model';
import { PersonAuxDataEditorComponent } from './personauxdata-editor.component';

@Component({
  selector: 'app-personauxdata-dialog',
  templateUrl: 'personauxdata-dialog.component.html',
  styleUrls: ['personauxdata-dialog.component.scss']
})
export class PersonAuxDataDialogComponent implements AfterViewInit {
  @ViewChild(PersonAuxDataEditorComponent, { static: true })
  personAuxDataEditor: PersonAuxDataEditorComponent;
  editText: string;
  newText: string;
  mess: string;

  constructor(
    public dialogRef: MatDialogRef<PersonAuxDataEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { baseTable: BaseTable, tableNumber, createNew, editText, newText },
    private personAuxDataService: PersonAuxDataService
  ) {
    this.editText = data.editText;
    this.newText = data.newText;
  }

  ngAfterViewInit() {
    this.personAuxDataEditor.tableSaved$.subscribe(response => this.dialogRef.close(this.mess));
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

  get canManageAuxData() {
    return true; //this.accountService.userHasPermission(Permission.manageRolesPermission);
  }

  isHiddenPersonRow(employee: any): boolean {
    return employee.hidden; // Please change the condition accordingly.
  }

}
