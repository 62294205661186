<div class="page-content">
  <h3>{{'menu.Parameters' | translate}}</h3>
  <div class="form-group">
    <div class="row">
      <div class="col-8">
        <strong>{{'parameter.Section' | translate}}</strong>&nbsp;&nbsp;
        <select [(ngModel)]="selectedSection" name="sectionid" (change)="toSectionId()">
          <option *ngFor="let sec of sectionids" [ngValue]="sec">
            {{ sec.description }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <table mat-table [dataSource]="parameters">
    <ng-container [matColumnDef]="col.key" *ngFor="let col of columnsSchema">
      <th mat-header-cell *matHeaderCellDef [ngSwitch]="col.key">
        <span *ngSwitchDefault>{{ col.label }}</span>
      </th>
      <td mat-cell *matCellDef="let element">
        <div [ngSwitch]="col.type" *ngIf="!element.isEdit">
          <div class="btn-edit" *ngSwitchCase="'isEdit'">
            <button mat-button (click)="element.isEdit = !element.isEdit" [disabled]="!writeRight">
              {{'common.Change' | translate}}
            </button>
          </div>
          <span *ngSwitchDefault>
            {{ element[col.key] }}
          </span>
        </div>
        <div [ngSwitch]="col.type" *ngIf="element.isEdit">
          <div class="btn-edit" *ngSwitchCase="'isEdit'">
            <button mat-button (click)="element.isEdit = !element.isEdit">
              {{'common.Cancel' | translate}}
            </button>
            <button mat-button (click)="editRow(element)" [disabled]="disableSubmit(element.id)">
              {{'common.Edit' | translate}}
            </button>
          </div>
          <mat-form-field *ngSwitchCase="'select'"
                          appearance="fill">
          </mat-form-field>

          <mat-form-field class="form-input" *ngSwitchDefault>
            <input matInput
                   [required]="col.required"
                   [pattern]="col.pattern"
                   [readonly]="col.readonly"
                   [type]="col.type"
                   [(ngModel)]="element[col.key]"
                   (change)="inputHandler($event, element.id, col.key)" />
          </mat-form-field>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [hidden]="numberOfRows < 11"
                 (page)="getData($event)"
                 [pageSize]="10"
                 itemsPerPageLabel=" "
                 showFirstLastButtons>
  </mat-paginator>

</div>
