import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Parameter } from '../models/parameter.model';
import { Observable } from 'rxjs';
import { BaseService, ApiResult } from '../base.service';

@Injectable({
  providedIn: 'root',
})

export class ParameterService
  extends BaseService {
  constructor(
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string) {
    super(http, baseUrl);
  }

  private parameterUrl: string = 'api/parameters';

  getData<ApiResult>(
    tableNumber: number,
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: string,
    filterColumn: string,
    filterQuery: string
  ): Observable<ApiResult> {
    var url = 'api/parameters';
    var params = new HttpParams()
      .set("tableNumber", tableNumber.toString())
      .set("pageIndex", pageIndex.toString())
      .set("pageSize", pageSize.toString())
      .set("sortColumn", sortColumn)
      .set("sortOrder", sortOrder);

    if (filterQuery) {
      params = params
        .set("filterColumn", filterColumn)
        .set("filterQuery", filterQuery);
    }
    return this.http.get<ApiResult>(url, { params });
  }


  updateParameter(parameter): Observable<Parameter> {
    return this.http.put<Parameter>(this.parameterUrl, parameter);
  }
}

