import { NgModule } from '@angular/core';
import { SharedModule } from '../shared.module';
import { SharedMaterialModule } from '../shared-mat.module';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';
import { PersonUserComponent } from './personuser/personuser.component';
import { CorrectionJobComponent } from './correctionjob/correctionjob.component';
import { UserTypeComponent } from './usertype/usertype.component';
import { UserRightsDialogComponent } from './usertype/usertypeuserrights.component';
import { AccessRightsDialogComponent } from './usertype/usertypeaccessrights.component';
import { ParameterListComponent } from './parameter/parameter-list.component';
import { DeviceComponent } from './device/device.component';
import { UserTypeService } from '../services/usertype.service';
import { PersonUserService } from '../services/personuser.service';
import { ParameterService } from '../services/parameter.service';
import { DeviceService } from '../services/device.service';
import { SubstituteService } from '../services/substitute.service';
import { CorrectionJobService } from '../services/correctionjob.service';
import { WtGroupService } from '../services/wtgroup.service';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  imports: [
    SharedModule,
    SharedMaterialModule,
    AdminRoutingModule,
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule      
  ],
  declarations: [
    AdminComponent,
    PersonUserComponent,
    CorrectionJobComponent,
    UserTypeComponent,
    UserRightsDialogComponent,
    AccessRightsDialogComponent,
    ParameterListComponent,
    DeviceComponent
  ],
  providers: [
    UserTypeService,
    PersonUserService,
    ParameterService,
    SubstituteService,
    CorrectionJobService,
    WtGroupService,
    DeviceService
  ]
})
export class AdminModule {
}
