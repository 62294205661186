import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LocalStoreManager } from './local-store-manager.service';
import { BaseTable } from '../models/basetable.model';
import { Observable } from 'rxjs';
import { BaseService, ApiResult } from '../base.service';

@Injectable({
  providedIn: 'root',
})

export class TaBasetableService
  extends BaseService {
  constructor(
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string, private localStorage: LocalStoreManager) {
    super(http, baseUrl);
  }

  private tabasetableUrl: string = 'api/personauxdata';

  getData<ApiResult>(
    tableNumber: number,
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: string,
    filterColumn: string,
    filterQuery: string
  ): Observable<ApiResult> {
    var url = 'api/personauxdata';
    var params = new HttpParams()
      .set("tableNumber", tableNumber.toString())
      .set("pageIndex", pageIndex.toString())
      .set("pageSize", pageSize.toString())
      .set("sortColumn", sortColumn)
      .set("sortOrder", sortOrder);

    if (filterQuery) {
      params = params
        .set("filterColumn", filterColumn)
        .set("filterQuery", filterQuery);
    }

    return this.http.get<ApiResult>(url, { params });
  }

  newBaseTable(table) {
    return this.http.post(this.tabasetableUrl, table, { responseType: 'text' })
      .pipe(
      )
  }

  updateBaseTable(table) {
    return this.http.put(this.tabasetableUrl, table, { responseType: 'text' })
      .pipe(
      )
  }

  deleteBaseTable(id: string, tablenumber: number): Observable<any> {
    const params = {
      tableId: id,
      tableNumber: tablenumber
    };
    return this.http.delete<BaseTable>(this.tabasetableUrl, { params });
  }

  get<BaseTable>(id): Observable<BaseTable> {
    let siteName = this.localStorage.getDataObject("sitename");
    var url = "api/personauxdata/" + id;
    return this.http.get<BaseTable>(url);
  }

  post<BaseTable>(item): Observable<BaseTable> {
    var url = "api/personauxdata";
    return this.http.post<BaseTable>(url, item);
  }

  delete<BaseTable>(id): Observable<BaseTable> {
    var url = "api/personauxdata/" + id;
    return this.http.delete<BaseTable>(url);
  }

}
