export class PrintReportParam {
  reportNumber: number;
  employee_id: Array<string> = [];
  startDate: string;
  endDate: string;
  lang: string;
  extra: string;
  reportType: number;
  withHeader: boolean;
}
