export class Parameter {
  parameter_id: number;
  section: string;
  value: string;
  data: string;
  description: string;
  isEdit: boolean;
}

export const ParameterColumns = [
  {
    key: 'value',
    type: 'text',
    readonly: true,
  },
  {
    key: 'data',
    type: 'text'
  },
  {
    key: 'description',
    type: 'text',
    readonly: true,
  },
  {
    key: 'isEdit',
    type: 'isEdit',
    label: '',
  },
];
