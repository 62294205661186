export class UserLogin {
  constructor(siteName?: string, userName?: string, password?: string, rememberMe?: boolean) {
    this.sitename = siteName;
    this.username = userName;
    this.password = password;
    this.rememberMe = rememberMe;
  }
  sitename: string;
  siteid: string;
  username: string;
  password: string;
  rememberMe: boolean;
  language: string;
  personName: string;
  userRights: number;
  reportRights: number;
  logintime: string;
  employee_id: string;
  guser: boolean;
  personCount: number;
  isAdmin: boolean;
  hasSubstitutes: boolean;
  substitute: string;
  sendMail: boolean;
  hasOrgRight: boolean;
  showSvHierarchy: boolean;
  withEmployeeNo: boolean;
  projects: number;
  readRights: number;
}
