export class ReportParam {
  employee_id: string;
  startDate: string;
  endDate: string;
  lang: string;
  extra: string;
  name: string;
  reportType: number;
  showSvHierarchy: boolean;
}
