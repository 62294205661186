import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { AlertService } from '../../services/alert.service';
import { AppTranslationService } from "../../services/app-translation.service";
import { ProjectReport } from '../../models/projectreport.model';
import { ProjectReportService } from "../../services/projectreport.service";
import { PersonNameService } from "../../services/personname.service";
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { DataService } from '../../services/data.service';
import { BookingId } from '../../models/bookingid.model';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { Organization } from '../../models/organization.model';
import { ProjectData } from '../../models/projectdata.model';
import { BaseReportComponent } from '../../components/base.report.component';
import { MatDialog } from '@angular/material/dialog';
import { OrganizationService } from "../../services/organization.service";
import { ProjectService } from "../../services/project.service";
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'projectreport',
  templateUrl: './projectreport.component.html',
  styleUrls: ['./projectreport.component.scss']
})
export class ProjectReportComponent
  extends BaseReportComponent implements OnInit {

  reportids: BookingId[] = [
    { booking_id: 0, descr: this.translationService.getTranslation('projectreport.Projects') },
    { booking_id: 1, descr: this.translationService.getTranslation('projectreport.Sumreport') },
    { booking_id: 2, descr: this.translationService.getTranslation('projectreport.SumreportProject') },
    { booking_id: 3, descr: this.translationService.getTranslation('projectreport.SumreportPerson') },
  ];
  projectreports: ProjectReport[] = [];
  project: number;
  projects: ProjectData[] = [];
  currentYear: number;
  thisYear: number;
  rights: number = 0;
  writeRights: number = 0;
  writeRight: boolean = false;

  years: BookingId[] = [
    { booking_id: 0, descr: '' },
    { booking_id: 2020, descr: '2020' },
    { booking_id: 2021, descr: '2021' },
    { booking_id: 2022, descr: '2022' },
    { booking_id: 2023, descr: '2023' },
    { booking_id: 2024, descr: '2024' },
    { booking_id: 2025, descr: '2025' }
  ];

  currentMonth: number;
  allmonths = [
    { booking_id: 0, descr: '' },
    { booking_id: 1, descr: this.translationService.getTranslation('months.January') },
    { booking_id: 2, descr: this.translationService.getTranslation('months.February') },
    { booking_id: 3, descr: this.translationService.getTranslation('months.March') },
    { booking_id: 4, descr: this.translationService.getTranslation('months.April') },
    { booking_id: 5, descr: this.translationService.getTranslation('months.May') },
    { booking_id: 6, descr: this.translationService.getTranslation('months.June') },
    { booking_id: 7, descr: this.translationService.getTranslation('months.July') },
    { booking_id: 8, descr: this.translationService.getTranslation('months.August') },
    { booking_id: 9, descr: this.translationService.getTranslation('months.September') },
    { booking_id: 10, descr: this.translationService.getTranslation('months.October') },
    { booking_id: 11, descr: this.translationService.getTranslation('months.November') },
    { booking_id: 12, descr: this.translationService.getTranslation('months.December') }];

  constructor(
    public dialog: MatDialog,
    public dataservice: DataService,
    public translationService: AppTranslationService,
    private authService: AuthService,
    public alertService: AlertService,
    private projectReportService: ProjectReportService,
    public personNameService: PersonNameService,
    public organizationService: OrganizationService,
    private projectService: ProjectService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public localStorage: LocalStoreManager) {
    super(dialog, dataservice, personNameService, localStorage, translationService, organizationService, alertService, router, spinner);
    this.thisYear = new Date().getFullYear();

    //this.years.push(undefined);
    //for (let year = this.thisYear; year >= 2020; year--) {
    //  this.years.push(year);
    //}
  }

  ngOnInit() {
    super.ngOnInit();
    this.getProjects();
    this.personIndex = 0;
    super.clearPerson();
    this.GUserSettings();
    if (this.guser)
      this.refresh();
    else {
      this.organizationTree.getAndFillOrganizationTree();
    }
    this.rights = this.localStorage.getDataObject("readrights");
    this.writeRights = this.localStorage.getDataObject("userrights");
    this.writeRight = (this.writeRights & 8192) == 8192;
  }

  getProjects() {
    this.projectService.getProjects("1")
      .subscribe(data => {
        this.projects = data;
      },
        (error: any) => {
          //console.log(error);
        });
  }

  selectedProject = this.projects[0];

  toProject() {
    if (this.projects.length > 1) {
      this.project = this.selectedProject.number;
    }
    this.refresh();
  }

  selectedMonth = this.allmonths[0];
  toMonth() {
    console.log(this.selectedMonth.booking_id);
    this.currentMonth = this.selectedMonth.booking_id;
    this.setMonthDates(this.selectedMonth.booking_id);
  }

  setMonthDates(newMonth: number) {
    if (newMonth == 0) {
      this.startDate = { date: { year: this.dateOlder.year, month: this.dateOlder.month, day: this.dateOlder.day } };
      this.endDate = { date: { year: this.dateNow.year, month: this.dateNow.month, day: this.dateNow.day } };
    }
    else {
      if (this.selectedYear.booking_id == 0)
        this.thisYear = new Date().getFullYear();
      else
        this.thisYear = this.selectedYear.booking_id;
      let days = this.getDaysInMonth(this.thisYear, newMonth);
      this.startDate = { date: { year: this.thisYear, month: newMonth, day: 1 } };
      this.endDate = { date: { year: this.thisYear, month: newMonth, day: days } };
    }
  }

  getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
  }


  selectedYear = this.years[0];
  toYear() {
    console.log(this.selectedYear.booking_id);
    this.currentYear = this.selectedYear.booking_id;
    this.setYearDates(this.selectedYear.booking_id);
  }

  setYearDates(newYear: number) {
    if (newYear == 0) {
      this.startDate = { date: { year: this.dateOlder.year, month: this.dateOlder.month, day: this.dateOlder.day } };
      this.endDate = { date: { year: this.dateNow.year, month: this.dateNow.month, day: this.dateNow.day } };
    }
    else {
      this.startDate = { date: { year: newYear, month: 1, day: 1 } };
      this.endDate = { date: { year: newYear, month: 12, day: 31 } };
    }
  }

  selectedReportId = this.reportids[0];
  toReportId() {
    this.reportid = this.selectedReportId.booking_id;
    this.refresh();
  }

  getPerson() {
    this.personNameService.getpersonnames("")
      .subscribe(results => {
        this.persons = results;
      },
        (error: any) => {
          console.log(error);
        });
  }

  clearPerson() {
    super.clearPerson();
    this.refresh();
  }

  changePerson() {
    super.changePerson();
    this.refresh();
  }

  previousname() {
    super.previousname();
    this.refresh();
  }

  nextname() {
    super.nextname();
    this.refresh();
  }

  refresh() {
    if (!this.checkReportDates())
      return;
    this.stampReportParameters();
    if (this.organizationTree.newOrganization.length == 0)
      this.organizationTree.newOrganization[0] = new Organization();
    this.params.extra = this.project == null ? '0' : this.project.toString();
    this.params.reportType = this.reportid;
    this.params.employee_id = this.employeeId;
    this.spinner.show(); // start spinner
    this.projectReportService.getProjectReport(this.params, this.organizationTree.newOrganization)
      .subscribe(data => {
        this.spinner.hide(); // stop spinner
        if (data != null) {
          this.projectreports = data as ProjectReport[];
        }
        this.authService.refreshLogin()
          .subscribe(data => {
            if (data) {
              this.authService.refresh_userdata(data);
            }
          },
            (error: any) => {
            });
      },
        (error: any) => {
        });
  }

  printpdf() {
    if (!this.checkReportDates())
      return;
    this.stampReportParameters();
    if (this.organizationTree.newOrganization.length == 0)
      this.organizationTree.newOrganization[0] = new Organization();
    this.params.extra = this.project.toString();
    this.params.reportType = this.reportid;
    this.params.employee_id = this.employeeId;
    this.spinner.show(); // start spinner
    this.projectReportService.printReport(this.params, this.organizationTree.newOrganization)
      .subscribe(data => {
        this.spinner.hide(); // stop spinner
        var url = window.URL.createObjectURL(data);
        window.open(url);
      },
        (error: any) => {
        });
  }

  printexcel() {
    if (!this.checkReportDates())
      return;
    this.stampReportParameters();
    if (this.organizationTree.newOrganization.length == 0)
      this.organizationTree.newOrganization[0] = new Organization();
    this.params.extra = this.project.toString();
    this.params.reportType = this.reportid;
    this.params.employee_id = this.employeeId;
    this.spinner.show(); // start spinner
    this.projectReportService.printReport(this.params, this.organizationTree.newOrganization)
      .subscribe((response: HttpResponse<Blob>) => {
        this.spinner.hide(); // stop spinner
        const filename = this.getFilenameFromResponse(response);
        const blob = new Blob([response.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        // If not IE, create link element and click it
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        // Set the file name
        link.download = filename;
        // Start the download
        link.click();
        // Clean up after yourself
        URL.revokeObjectURL(link.href);
      },
        (error: any) => {
        });
  }

  organizationClick() {
    super.clearPersonList();
    super.openDialog();
  }

  ngOnDestroy() {
    this.dataservice.PersonData = this.selectedPerson;
    this.dataservice.PersonIndex = this.personIndex;
    this.dataservice.StartDate = this.startDate;
    this.dataservice.EndDate = this.endDate;
    this.spinner.hide(); // stop spinner if ended by autologout
  }

}
