import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStoreManager } from './local-store-manager.service';
import { WtGroup } from '../models/wtgroup.model';

@Injectable()
export class WtGroupService {
  private wtgroupUrl: string = 'api/worktimegroups';

  constructor(
    private http: HttpClient,
    private localStorage: LocalStoreManager) { }

  getwtgroups(): Observable<WtGroup[]> {
    //let language = this.localStorage.getDataObject("language");
    let sitename = this.localStorage.getDataObject("sitename");
    let endpointUrl = `${this.wtgroupUrl}/${sitename}`;
    return this.http.get<WtGroup[]>(endpointUrl);
  }

}

