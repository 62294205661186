
import { Component, Input, AfterViewInit, ViewChild, OnChanges } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { AppTranslationService } from '../../services/app-translation.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { PersonNameService } from "../../services/personname.service";
import { EmployeeService } from '../../services/employee.service';
import { SiteService } from '../../services/site.service';
import { Gender } from '../../models/gender.model';
import { Tam } from '../../models/tam.model';
import { VacancyGroup } from '../../models/vacancygroup.model';
import { WorkShift } from '../../models/workshift.model';
import { Profession } from '../../models/profession.model';
import { AcceptLevel } from '../../models/acceptlevel.model';
import { SupplierGroup } from '../../models/suppliergroup.model';
import { Employee } from '../../models/employee.model';
import { UserLanguage } from '../../models/userlanguage.model';
import { WorkingPos } from '../../models/workingpos.model';
import { YesNo } from '../../models/yesno.model';
import { ChangePassword } from './setpassword.component';
import { SetPassword } from '../../models/setpassword.model';
import { UserType } from '../../models/usertype.model';
import { AuthService } from '../../services/auth.service';
import { UserTypeService } from '../../services/usertype.service';
import { EmployeeGroupService } from '../../services/employeegroup.service';
import { SupplierGroupService } from '../../services/suppliergroup.service';
import { ProfessionService } from '../../services/profession.service';
import { AcceptLevelService } from '../../services/acceptlevel.service';
import { WorkingPosService } from '../../services/workingpos.service';
import { UserLanguageService } from '../../services/userlanguage.service';
import { EmployeeGroup } from '../../models/employeegroup.model';
import { SharedDataService } from '../../services/shareddata.service';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { distinctUntilChanged } from 'rxjs/operators';



@Component({
  selector: 'app-persons-taeditor',
  templateUrl: './persons-taeditor.component.html',
  styleUrls: ['./persons-taeditor.component.scss']
})
export class PersonsTaEditorComponent implements OnChanges, AfterViewInit {
  @ViewChild('form', { static: true })
  private form: NgForm;

  public isNewEmployee = false;
  private onEmployeeSaved = new Subject<Employee>();
  generalUsers: UserType[] = [];
  setPasswd = new (SetPassword);
  employees: Employee[] = [];
  employeegroups: EmployeeGroup[] = [];
  tams: Tam[] = [];
  workshifts: WorkShift[] = [];
  vacancygroups: VacancyGroup[] = [];
  professions: Profession[] = [];
  acceptlevels: AcceptLevel[] = [];
  suppliergroups: SupplierGroup[] = [];
  workingposs: WorkingPos[] = [];
  userlanguages: UserLanguage[] = [];
  yesnos: YesNo[] = [
    { id: 1, descr: this.translationService.getTranslation('common.No') },
    { id: 2, descr: this.translationService.getTranslation('common.Yes') }
  ];

  genders: Gender[] = [
    { itemNumber: 1, description: this.translationService.getTranslation('common.Man') },
    { itemNumber: 2, description: this.translationService.getTranslation('common.Woman') }
  ];

  @Input() employee: Employee = new Employee();
  @Input() isEditMode = true;

  employeeTaForm: FormGroup;
  employeeSaved$ = this.onEmployeeSaved.asObservable();

  get employeeId() {
    return this.employeeTaForm.get('employeeId');
  }

  get employee_id() {
    return this.employeeTaForm.get('employee_id');
  }

  get surname() {
    return this.employeeTaForm.get('surname');
  }

  get forename() {
    return this.employeeTaForm.get('forename');
  }

  get employeeNo() {
    return this.employeeTaForm.get('employeeNo');
  }
  get mobileTelephone() {
    return this.employeeTaForm.get('mobileTelephone');
  }

  get email() {
    return this.employeeTaForm.get('email');
  }

  get floatLabels(): string {
    return this.isEditMode ? 'auto' : 'always';
  }

  constructor(
    public dialog: MatDialog,
    private siteService: SiteService,
    private alertService: AlertService,
    private authService: AuthService,
    private employeeGroupService: EmployeeGroupService,
    private supplierGroupService: SupplierGroupService,
    private generalUserService: UserTypeService,
    private acceptLevelService: AcceptLevelService,
    private professionService: ProfessionService,
    private employeeService: EmployeeService,
    private translationService: AppTranslationService,
    private workingPosService: WorkingPosService,
    private userLanguageService: UserLanguageService,
    public personNameService: PersonNameService,
    private sharedDataService: SharedDataService,
    private formBuilder: FormBuilder,
    public localStorage: LocalStoreManager
  ) {
    this.buildForm();
  }

  ngAfterViewInit() {
    console.log("PersonsTAeditor afterViewInit ");
    //setTimeout(() => {
    //  this.employeeTaForm.controls['email'].setValue('');
    //}, 500);
  }

  ngOnInit() {
    console.log("PersonsTAeditor ngOnInit ");
    this.sharedDataService.currentFormData.subscribe(formData => {
      const rowId = this.employeeId.value;
      console.log("TALoop " + rowId + " " + formData[rowId]);
      if (formData[rowId]) {
        for (const field in formData[rowId]) {
          if (formData[rowId].hasOwnProperty(field) && this.employeeTaForm.controls[field]) {
            this.employeeTaForm.controls[field].setValue(formData[rowId][field], { emitEvent: false });
          }
        }
      }
    });

    this.generalUserService.getGeneralUserTypes()
      .subscribe(data => {
        this.generalUsers = data;
      },
        (error: any) => {
          //console.log(error);
        });
    this.professionService.getProfessions()
      .subscribe(data => {
        this.professions = data;
      },
        (error: any) => {
          //console.log(error);
        });
    this.employeeGroupService.getEmployeeGroups()
      .subscribe(data => {
        this.employeegroups = data;
      },
        (error: any) => {
          //console.log(error);
        });
    this.acceptLevelService.getAcceptLevels()
      .subscribe(data => {
        this.acceptlevels = data;
      },
        (error: any) => {
          //console.log(error);
        });
    this.supplierGroupService.getSupplierGroups()
      .subscribe(data => {
        this.suppliergroups = data;
      },
        (error: any) => {
          //console.log(error);
        });
    this.workingPosService.getWorkingPoss()
      .subscribe(data => {
        this.workingposs = data;
      },
        (error: any) => {
          //console.log(error);
        });
    this.userLanguageService.getUserLanguages()
      .subscribe(data => {
        this.userlanguages = data;
      },
        (error: any) => {
          //console.log(error);
        });
  }

  ngOnChanges() {
    console.log("PersonsTAeditor ngOnChanges ");
    if (this.employee) {
      this.isNewEmployee = false;
    } else {
      this.isNewEmployee = true;
      this.employee = new Employee();
    }
    //console.log("PersonTA Editor NGOC Resetting form " + this.isNewEmployee + " empl  " + this.employee + " ssn  " + this.employee.ssn);
    this.resetForm();
  }

  public saveTa() {
    //if (!this.form.submitted) {
    //  this.form.onSubmit(null);
    //  return;
    //}
    console.log("PersonsTAeditor saveTA ");
    if (!this.employeeTaForm.valid) {
      /*      this.alertService.showValidationError();*/
      return;
    }

    var editedEmployee = this.getEditedEmployee();
    if (editedEmployee.employeeId == null || editedEmployee.employeeId == '')
      editedEmployee.employeeId = this.localStorage.getDataObject("employee_id");
    let guserId = Number(editedEmployee.guserTypeId);
    editedEmployee.guserTypeId = (guserId == 0) ? null : guserId;
    this.employeeTaForm.markAsPristine();
    editedEmployee.updateScreen = 1;
    this.employeeService.updateEmployee(editedEmployee).subscribe(
      response => this.saveSuccessHelper(editedEmployee),
      error => {
        if (error == "Unauthorized")
          this.authService.logout();
        else
          this.saveFailedHelper(error);
      });
    /*    }*/
  }

  private getEditedEmployee(): Employee {
    const formModel = this.employeeTaForm.value;
    console.log("PersonsTAeditor getEdited " + formModel.employeeId + " " + formModel.surname);
    return {
      updateScreen: 0,
      employeeId: formModel.employeeId,
      employee_id: formModel.employee_id,
      surname: formModel.surname,
      forename: formModel.forename,
      employeeNo: formModel.employeeNo,
      ssn: this.employee.ssn,
      email: formModel.email,
      hidden: this.employee.hidden,
      userLanguage: formModel.userlanguage,
      mobileTelephone: formModel.mobileTelephone,
      guserTypeId: formModel.guserTypeId,
      guserPwd: formModel.guserPwd,
      professionId: formModel.professionId,
      occupation: formModel.occupation,
      svId: formModel.svId,
      additionalSupervisor: this.employee.additionalSupervisor,
      additInfo: this.employee.additInfo,
      cardId: this.employee.cardId,
      gender: formModel.gender,
      add_employee_no: formModel.add_employee_no,
      emplStart: formModel.emplstart,
      emplEnd: formModel.emplend,
      text: this.employee.text,
      premiVisor: (formModel.premivisor == 2) ? "1" : null,
      position: (formModel.position == 2) ? "*" : null,
      salarysys_user: formModel.salarysys_user,
      worktime_data: formModel.worktime_data,
      employeeGroup: formModel.employeegroup,
      acceptLevel: formModel.acceptlevel,
      lunchGroup: formModel.lunchgroup,
      lunchDisable: (formModel.lunchdisable == 2) ? true : false,
      workingPos: formModel.workingpos,
      companyId: this.employee.companyId,
      company: this.employee.company,
      companyName: this.employee.companyName,
      factoryId: this.employee.factoryId,
      factory: this.employee.factory,
      factoryName: this.employee.factoryName,
      departmentId: this.employee.departmentId,
      department: this.employee.department,
      departmentName: this.employee.departmentName,
      sectionId: this.employee.sectionId,
      section: this.employee.section,
      sectionName: this.employee.sectionName,
      lastChange: formModel.lastChange,
      changemadeby: formModel.changemadeby
    };
  }

  private saveSuccessHelper(empl?: Employee) {
    let mess: string;
    //if (this.isNewEmployee) {
    //  mess = this.translationService.getTranslation('notifications.InsertRecordSucceeded');
    //} else {
    mess = this.translationService.getTranslation('notifications.UpdateRecordSucceeded');
    //}
    this.alertService.successMessage(mess);
    //this.onEmployeeSaved.next(empl);

  }

  private saveFailedHelper(empl?: Employee) {
    //this.alertService.stopLoadingMessage();
    //this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
    //this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  private cancel() {
    this.resetForm();

    //this.alertService.resetStickyMessage();
  }

  private buildForm() {
    //console.log("PersonsTAeditor buildForm " + this.employeeId + " " + this.surname);
    this.employeeTaForm = this.formBuilder.group({
      employeeId: null,
      employee_id: 0,
      surname: ['', Validators.required],
      forename: ['', Validators.required],
      employeeNo: ['', Validators.required],
      email: [null, [Validators.maxLength(320)]],
      mobileTelephone: [null, [Validators.maxLength(30)]],
      professionId: null,
      occupation: [null, [Validators.maxLength(40)]],
      gender: null,
      guserTypeId: null,
      guserPwd: null,
      emplstart: null,
      emplend: null,
      premivisor: null,
      position: null,
      add_employee_no: [null, [Validators.maxLength(8)]],
      employeegroup: null,
      salarysys_user: [null, [Validators.maxLength(15)]],
      worktime_data: [null, [Validators.maxLength(40)]],
      acceptlevel: null,
      userlanguage: null,
      workingpos: null,
      lunchgroup: null,
      lunchdisable: null,
      lastChange: null,
      changemadeby: null
    });
  }

  private resetForm(stopEditing = false) {
    if (stopEditing) {
      this.isEditMode = false;
    }
    if (!this.employee) {
      this.isNewEmployee = true;
      this.employee = new Employee();
    }
    console.log("TAEditform ResetForm empl " + this.isNewEmployee + " id " + this.employee.employeeId + " name " + this.employee.surname);
    this.employeeTaForm.reset({
      employeeId: this.employee.employeeId || '',
      employee_id: this.employee.employee_id || 0,
      surname: this.employee.surname || '',
      forename: this.employee.forename || '',
      employeeNo: this.employee.employeeNo || '',
      email: this.employee.email || null,
      mobileTelephone: this.employee.mobileTelephone || null,
      occupation: this.employee.occupation || null,
      professionId: this.employee.professionId || null,
      gender: this.employee.gender || null,
      salarysys_user: this.employee.salarysys_user || null,
      add_employee_no: this.employee.add_employee_no || null,
      worktime_data: this.employee.worktime_data || null,
      acceptlevel: this.employee.acceptLevel || null,
      userlanguage: this.employee.userLanguage || null,
      workingpos: this.employee.workingPos || null,
      employeegroup: this.employee.employeeGroup || null,
      emplstart: this.employee.emplStart || null,
      emplend: this.employee.emplEnd || null,
      guserTypeId: this.employee.guserTypeId || null,
      guserPwd: this.employee.guserPwd || null,
      premivisor: (this.employee.premiVisor != null) ? 2 : 1 || '1',
      position: (this.employee.position?.charAt(0) === '*' ? 2 : 1) ?? '1',
      lunchgroup: this.employee.lunchGroup || null,
      lunchdisable: (this.employee.lunchDisable) ? 2 : 1 || '1',
      lastChange: this.employee.lastChange || null,
      changemadeby: this.employee.changemadeby || null
    });


    //Object.keys(this.employeeForm.controls).forEach(key => {
    //  this.employeeForm.get(key).setErrors(null);
    //});
  }

  openPasswordDialog(): void {
    const dialogConfig2 = new MatDialogConfig();
    dialogConfig2.disableClose = true;
    dialogConfig2.autoFocus = true;
    dialogConfig2.height = '300px';
    dialogConfig2.width = '300px';
    dialogConfig2.data = {
      employee_id: null
    };
    const dialogRef = this.dialog.open(ChangePassword, dialogConfig2);

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.password1 && result.password2) {
        // Handle the case where you have valid password1 and password2
        this.setPasswd.newpasswd = result.password1;
        this.setPasswd.oldpasswd = result.password2;
      }
      else {
          // Handle the case where password fields should be cleared
          this.setPasswd.newpasswd = null;
      }
      this.setPasswd.onlyCheck = true;
      this.siteService.updatePassword(this.setPasswd, true)
          .subscribe(data => {
            this.handlePassword(data);
          });
    });
  }


  private handlePassword(data: string) {
    if (Array.from(data)[0] == '=') {
      this.employee.guserPwd = data.substring(1);
      this.employeeTaForm.controls['guserPwd'].markAsDirty();
      this.employeeTaForm.patchValue({
        guserPwd: this.employee.guserPwd,
      })
    }
    else if (Array.from(data)[0] == '+') {
      this.alertService.success(data.substring(1));
    }
    else
      this.alertService.error(data);
  }

  get canManageEmployees() {
    return true; //this.accountService.userHasPermission(Permission.manageRolesPermission);
  }
}
