import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppTranslationService } from '../../services/app-translation.service';
import { UserRightsColumns, UserTypeUright } from '../../models/usertypeuright.model';
import { MatTableDataSource } from '@angular/material/table';
import { UserTypeService } from '../../services/usertype.service';

@Component({
  selector: 'usertypeurights-dialog',
  templateUrl: './usertypeuserrights.component.html',
  styleUrls: ['./usertypeuserrights.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UserRightsDialogComponent implements OnInit {
  userTypeRights: UserTypeUright[] = [];
  userRightsForm: FormGroup;
  userRightsColumns: string[] = UserRightsColumns.map((col) => col.key);
  userRightsSchema: any = UserRightsColumns;
  dataSource1 = new MatTableDataSource<UserTypeUright>();
  public userrights: MatTableDataSource<UserTypeUright>;
  originalData: any[];  // Your original data
  editableDataSource: any[];  // Temporary data for editing


  constructor(
    //private fb: FormBuilder,
    private userTypeService: UserTypeService,
    private translationService: AppTranslationService,
    public dialogRef: MatDialogRef<UserRightsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    //this.originalData = [...];  // Initialize your original data
    //this.editableDataSource = JSON.parse(JSON.stringify(this.originalData));  // Create a deep copy for editing

   }

  ngOnInit(): void {
    UserRightsColumns[0].label = this.translationService.getTranslation('usertype.DisplayName');
    UserRightsColumns[1].label = this.translationService.getTranslation('usertype.ReadRight');
    UserRightsColumns[2].label = this.translationService.getTranslation('usertype.WriteRight');
    this.userTypeService.getUserTypeRights(this.data.userType)
      .subscribe(data => {
        this.userTypeRights = data;
        this.dataSource1.data = data;
        console.log('Reading Original from backend', this.userTypeRights);
        this.editableDataSource = JSON.parse(JSON.stringify(this.userTypeRights));  // Create a deep copy for editing
        console.log('Reading Converted backend', this.editableDataSource);
    },
        (error: any) => {
  //console.log(error);
});
  }

  save(): void {
    // Apply changes in editableDataSource to originalData
    this.userTypeRights = JSON.parse(JSON.stringify(this.editableDataSource));
    // Implement save logic to backend here
    console.log('Saving all changes to the backend', this.userTypeRights);
    this.userTypeService.updateUserURights(this.userTypeRights).subscribe({
      next: (response) => console.log('Succesfull', response),
      error: (error) => console.error('Update failed', error)
    });
    this.dialogRef.close(this.userTypeRights);
  }


  cancel(): void {
    this.dialogRef.close();
  }
}
