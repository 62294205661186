<div id="app_container" [class.is-mobile]="mobileQuery.matches" class="nav-container">
  <mat-toolbar ngPreserveWhitespaces color="primary" class="app-toolbar">
    <button mat-icon-button (click)="mainNav.toggle()"><mat-icon>menu</mat-icon></button>
    <h1 class="app-name"><a routerLink="/">{{appTitle}}</a></h1>
    <span class="app-toolbar-spacer"></span>
    <button *ngIf="isUserLoggedIn" class="side-button" (click)="logout()">
      <mat-icon>lock</mat-icon>
      <span>{{'menu.Logout' | translate}}</span>
    </button>
  </mat-toolbar>
  <mat-sidenav-container class="app-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav #mainNav class="app-sidenav-nav mat-elevation-z6" [mode]="'side'"
                 [fixedInViewport]="mobileQuery.matches" fixedTopGap="56" [opened]="true">
      <mat-nav-list dense *ngIf="isUserLoggedIn">
        <mat-accordion>
          <mat-expansion-panel hideToggle *ngIf="canViewPersonsMenu">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{'menu.Persons' | translate}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLinkActive="active" *ngIf="canViewPersons" routerLink="/persons/persons-list">{{'menu.PersonList' | translate}}</a>
            <a mat-list-item routerLinkActive="active" *ngIf="canViewOrganizations" routerLink="/persons/organizationhierarchy">{{'menu.OrganizationHierarchy' | translate}}</a>
            <mat-expansion-panel *ngIf="canViewPersonAuxData" [class.mat-elevation-z0]="true">
              <mat-expansion-panel-header>
                {{'menu.PersonAuxData' | translate}}
              </mat-expansion-panel-header>
              <mat-nav-list dense>
                <a mat-list-item routerLinkActive="active" routerLink="/persons/personauxdata0">{{'menu.EmployeeGroup' | translate}}</a>
                <a mat-list-item routerLinkActive="active" routerLink="/persons/personauxdata1">{{'menu.WorkingPos' | translate}}</a>
                <a mat-list-item routerLinkActive="active" routerLink="/persons/personauxdata2">{{'menu.Profession' | translate}}</a>
              </mat-nav-list>
            </mat-expansion-panel>
          </mat-expansion-panel>
          <mat-expansion-panel *ngIf="canViewReports" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{'menu.Reports' | translate}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLinkActive="active" *ngIf="canViewStampReport" routerLink="/stampreport">{{'menu.StampReport' | translate}}</a>
            <a mat-list-item routerLinkActive="active" *ngIf="canViewOverTimeReport" routerLink="/overtimereport">{{'menu.PeriodOvertimeReport' | translate}}</a>
            <a mat-list-item routerLinkActive="active" *ngIf="canViewAbsenceCodeReport" routerLink="/absencecodereport">{{'menu.AbsenceCodeReport' | translate}}</a>
            <a mat-list-item routerLinkActive="active" *ngIf="canViewAbsenceCodeSumReport" routerLink="/absencecodesumreport">{{'menu.AbsenceCodeSumReport' | translate}}</a>
            <a mat-list-item routerLinkActive="active" *ngIf="canViewSaldoReport" routerLink="/saldoreport">{{'menu.SaldoReport' | translate}}</a>
            <a mat-list-item routerLinkActive="active" *ngIf="canViewVacationReport" routerLink="/vacationreport">{{'menu.VacationReport' | translate}}</a>
            <a mat-list-item routerLinkActive="active" *ngIf="canViewClockingReport" routerLink="/clockingreport">{{'menu.ClockingReport' | translate}}</a>
            <a mat-list-item routerLinkActive="active" *ngIf="canViewStateReport" routerLink="/statereport">{{'menu.StateReport' | translate}}</a>
            <a mat-list-item routerLinkActive="active" *ngIf="canViewSumReport" routerLink="/sumreport">{{'menu.SumReport' | translate}}</a>
            <a mat-list-item routerLinkActive="active" *ngIf="canViewWorkNumberReport" routerLink="/worknumberreport">{{'menu.WorkNumberReport' | translate}}</a>
            <a mat-list-item routerLinkActive="active" *ngIf="canViewMaxWorkingHours" routerLink="/maxworkinghours">{{'menu.MaxWorkingHours' | translate}}</a>
          </mat-expansion-panel>
          <mat-expansion-panel *ngIf="canViewAcceptMenu" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{'menu.Accepts' | translate}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLinkActive="active" *ngIf="canViewStampCorrection" routerLink="/stampcorrection">{{'menu.StampCorrection' | translate}}</a>
            <a mat-list-item routerLinkActive="active" *ngIf="canViewSaldoCorrection" routerLink="/saldocorrection">{{'menu.SaldoCorrection' | translate}}</a>
            <a mat-list-item routerLinkActive="active" *ngIf="canViewUnAcceptedDays" routerLink="/unaccepteddays">{{'menu.UnacceptedDays' | translate}}</a>
          </mat-expansion-panel>
          <mat-expansion-panel hideToggle *ngIf="canViewTimeAttendance">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{'menu.TADefinitions' | translate}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLinkActive="active" routerLink="/timeattendance/tabasetable5">{{'menu.AbsenceCode' | translate}}</a>
            <a mat-list-item routerLinkActive="active" routerLink="/timeattendance/tabasetable7">{{'menu.JobTask' | translate}}</a>
            <a mat-list-item routerLinkActive="active" routerLink="/timeattendance/tabasetable3">{{'menu.CircAdd' | translate}}</a>
            <a mat-list-item routerLinkActive="active" routerLink="/timeattendance/tabasetable4">{{'menu.ExceptDaytypes' | translate}}</a>
            <a mat-list-item routerLinkActive="active" routerLink="/timeattendance/tabasetable6">{{'menu.AcceptLevel' | translate}}</a>
          </mat-expansion-panel>
          <!--<mat-expansion-panel hideToggle *ngIf="canViewTimeAttendanceMenu">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{'menu.TAControl' | translate}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLinkActive="active" routerLink="/timeattendance/tabasetable5">{{'menu.AbsenceCode' | translate}}</a>
          </mat-expansion-panel>-->
        <mat-expansion-panel hideToggle *ngIf="canViewAdministrator">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{'menu.Administrator' | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a mat-list-item routerLinkActive="active" routerLink="/admin/usertypes">{{'menu.UserTypes' | translate}}</a>
          <a mat-list-item routerLinkActive="active" routerLink="/admin/personusers">{{'menu.PersonUsers' | translate}}</a>
          <a mat-list-item routerLinkActive="active" routerLink="/admin/parameterlist">{{'menu.Parameters' | translate}}</a>
          <a mat-list-item routerLinkActive="active" routerLink="/admin/devices">{{'menu.Devices' | translate}}</a>
          <a mat-list-item routerLinkActive="active" routerLink="/admin/correctionjob">{{'menu.CorrectionJob' | translate}}</a>

        </mat-expansion-panel>
          <mat-expansion-panel hideToggle *ngIf="canViewProjectMenu">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{'menu.ProjectFollowup' | translate}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLinkActive="active" *ngIf="canViewProjectAdmin" routerLink="/project/projects">{{'menu.Projects' | translate}}</a>
            <a mat-list-item routerLinkActive="active" *ngIf="canViewProjectAdmin" routerLink="/project/projectpersons">{{'menu.ProjectPersons' | translate}}</a>
            <a mat-list-item routerLinkActive="active" *ngIf="canViewProjectUser" routerLink="/project/projectenter">{{'menu.ProjectEnter' | translate}}</a>
            <a mat-list-item routerLinkActive="active" *ngIf="canViewProjectUser" routerLink="/project/projectreport">{{'menu.ProjectReport' | translate}}</a>
          </mat-expansion-panel>
          <!--<a mat-list-item routerLinkActive="active" routerLink="/infostate">{{'menu.InfoState' | translate}}</a>-->
          <a mat-list-item routerLinkActive="active" *ngIf="canViewSubstitute" routerLink="/substitute">{{'menu.Substitute' | translate}}</a>
          <a mat-list-item routerLinkActive="active" routerLink="/about">{{'menu.About' | translate}}</a>
        </mat-accordion>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <div id="mainContent" fxFlex>
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <alert></alert>
</div>


