export class PersonCalendar {
  id: number;
  startDate: Date;
  endDate: Date;
  baseCalendarId: number;
  baseCalendarDescr: string;
  worktimePercentage: string;
  employeeId: string;
  employee_id: number;
  isEdit: boolean;
}

export const CalendarColumns = [
  {
    key: 'startDate',
    type: 'startdate',
    required: true,
  },
  {
    key: 'endDate',
    type: 'enddate'
  },
  {
    key: 'baseCalendarId',
    type: 'select',
    required: true,
  },
  {
    key: 'baseCalendarDescr',
    type: 'text',
    readonly: true,
  },
  {
    key: 'worktimePercentage',
    type: 'text',
  },
  {
    key: 'isEdit',
    type: 'isEdit',
    label: '',
  },
];

