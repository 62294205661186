import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PersonUser } from '../models/personuser.model';

@Injectable()
export class PersonUserService {
  private personuserUrl: string = 'api/personusers';

  constructor(
    private http: HttpClient) { }

  getPersonUsers(query: string): Observable<PersonUser[]> {
    let endpointUrl = `${this.personuserUrl}/${query}`;
    return this.http.get<PersonUser[]>(endpointUrl).pipe();
  }

  updatePersonUser(personuser): Observable<PersonUser> {
    return this.http.put<PersonUser>(this.personuserUrl, personuser);
  }

  newPersonUser(personuser): Observable<PersonUser> {
    return this.http.post<PersonUser>(this.personuserUrl, personuser);
  }

  deletePersonUser(id: string ) {
    let endpointUrl = `${this.personuserUrl}/${id}`;
    return this.http.delete<PersonUser>(endpointUrl);
  }

}

