import { Component, Inject, OnInit } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ViewChildren, QueryList } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatNestedTreeNode, MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { OrganizationService } from '../../services/organization.service';
import { Organization } from 'src/app/models/organization.model';
import { forEach } from '@angular-devkit/schematics';

export interface DialogData {
  company: string;
  factory: string;
  department: string;
  section: string;
}

export class OrganizationNode {
  name: string;
  children?: OrganizationNode[];
  selected?: boolean;
  id?: number;
  companyNumber: number;
  factoryNumber?: number;
  departmentNumber?: number;
  sectionNumber?: number;
}

@Component({
  selector: 'organizationtree',
  templateUrl: './organizationtree.component.html',
  styleUrls: ['./organizationtree.component.scss']
})
export class OrganizationTreeComponent {
  public treeControl = new NestedTreeControl<OrganizationNode>(node => node.children);
  public dataSource = new MatTreeNestedDataSource<OrganizationNode>();
  organizations: Organization[] = [];
  orgTree: OrganizationNode[] = [];
  selectedNode: OrganizationNode | null = null;
  companyNumber: number;
  factoryNumber: number;
  departmentNumber: number;
  sectionNumber: number;

  constructor(
    private organizationservice: OrganizationService,
    public dialogRef: MatDialogRef<OrganizationTreeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.companyNumber = Number(data.companyNumber);
      this.factoryNumber = Number(data.factoryNumber);
      this.departmentNumber = Number(data.departmentNumber);
      this.sectionNumber = Number(data.sectionNumber);
  }

  ngOnInit() {
    this.organizationservice.getOrganization()
      .subscribe(results => {
        this.organizations = results;
        this.fillOrganizationTree();
        this.findOldSelection(this.companyNumber, this.factoryNumber, this.departmentNumber, this.sectionNumber);
        this.dataSource.data = this.orgTree;
        this.treeControl.dataNodes = this.orgTree;
        this.treeControl.expandAll();
      },
        (error: any) => {
          console.log(error);
        });
  }

  selectNode(node: OrganizationNode) {
    this.selectedNode = node;
    this.data.companyNumber = this.selectedNode.companyNumber;
    this.data.factoryNumber = this.selectedNode.factoryNumber;
    this.data.departmentNumber = this.selectedNode.departmentNumber;
    this.data.sectionNumber = this.selectedNode.sectionNumber;
  }

   onNoClick(): void {
    this.dialogRef.close();
  }

  hasChild = (_: number, node: OrganizationNode) => !!node.children && node.children.length > 0;
  hasNoChild = (_: number, node: OrganizationNode) => !node.children || node.children.length === 0;

  public save() {
    this.data.companyNumber = this.selectedNode.companyNumber;
    this.data.factoryNumber = this.selectedNode.factoryNumber;
    this.data.departmentNumber = this.selectedNode.departmentNumber;
    this.data.sectionNumber = this.selectedNode.sectionNumber;
  }

  findOldSelection(companyNumber, factoryNumber, departmentNumber, sectionNumber) {
    //console.log("OLD " + companyNumber + " F " + factoryNumber + " D " + departmentNumber + " S " + sectionNumber + " LEN " + this.orgTree.length);
    for (let i = 0; i < this.orgTree.length; i++ ) {
      //console.log("NEW " + this.orgTree[i].companyNumber + " F " + this.orgTree[i].factoryNumber + " D " + this.orgTree[i].departmentNumber + " S " + this.orgTree[i].sectionNumber);
      if (this.orgTree[i].companyNumber == companyNumber &&
        this.orgTree[i].factoryNumber == factoryNumber &&
        this.orgTree[i].departmentNumber == departmentNumber &&
        this.orgTree[i].sectionNumber == sectionNumber) {
          this.selectedNode = this.orgTree[i];
          break;
      }
    };
    //console.log("Node " + this.selectedNode);
  }

  fillOrganizationTree() {
    let len = this.organizations.length;
    console.log("FOT " + this.organizations.length);
    let companyNumber = -1;
    let companyIndex = -1;
    let factoryNumber = -1;
    let factoryIndex = -1;
    let departmentNumber = -1;
    let departmentIndex = -1;
    let sectionNumber = -1;
    let sectionIndex = -1;
    let currentType = 0;
    for (let index = 0; index < len; index++) {
      if (this.organizations[index].type == 1) {
        currentType = 1;
        if (this.organizations[index].companyNumber != companyNumber) {
          companyIndex = companyIndex + 1;
          companyNumber = this.organizations[index].companyNumber;
          this.orgTree[companyIndex] = new OrganizationNode();
          //console.log("IOT1 " + index + " cn " + companyNumber + " fn " + factoryNumber + " dn " + departmentNumber + " sn " + sectionNumber);
          if (this.companyNumber == companyNumber && this.factoryNumber == factoryNumber && this.departmentNumber == departmentNumber && this.sectionNumber == sectionNumber) {
            this.selectedNode = this.orgTree[companyIndex];
            this.orgTree[companyIndex].selected = true;
            //console.log("IOT1 found!")
          }
          this.orgTree[companyIndex].name = companyNumber + ' ' + this.organizations[index].description;
          this.orgTree[companyIndex].id = this.organizations[index].id;
          this.orgTree[companyIndex].companyNumber = companyNumber;
          this.orgTree[companyIndex].factoryNumber = factoryNumber;
          this.orgTree[companyIndex].departmentNumber = departmentNumber;
          this.orgTree[companyIndex].sectionNumber = sectionNumber;
          this.orgTree[companyIndex].children = [];
          this.orgTree[companyIndex].children[0] = new OrganizationNode();
          this.orgTree[companyIndex].children.pop();
          factoryIndex = -1;
          factoryNumber = -1;
        }
      }
      else if (this.organizations[index].type == 2) {
        currentType = 2;
        if (this.organizations[index].factoryNumber != factoryNumber) {
          factoryIndex = factoryIndex + 1;
          factoryNumber = this.organizations[index].factoryNumber;
          this.orgTree[companyIndex].children.push(new OrganizationNode());
          this.orgTree[companyIndex].children[factoryIndex].name = factoryNumber + ' ' + this.organizations[index].description;
          this.orgTree[companyIndex].children[factoryIndex].id = this.organizations[index].id;
          this.orgTree[companyIndex].children[factoryIndex].companyNumber = companyNumber;
          this.orgTree[companyIndex].children[factoryIndex].factoryNumber = factoryNumber;
          this.orgTree[companyIndex].children[factoryIndex].departmentNumber = departmentNumber;
          this.orgTree[companyIndex].children[factoryIndex].sectionNumber = sectionNumber;
          //console.log("IOT2 " + index + " cn " + companyNumber + " fn " + factoryNumber + " dn " + departmentNumber + " sn " + sectionNumber);
          if (this.companyNumber == companyNumber && this.factoryNumber == factoryNumber && this.departmentNumber == departmentNumber && this.sectionNumber == sectionNumber) {
            //console.log("IOT2 found!");
            this.selectedNode = this.orgTree[companyIndex].children[factoryIndex];
            this.orgTree[companyIndex].children[factoryIndex].selected = true;
          }
          this.orgTree[companyIndex].children[factoryIndex].children = [];
          this.orgTree[companyIndex].children[factoryIndex].children[0] = new OrganizationNode();
          this.orgTree[companyIndex].children[factoryIndex].children.pop();
          departmentIndex = -1;
          departmentNumber = -1;
        }
      }
      else if (this.organizations[index].type == 3) {
        if (currentType > 3 ) {
          sectionNumber = -1;
        }
        currentType = 3;
        if (this.organizations[index].departmentNumber != departmentNumber) {
          departmentIndex = departmentIndex + 1;
          departmentNumber = this.organizations[index].departmentNumber;
          this.orgTree[companyIndex].children[factoryIndex].children.push(new OrganizationNode());
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].name = departmentNumber + ' ' + this.organizations[index].description;
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].id = this.organizations[index].id;
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].companyNumber = companyNumber;
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].factoryNumber = factoryNumber;
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].departmentNumber = departmentNumber;
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].sectionNumber = sectionNumber;
          //console.log("IOT3 " + index + " cn " + companyNumber + " fn " + factoryNumber + " dn " + departmentNumber + " sn " + sectionNumber);
          if (this.companyNumber == companyNumber && this.factoryNumber == factoryNumber && this.departmentNumber == departmentNumber && this.sectionNumber == sectionNumber) {
            this.selectedNode = this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex];
            this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].selected = true;
            //console.log("IOT3 found!")
          }
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children = [];
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children[0] = new OrganizationNode();
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children.pop();
          sectionIndex = -1;
          sectionNumber = -1;
        }
      }
      else if (this.organizations[index].type == 4) {
        currentType = 4;
        if (this.organizations[index].sectionNumber != sectionNumber) {
          sectionIndex = sectionIndex + 1;
          sectionNumber = this.organizations[index].sectionNumber;
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children.push(new OrganizationNode());
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children[sectionIndex].name = sectionNumber + ' ' + this.organizations[index].description;
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children[sectionIndex].id = this.organizations[index].id;
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children[sectionIndex].companyNumber = companyNumber;
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children[sectionIndex].factoryNumber = factoryNumber;
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children[sectionIndex].departmentNumber = departmentNumber;
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children[sectionIndex].sectionNumber = sectionNumber;
          //console.log("IOT4 " + index + " cn " + companyNumber + " fn " + factoryNumber + " dn " + departmentNumber + " sn " + sectionNumber);
          if (this.companyNumber == companyNumber && this.factoryNumber == factoryNumber && this.departmentNumber == departmentNumber && this.sectionNumber == sectionNumber) {
            this.selectedNode = this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children[sectionIndex];
            this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children[sectionIndex].selected = true;
            //console.log("IOT4 found!")
          }

        }
      }
    }
  }


}

