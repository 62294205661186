import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin.component';
import { UserTypeComponent } from './usertype/usertype.component';
import { PersonUserComponent } from './personuser/personuser.component';
import { ParameterListComponent } from './parameter/parameter-list.component';
import { CorrectionJobComponent } from './correctionjob/correctionjob.component';
import { DeviceComponent } from './device/device.component';
import { AuthService } from '../services/auth.service';
import { AuthGuard } from '../services/auth-guard.service';

const projectRoutes: Routes = [
  {
    path: 'admin', component: AdminComponent,
    children: [
      { path: 'usertypes', component: UserTypeComponent, canActivate: [AuthGuard] },
      { path: 'personusers', component: PersonUserComponent, canActivate: [AuthGuard] },
      { path: 'parameterlist', component: ParameterListComponent, canActivate: [AuthGuard] },
      { path: 'devices', component: DeviceComponent, canActivate: [AuthGuard] },
      { path: 'correctionjob', component: CorrectionJobComponent, canActivate: [AuthGuard] }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(projectRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: [
    AuthService, AuthGuard
  ]
})
export class AdminRoutingModule { }
