import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStoreManager } from './local-store-manager.service';
import { Organization } from '../models/organization.model';
import { OrganizationLeaf } from '../models/organizationleaf.model';
import { Company } from '../models/company.model';
import { Factory } from '../models/factory.model';
import { Department } from '../models/department.model';
import { Section } from '../models/section.model';
import { Observable } from 'rxjs';

@Injectable()
export class OrganizationService {
  private organizationUrl: string = 'api/organizatiohier';
  private organizationLeafUrl: string = 'api/organizationleaf';
  private companyUrl: string = 'api/company';
  private factoryUrl: string = 'api/factory';
  private departmentUrl: string = 'api/department';
  private sectionUrl: string = 'api/section';

  constructor(
    private http: HttpClient,
    private localStorage: LocalStoreManager) {
  }

  getOrganization(): Observable<Organization[]> {
    let system = this.localStorage.getDataObject("sitename");
    let user = this.localStorage.getDataObject("username");
    let endpointUrl = `${this.organizationUrl}/${system}:${user}`;
    return this.http.get<Organization[]>(endpointUrl);
  }

  getOrganizationLeaf(cmpNum: number, facNum: number, depNum: number, secNum: number): Observable<OrganizationLeaf> {
    let system = this.localStorage.getDataObject("sitename");
    let endpointUrl = `${this.organizationLeafUrl}/${system}:${cmpNum}:${facNum}:${depNum}:${secNum}`;
    return this.http.get<OrganizationLeaf>(endpointUrl);
  }
  // Company
  getCompanies(): Observable<Company[]> {
    return this.http.get<Company[]>(this.companyUrl);
  }

  createCompany(company: Company) {
    return this.http.post<number>(this.companyUrl, company)
  }

  updateCompany(company: Company) {
    return this.http.put(this.companyUrl, company, { responseType: 'text' })
      .pipe(
    )
  }


  deleteCompany(id: number) {
    let endpointUrl = `${this.companyUrl}/${id}`;
    return this.http.delete(endpointUrl, { responseType: 'text' });
  }

// Factory
  createFactory(factory: Factory) {
    return this.http.post<number>(this.factoryUrl, factory)
   }

  updateFactory(factory: Factory) {
    return this.http.put(this.factoryUrl, factory, { responseType: 'text' })
      .pipe(
    )
  }

  deleteFactory(companyId: number, factoryId: number): Observable<any>  {
    const params = {
      CompanyId: companyId,
      FactoryId: factoryId
    };
    return this.http.delete(this.factoryUrl, { params, responseType: 'text' } );
  }

// Department
  createDepartment(department: Department) {
    return this.http.post<number>(this.departmentUrl, department)
  }

  updateDepartment(department: Department) {
    return this.http.put(this.departmentUrl, department, { responseType: 'text' })
      .pipe(
    )
  }

  deleteDepartment(factoryId: number, departmentId: number) {
    const params = {
      FactoryId: factoryId,
      DepartmentId: departmentId
    };
    return this.http.delete(this.departmentUrl, { params, responseType: 'text' });
  }

// Section
  createSection(section: Section):Observable<number> {
    return this.http.post<number>(this.sectionUrl, section )
  }

  updateSection(section: Section) {
    return this.http.put(this.sectionUrl, section, { responseType: 'text' })
      .pipe(
    )
  }

  deleteSection(departmentId: number, sectionId: number) {
    const params = {
      DepartmentId: departmentId,
      SectionId: sectionId
    };
    return this.http.delete(this.sectionUrl, { params, responseType: 'text' });
  }

 
}
