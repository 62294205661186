<h1 mat-dialog-title>{{ data.baseTable ? editText : newText }}</h1>
<div mat-dialog-content>
  <ng-container #dynamicComponentContainer></ng-container>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="save()" [disabled]="!isFormDirty" color="primary">
    {{ 'common.Save' | translate }}
  </button>
  <button mat-raised-button (click)="cancel()" color="warn">
    {{ 'common.Cancel' | translate }}
  </button>
</div>
