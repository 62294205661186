import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStoreManager } from './local-store-manager.service';
import { Organization } from '../models/organization.model';
import { ProjectReport } from '../models/projectreport.model';
import { ReportParam } from '../models/reportparam.model';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  responseType: 'blob' as 'json',
  observe: 'response' as 'body'
};

@Injectable()
export class ProjectReportService {
  private reportUrl: string = 'api/projectreport';
  private printUrl: string = 'api/projectprint';
  private excelUrl: string = 'api/projectexcelprint';

  constructor(
    private http: HttpClient,
    private localStorage: LocalStoreManager) { }

  getProjectReport(params: ReportParam, organization: Organization[]): Observable<ProjectReport[]> {
    params.showSvHierarchy = this.localStorage.getDataObject("showSvHierarchy");
    const requestBody = {
      reportParams: params,
      organization: organization
    };
    return this.http.post<ProjectReport[]>(this.reportUrl, requestBody);
  }

  printReport(params: ReportParam, organization: Organization[]): Observable<any> {
    params.showSvHierarchy = this.localStorage.getDataObject("showSvHierarchy");
    const requestBody = {
      reportParams: params,
      organization: organization
    };
    return this.http.post(this.printUrl, requestBody, { responseType: "blob" })
  }

  printExcel(params: ReportParam, organization: Organization[]) {
    params.showSvHierarchy = this.localStorage.getDataObject("showSvHierarchy");
    const requestBody = {
      reportParams: params,
      organization: organization
    };
    return this.http.post(this.excelUrl, requestBody, httpOptions)
  }
}
