import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'project-tooltip.component',
  templateUrl: 'project-tooltip.component.html',
  styleUrls: ['./project-tooltip.component.scss']

})
export class ProjectToolTipDialog {
  constructor(
    public dialogRef: MatDialogRef<ProjectToolTipDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.myComment = data.myComment;
  }
  myComment: string;
}
