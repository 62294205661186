<div class="header">
    <h3 mat-dialog-title>{{data?.title}}</h3>
    <button mat-icon-button mat-dialog-close  (click)="onNoClick(0)">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div mat-dialog-content>
  <p>{{data?.message}}</p>
  <button *ngIf="data?.firstQuestion!=null" class="btn" (click)="onNoClick(data.hiddenType)">{{data?.firstQuestion}}</button>
  <button *ngIf="data?.secondQuestion!=null" class="btn" (click)="onNoClick(4)">{{data?.secondQuestion}}</button>
  <button *ngIf="data?.thirdQuestion!=null" class="btn" (click)="onNoClick(5)">{{data?.thirdQuestion}}</button>
</div>

<div mat-dialog-actions [align]="'end'">
    <button mat-button mat-dialog-close (click)="onNoClick(0)">
        {{data?.cancelCaption}}
    </button>
    <button mat-button color="primary" mat-dialog-close (click)="onNoClick(1)">
        {{data?.confirmCaption}}
    </button>
</div>
