import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStoreManager } from './local-store-manager.service';
import { ReportParam } from '../models/reportparam.model';
import { ProjectLog } from '../models/projectlog.model';
import { WeekDayReport } from '../models/weekdayreport.model';
import { WeekDayProject } from '../models/weekdayproject.model';
import { Observable } from 'rxjs';

@Injectable()
export class EnterProjectService {
  private enterweekdatatUrl: string = 'api/enterweekdata';
  private enterprojectUrl: string = 'api/enterproject';
  private addprojectlogUrl: string = 'api/addprojectlog';
  private updateprojectlogUrl: string = 'api/updateprojectlog';
  private deleteprojectlogUrl: string = 'api/deleteprojectlog';

  constructor(
    private http: HttpClient,
    private localStorage: LocalStoreManager) { }

  getWeekData(params: ReportParam): Observable<WeekDayReport[]> {
    //params.system = this.localStorage.getDataObject("sitename");
    return this.http.post<WeekDayReport[]>(this.enterweekdatatUrl, params);
  }

  getWeekProject(params: ReportParam): Observable<WeekDayProject[]> {
    //params.system = this.localStorage.getDataObject("sitename");
    return this.http.post<WeekDayProject[]>(this.enterprojectUrl, params);
  }

  addNewProject(proj: ProjectLog): Observable<WeekDayProject[]> {
    //proj.sitename = this.localStorage.getDataObject("sitename");
    proj.language = this.localStorage.getDataObject("language");
    return this.http.post<WeekDayProject[]>(this.addprojectlogUrl, proj);
  }

  updateProject(proj: ProjectLog): Observable<WeekDayProject[]> {
    //proj.sitename = this.localStorage.getDataObject("sitename");
    proj.language = this.localStorage.getDataObject("language");
    return this.http.post<WeekDayProject[]>(this.updateprojectlogUrl, proj);
  }

  deleteProject(proj: ProjectLog): Observable<WeekDayProject[]> {
    //proj.sitename = this.localStorage.getDataObject("sitename");
    proj.language = this.localStorage.getDataObject("language");
    return this.http.post<WeekDayProject[]>(this.deleteprojectlogUrl, proj);
  }

}
