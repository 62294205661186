import { Person } from '../models/person.model';
import { Organization } from '../models/organization.model';
export class DataService {
  public PersonData: Person;
  public StampDate: Date;
  public editedOrganization: Organization[];
  public PersonIndex: number;
  public StartDate: Date;
  public EndDate: Date;
  public MyRepDate: Date;
}
