import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  // Use an object to store multiple values
  private formDataSource = new BehaviorSubject<{ [key: string]: any }>({});
  currentFormData = this.formDataSource.asObservable();

  constructor() { }

  // Method to update individual field value
  updateFieldValue(rowId: string, field: string, value: any) {
    const formData = this.formDataSource.getValue();
    //console.log("SHDS " + rowId + " F " + field);
    if (!formData[rowId]) {
      formData[rowId] = {};
    }
    formData[rowId][field] = value;
    this.formDataSource.next(formData);
  }

}
