export class ProjectLog {
  employeeId: string;
  date: string;
  projectName: string;
  sumTime: string;
  comment: string;
  sitename: string;
  language: string;
}

