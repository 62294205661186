import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStoreManager } from './local-store-manager.service';
import { BaseCalendar } from '../models/basecalendar.model';

@Injectable()
export class BaseCalendarService {
  private basecalendarUrl: string = 'api/basecalendars';

  constructor(
    private http: HttpClient,
    private localStorage: LocalStoreManager) { }

  getBaseCalendars(): Observable<BaseCalendar[]> {
    let sitename = this.localStorage.getDataObject("sitename");
    let endpointUrl = `${this.basecalendarUrl}/${sitename}`;
    return this.http.get<BaseCalendar[]>(endpointUrl);
  }

}

