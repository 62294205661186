<div class="page-content">
  <h3>{{'project.ProjectPersons' | translate}}</h3>
  <div class="projectdata" [formGroup]="projectData">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
      <mat-form-field>
        <mat-label>{{'project.Project'|translate}}</mat-label>
        <mat-select id="project" formControlName="project" (selectionChange)="projectChange($event.value)">
          <mat-option *ngFor="let proj of projectdatas" [value]="proj.id">
            {{proj.number}}  {{proj.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="table-striped mat-elevation-z8">
    <mat-table [dataSource]="projectpersons"
               matSort
               matSortActive="{{defaultSortColumn}}" matSortDirection="{{defaultSortOrder}}">

      <ng-container matColumnDef="personName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'common.Name'| translate }} </mat-header-cell>
        <mat-cell *matCellDef="let projectperson"> {{projectperson.name}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'stampreport.StartDate'| translate }} </mat-header-cell>
        <mat-cell *matCellDef="let projectperson"> {{projectperson.startDate| date: 'dd.MM.yyyy'}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'stampreport.EndDate'| translate }} </mat-header-cell>
        <mat-cell *matCellDef="let projectperson"> {{projectperson.endDate | date: 'dd.MM.yyyy'}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="targetTime">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'common.Percent'| translate }} </mat-header-cell>
        <mat-cell *matCellDef="let projectperson"> {{projectperson.targetTime}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef fxFlex="200px">
          <button *ngIf="canInsertPersons" mat-button color="primary" matTooltip="{{'project.NewProjectPerson' | translate}}"  [disabled]="!writeRight" (click)="createProjectPerson(project)">
            <mat-icon class="mat-button-icon">add</mat-icon>{{'project.NewProjectPerson' | translate}}
          </button>
        </mat-header-cell>
        <mat-cell *matCellDef="let project" fxFlex="100px" fxFlexOffset="30px">
          <button mat-icon-button matTooltip="{{'common.Edit' | translate}}" [disabled]="!writeRight" (click)="editProjectPerson(project)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button matTooltip="{{'common.Delete' | translate}}" [disabled]="!writeRight" (click)="confirmDeleteProjectPerson(project)">
            <mat-icon color="warn">delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>
  </div>
</div>








