import { Component } from '@angular/core';

@Component({
  selector: 'app-timeattendance',
  templateUrl: './timeattendance.component.html',
  styleUrls: ['./timeattendance.component.scss']
})
export class TimeAttendanceComponent {

}
