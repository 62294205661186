<div class="container">
  <div class="form-group">
    <div class="row">
      <div class="col-8">
        <strong>{{'menu.ProjectReport' | translate}}</strong>&nbsp;&nbsp;
        <select [(ngModel)]="selectedReportId" name="reportid" (change)="toReportId()">
          <option *ngFor="let rid of reportids" [selected]="rid.booking_id==0" [ngValue]=rid>
            {{rid.descr}}
          </option>
        </select>
      </div>
    </div>
  </div>
  <br />
  <div class="form-group" *ngIf="guser">
    <input type="text" class="form-control" [(ngModel)]="personname" name="personname" readonly>
  </div>
  <div class="form-group" *ngIf="!guser">
    <div class="row">
      <div class="col-1">
        <button class="btn pull-left" (click)="previousname()" [disabled]="(personIndex<=0)"><i class="fa fa-angle-left"></i></button>
      </div>
      <div class="col-8">
        <ng-select [items]="persons"
                   bindLabel="name"
                   (clear)="clearPerson()"
                   (close)="changePerson()"
                   (open)="getPerson()"
                   placeholder={{selectNameStr}}
                   [(ngModel)]="selectedPerson">
        </ng-select>
      </div>
      <div class="col-1">
        <button class="btn pull-right" (click)="nextname()" [disabled]="(!guser && !selectedPerson)"><i class="fa fa-angle-right"></i></button>
      </div>
      <div class="col-2" *ngIf="hasOrganization">
        <button class="btn" (click)="organizationClick()" [disabled]="guser"><strong>{{'common.Organization' | translate}}</strong></button>
      </div>
    </div>
  </div>
  <br />
  <div class="form-group">
    <div class="row">
      <div class="col-4">
        <div class="form-group1">
          <strong>{{'project.Project' | translate}}</strong>&nbsp;&nbsp;
          <select [(ngModel)]="selectedProject" (change)="toProject()">
            <option value="0">{{'helps.AllProjects' | translate}}</option>
            <option *ngFor="let project of projects"
                    [ngValue]=project>
              {{project.name}}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <br />
  <div class="form-group">
    <div class="row">
      <div class="col-5">
        <p><strong>{{'stampreport.StartDate' | translate}}</strong></p>
      </div>
      <div class="col-3">
        <p><strong>{{'stampreport.EndDate' | translate}}</strong></p>
      </div>
      <div class="col-2">
        <p><strong>{{'stampreport.Month' | translate}}</strong></p>
      </div>
      <div class="col-2">
        <p><strong>{{'stampreport.Year' | translate}}</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <my-date-picker name="startdate" [options]="myDatePickerOptions" [locale]="locale" [(ngModel)]="startDate" required #dateField="ngModel"></my-date-picker>
      </div>
      <div class="col-1">
        <button class="btn" (click)="refresh()"><strong>{{'common.Refresh' | translate}}</strong></button>
      </div>
      <div class="col-1">
        <button class="btn" (click)="printpdf()"><i class="fa fa-file-pdf-o"></i></button>
      </div>
      <div class="col-1">
        <button class="btn" (click)="printexcel()"><i class="fa fa-file-excel-o"></i></button>
      </div>
      <div class="col-3">
        <my-date-picker name="enddate" [options]="myDatePickerOptions" [locale]="locale" [(ngModel)]="endDate" required #dateField="ngModel"></my-date-picker>
      </div>
      <div class="col-2">
        <div class="form-group1">
          <select [(ngModel)]="selectedMonth" name="monthid" (change)="toMonth()">
            <option *ngFor="let month of allmonths" [selected]="month.booking_id==0" [ngValue]=month>
              {{month.descr}}
            </option>
          </select>
        </div>
      </div>
      <div class="col-1">
        <div class="form-group1">
          <select [(ngModel)]="selectedYear" name="yearid" (change)="toYear()">
            <option *ngFor="let year of years" [selected]="year.booking_id==0" [ngValue]=year>
              {{year.descr}}
            </option>
          </select>
        </div>
      </div>

    </div>
  </div>
  <ngx-spinner></ngx-spinner>
  <div class="form-group">
    <ngx-datatable class="material"
                   [columnMode]="'force'"
                   [rows]="projectreports"
                   [headerHeight]="30"
                   [messages]="{emptyMessage: emptyMess, selectedMessage: false}"
                   [rowHeight]="'auto'">
      <ngx-datatable-column *ngIf="reportid!=2" name="{{'employee.Surname' | translate}}" [width]="80">
        <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
          {{row.surname}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="reportid!=2" name="{{'employee.Forename' | translate}}" [width]="50">
        <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
          {{row.forename}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="reportid==0" name="{{'common.Date' | translate}}" [width]="50">
        <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
          {{row.projectDate}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="reportid!=3" name="{{'common.Name' | translate}}" [width]="120">
        <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
          {{row.name}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="reportid==1 || reportid==2 " name="{{'common.Descr' | translate}}" [width]="200">
        <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
          {{row.description}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'worknumberreport.Sum' | translate}}" [width]="30">
        <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
          {{row.sumTime}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="reportid==0" name="{{'common.Comment' | translate}}" [width]="350">
        <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
          {{row.comment}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="reportid==1" name="{{'common.Percent' | translate}}" [width]="100">
        <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
          {{row.targetTime}}
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>
