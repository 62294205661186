import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { AppTranslationService } from "../../services/app-translation.service";
import { IMyDpOptions, IMyDateModel, IMyDate } from 'mydatepicker';
import { PersonNameService } from "../../services/personname.service";
import { OrganizationComponent } from '../../components/organization/organization.component';
import { OrganizationService } from '../../services/organization.service';
import { CorrectionJobService } from '../../services/correctionjob.service';
import { WtGroupService } from '../../services/wtgroup.service';
import { ReportParam } from '../../models/reportparam.model';
import { Person } from '../../models/person.model';
import { WtGroup } from '../../models/wtgroup.model';
import { CorrectionJob } from '../../models/correctionjob.model';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { AlertService, DialogType } from '../../services/alert.service';
import { DataService } from '../../services/data.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BaseReportComponent } from '../../components/base.report.component';
import { BookingId } from '../../models/bookingid.model';
import { Organization } from '../../models/organization.model';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';

@Component({
  selector: 'correctionjob',
  templateUrl: './correctionjob.component.html',
  styleUrls: ['./correctionjob.component.scss']
})
export class CorrectionJobComponent
  extends BaseReportComponent implements OnInit {

  reportids: BookingId[] = [
    { booking_id: 1, descr: this.translationService.getTranslation('correctionjob.ToPaymentSystem') },
    { booking_id: 2, descr: this.translationService.getTranslation('correctionjob.ToServiceSystem') },
    { booking_id: 3, descr: this.translationService.getTranslation('correctionjob.NewCalculationNoManual') },
    { booking_id: 4, descr: this.translationService.getTranslation('correctionjob.NewCalculationWithManual') },
    { booking_id: 5, descr: this.translationService.getTranslation('correctionjob.AccRemoval') }
  ];

  constructor(
    public dialog: MatDialog,
    public dataservice: DataService,
    public alertService: AlertService,
    public authService: AuthService,
    public translationService: AppTranslationService,
    public personNameService: PersonNameService,
    public localStorage: LocalStoreManager,
    public organizationService: OrganizationService,
    public router: Router,
    public spinner: NgxSpinnerService,
    private correctionJobService: CorrectionJobService,
    private wtGroupService: WtGroupService
  ) {
    super(dialog, dataservice, personNameService, localStorage, translationService, organizationService, alertService, router, spinner);
  }
  organizationTree: OrganizationComponent = new OrganizationComponent(this.dataservice, this.organizationService, null, null, null);
  dateNow: IMyDate = this.setReportDate(1);
  dateOlder: IMyDate = this.setReportDate(7);
  persons: Person[] = [];
  wtgroups: WtGroup[] = [];
  correctionjobs: CorrectionJob[] = [];
  params = new ReportParam();
  ownDataVisible: boolean;
  allowdate: boolean;
  personname: string;
  selectedPerson: Person;
  selectNameStr: string;
  personIndex: number;
  message: string;
  currentDate: Date = new Date();
  rights: number;
  //wtGroupNumber: number;

  setReportDate(offset: number): IMyDate {
    let date: Date = new Date();
    date.setDate(date.getDate() - offset);
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
  }

  getDayOfWeek(): number {
    let date: Date = new Date();
    //date.setDate(date.getDate());
    return (date.getDay());
  }


  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'dd.mm.yyyy',
    disableSince: this.dateNow,
    width: "100%",
    showClearDateBtn: false,
    alignSelectorRight: false,
  };

  public locale: string;
  public startDate: any = { date: { year: this.dateOlder.year, month: this.dateOlder.month, day: this.dateOlder.day } };
  public endDate: any = { date: { year: this.dateNow.year, month: this.dateNow.month, day: this.dateNow.day } };
  public showUnaccepted: boolean;
  public weekReport: boolean;

  ngOnInit() {
    super.ngOnInit();
    this.personIndex = 0;
    super.clearPerson();
    this.organizationTree.getAndFillOrganizationTree();
    this.selectNameStr = this.translationService.getTranslation('stampreport.SelectName');
    this.locale = this.localStorage.getDataObject("language");
    //this.getWorkTimeGroups();

  }

  selectedReportId = this.reportids[3];
  toReportId() {
    this.reportid = this.selectedReportId.booking_id;
  }

  getPersonNames() {
    this.personNameService.getpersonnames("")
      .subscribe(results => {
        this.persons = results;
      },
        (error: any) => {
          console.log(error);
        });
  }

  //getWorkTimeGroups() {
  //  this.wtGroupService.getwtgroups()
  //    .subscribe(data => {
  //      this.wtgroups = data;
  //    },
  //      (error: any) => {
  //        //console.log(error);
  //      });
  //}

  //selectedWTGroup = this.wtgroups[0];
  //toNumber() {
  //  if (this.wtgroups.length > 1) {
  //    this.wtGroupNumber = this.selectedWTGroup.wh_group_id;
  //  }
  //  //this.refresh();
  //}


  organizationClick() {
    /*    this.organizationTree.getOrganizationTree();*/
    super.openDialog();
  }

  getPerson() {
    this.personNameService.getpersonnames("")
      .subscribe(results => {
        this.persons = results;
      },
        (error: any) => {
          console.log(error);
        });
  }

  clearPerson() {
    super.clearPerson();
  }

  changePerson() {
    super.changePerson();
    /*    this.refresh();*/
  }

  previousname() {
    super.previousname();
    /*    this.refresh();*/
  }

  nextname() {
    super.nextname();
    /*    this.refresh();*/
  }

  execQuery() {
    if (!this.checkReportDates())
      return;
    this.stampReportParameters();
    if (this.organizationTree.newOrganization.length == 0)
      this.organizationTree.newOrganization[0] = new Organization();
    this.params.reportType = this.selectedReportId.booking_id;
    this.params.employee_id = this.employeeId;
    //let wtg = this.wtGroupNumber == null ? 0 : this.wtGroupNumber;
    //this.params.extra = wtg.toString();
    this.spinner.show(); // start spinner
    this.correctionJobService.execcorrectionjob(this.params, this.organizationTree.newOrganization)
      .subscribe(data => {
        this.spinner.hide(); // stop spinner
        if (data != null) {
          this.correctionjobs = data as CorrectionJob[]
        }
        this.authService.refreshLogin()
          .subscribe(data => {
            if (data) {
              this.authService.refresh_userdata(data);
            }
          },
            (error: any) => {
            });
      },
        (error: any) => {
          this.spinner.hide(); // Stop spinner on error
          //const errorMessage = error?.error?.details || "An error occurred while processing the correction job.";
          //alert(errorMessage); // Or set a UI message variable for display

        });
  }


  private handleResultMessage(data: string) {

    if (data.length == 0)
      data = this.translationService.getTranslation('unaccepted.Ok');
    this.alertService.showDialog(data, DialogType.alert);
  }

  ngOnDestroy() {
    //this.dataservice.PersonData = this.selectedPerson;
    //this.dataservice.PersonIndex = this.personIndex;
  }

}


