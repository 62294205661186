<div>
  <form class="form-horizontal" name="organizationForm" #f="ngForm">
    <div class="col-sm-12">
      <div class="form-group">
        <label>
          <strong>{{'common.Organization' | translate}}</strong>
        </label>
        <ngx-treeview [config]="config" [items]="orgTree">
        <!--<ngx-treeview [config]="config" [items]="orgTree" (filterChange)="onFilterChange($event)" (selectedChange)="onSelectedChange($event)">-->
        </ngx-treeview>
      </div>
    </div>
    <div class="col-sm-12">
      <button type="button" (click)="cancel()" class="btn buttonCancel"><strong>{{'common.Cancel' | translate}}</strong></button>
      <button type="button" (click)="save()" class="btn buttonOk"><strong>{{'common.Save' | translate}}</strong></button>
    </div>
  </form>
</div>
