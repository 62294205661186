import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStoreManager } from './local-store-manager.service';
import { CalcRule } from '../models/calcrule.model';

@Injectable()
export class CalcRuleService {
  private calcruleUrl: string = 'api/calcrules';

  constructor(
    private http: HttpClient,
    private localStorage: LocalStoreManager) { }

  getcalcrules(): Observable<CalcRule[]> {
    let language = this.localStorage.getDataObject("language");
    let sitename = this.localStorage.getDataObject("sitename");
    let endpointUrl = `${this.calcruleUrl}/${sitename}:${language}`;
    return this.http.get<CalcRule[]>(endpointUrl);
  }

}

