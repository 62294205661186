import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AlertService, DialogType } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { ProjectService } from '../../services/project.service';
import { Project } from '../../models/project.model';
import { EditProjectDialogComponent } from './edit-project-dialog.component';
import { ApiResult } from '../../base.service';
import { LocalStoreManager } from '../../services/local-store-manager.service';

@Component({
  selector: 'project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['number', 'name', 'description', 'startDate', 'endDate', 'personName', 'costPlace','actions'];
  public projects: MatTableDataSource<Project>;
  sourceProject: Project;
  editingProjectName: { name: string };
  public show: boolean = true;
  defaultPageIndex: number = 0;
  defaultPageSize: number = 10;
  public defaultSortColumn: string = "number";
  public defaultSortOrder: string = "asc";
  defaultFilterColumn: string = "name";
  filterQuery: string = null;
  tableNumber: number = 0;
  numberOfRows: number = 0;
  rights: number = 0;
  writeRights: number = 0;
  writeRight: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  filterTextChanged: Subject<string> = new Subject<string>();

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private projectService: ProjectService,
    private dialog: MatDialog,
    public localStorage: LocalStoreManager
  ) {
  }

  get canManageProjects() {
    return true;
  }

  ngOnInit() {
    this.loadData();
    this.rights = this.localStorage.getDataObject("readrights");
    this.writeRights = this.localStorage.getDataObject("userrights");
    this.writeRight = (this.writeRights & 4096) == 4096;
  }

  onFilterTextChanged(filterText: string) {
    if (this.filterTextChanged.observers.length === 0) {
      this.filterTextChanged
        .pipe(debounceTime(1000), distinctUntilChanged())
        .subscribe(query => {
          this.loadData(query);
        });
    }
    this.filterTextChanged.next(filterText);
  }

  loadData(query: string = null) {
    var pageEvent = new PageEvent();
    pageEvent.pageIndex = this.defaultPageIndex;
    pageEvent.pageSize = this.defaultPageSize;
    if (query) {
      this.filterQuery = query;
    }
    else
       this.filterQuery = null;
    this.getData(pageEvent);
  }

  getData(event: PageEvent) {
    var sortColumn = (this.sort)
      ? this.sort.active
      : this.defaultSortColumn;
    var sortOrder = (this.sort)
      ? this.sort.direction
      : this.defaultSortOrder;
    var filterColumn = (this.filterQuery)
      ? this.defaultFilterColumn
      : null;
    var filterQuery = (this.filterQuery)
      ? this.filterQuery
      : null;
    this.projectService.getData<ApiResult<Project>>(
      this.tableNumber,
      event.pageIndex,
      event.pageSize,
      sortColumn,
      sortOrder,
      filterColumn,
      filterQuery)
      .subscribe(result => {
        this.paginator.length = result.totalCount;
        this.paginator.pageIndex = result.pageIndex;
        this.paginator.pageSize = result.pageSize;
        this.projects = new MatTableDataSource<Project>(result.data);
        this.numberOfRows = this.paginator.length;
        if (this.numberOfRows == 0) {
          var mess = this.translationService.getTranslation('notifications.NoDataAvailable');
          this.alertService.errorMessage(mess);
        }
      }, error => console.error(error));
  }


  ngAfterViewInit() {
    //this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
  }

  public createProject() {
    const dialogRef = this.dialog.open(EditProjectDialogComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { project:null }
      });
    dialogRef.afterClosed().subscribe(r => {
      if (r && this.canManageProjects) {
        this.projectService.newProject(r);
      }
      this.loadData();
    });
  }

  public editProject(project?: Project) {
    if (!this.writeRight)
      return;
    const dialogRef = this.dialog.open(EditProjectDialogComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { project }
      });
    dialogRef.afterClosed().subscribe(r => {
      if (r && this.canManageProjects) {
        this.projectService.updateProject(r);
      }
      this.loadData();
    });
  }

  public confirmDelete(project: Project) {
    let delQ = this.translationService.getTranslation('notifications.DeleteItem');
    delQ = delQ + ' ' + this.translationService.getTranslation('project.Project');
    this.alertService.showDialog(delQ + '?',
      DialogType.confirm, () => this.deleteStamp(project));
  }

  deleteStamp(project) {
    let id = project.id;
    this.projectService.deleteProject(id)
      .subscribe(results => {
        this.loadData();
      },
        error => {
        });
  }
}

