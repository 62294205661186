export class ProjectPerson {
  id: number;
  projectNumber: number;
  projectName: string;
  employeeId: string;
  name: string;
  startDate: Date;
  endDate: Date;
  targetTime: number;
}

