<form #form="ngForm" [formGroup]="projectForm" novalidate (ngSubmit)="save()" fxLayout="column" [fxLayoutGap]="isEditMode ? '1em' : '0'" autocomplete="off">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'common.Number' | translate}}</mat-label>
      <input matInput formControlName="number" [readonly]="!isNewProject" [attr.tabindex]="!isNewProject ? -1 : null"/>
      <mat-error *ngIf="number.hasError('required')">
        {{'notifications.FieldRequired' | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'common.Name' | translate}}</mat-label>
      <input matInput formControlName="name" />
      <mat-error *ngIf="name.hasError('required')">
        {{'notifications.FieldRequired' | translate}}
      </mat-error>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'common.Descr' | translate}}</mat-label>
      <input matInput formControlName="description" />
    </mat-form-field>
    <mat-error *ngIf="description.hasError('required')">
      {{'notifications.FieldRequired' | translate}}
    </mat-error>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field [floatLabel]="floatLabels" fxFlex.gt-sm="0 1 calc(50% - 0.5em)">
      <mat-label>{{'stampreport.StartDate' | translate}}</mat-label>
      <input matInput formControlName="startDate" [matDatepicker]="picker1" (click)="picker1.open()" />
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>

    <mat-form-field [floatLabel]="floatLabels" fxFlex.gt-sm="0 1 calc(50% - 0.5em)">
      <mat-label>{{'stampreport.EndDate' | translate}}</mat-label>
      <input matInput formControlName="endDate" [matDatepicker]="picker2" (click)="picker2.open()" />
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field *ngIf="persons">
      <mat-label>{{'common.ProjectOwner'|translate}}</mat-label>
      <mat-select id="employeeId" formControlName="employeeId">
        <mat-option [value]=null>{{'project.NoOwner' | translate}}</mat-option>
        <mat-option *ngFor="let person of persons" [value]="person.employeeId">
          {{person.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field *ngIf="costplaces">
      <mat-label>{{'common.CostPlace'|translate}}</mat-label>
      <mat-select id="costPlace" formControlName="costPlace">
        <mat-option [value]=null>{{'project.NoCostplace' | translate}}</mat-option>
        <mat-option *ngFor="let cp of costplaces" [value]="cp.costplace">
          {{cp.costplace}}  {{cp.description}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>


  <div>&nbsp;</div>
</form>

