import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatNestedTreeNode, MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { OrganizationService } from '../../services/organization.service';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { AlertService, DialogType } from '../../services/alert.service';
import { DataService } from '../../services/data.service';
import { Organization } from '../../models/organization.model';
import { Company } from '../../models/company.model';
import { Factory } from '../../models/factory.model';
import { Department } from '../../models/department.model';
import { Section } from '../../models/section.model';
import { OrganizationLeaf } from '../../models/organizationleaf.model';

export class OrganizationNode {
  name: string;
  children?: OrganizationNode[];
  selected?: boolean;
  id?: number;
  companyNumber: number;
  factoryNumber?: number;
  departmentNumber?: number;
  sectionNumber?: number;
  description: string;
  type: number;
}

@Component({
  selector: 'organizationhierarchy',
  templateUrl: './organizationhierarchy.component.html',
  styleUrls: ['./organizationhierarchy.component.scss',]
})
export class OrganizationHierarchyComponent implements OnInit {
  hierarchyForm: FormGroup;
  public treeControl = new NestedTreeControl<OrganizationNode>(node => node.children);
  public dataSource = new MatTreeNestedDataSource<OrganizationNode>();
  organizations: Organization[] = [];
  orgTree: OrganizationNode[] = [];
  organizationLeaf: OrganizationLeaf;
  selectedNode: OrganizationNode | null = null;
  companyNumber: number;
  factoryNumber: number;
  departmentNumber: number;
  sectionNumber: number;
  companyData: Company = new Company();
  factoryData: Factory = new Factory();
  departmentData: Department = new Department();
  sectionData: Section = new Section();
  rights: number = 0;
  writeRights: number = 0;
  writeRight: boolean = false;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private organizationService: OrganizationService,
    private localStorage: LocalStoreManager,
    public dataservice: DataService,
    private formBuilder: FormBuilder
  ) {
    this.buildForm();
  }

  ngOnInit() {
    this.getOrganization();
    this.rights = this.localStorage.getDataObject("readrights");
    this.writeRights = this.localStorage.getDataObject("userrights");
    this.writeRight = (this.writeRights & 256) == 256;
  }

  getOrganization() {
    this.organizationService.getOrganization()
      .subscribe(results => {
        this.organizations = results;
        this.fillOrganizationTree();
        this.dataSource.data = this.orgTree;
        this.treeControl.dataNodes = this.orgTree;
        this.treeControl.expandAll();
      },
        (error: any) => {
          console.log(error);
        });
  }

  hasChild = (_: number, node: OrganizationNode) => !!node.children && node.children.length > 0;

  buildForm() {
    this.hierarchyForm = this.formBuilder.group({
      companyId: null,
      company: [{ value: null, disabled: true }],
      companyName: [{ value: null, disabled: true }],
      factoryId: null,
      factory: [{ value: null, disabled: true }],
      factoryName: [{ value: null, disabled: true }],
      departmentId: null,
      department: [{ value: null, disabled: true }],
      departmentName: [{ value: null, disabled: true }],
      sectionId: null,
      section: [{ value: null, disabled: true }],
      sectionName: [{ value: null, disabled: true }]
    });
  }

  get companyId() {
    return this.hierarchyForm.get('companyId');
  }
  get company() {
    return this.hierarchyForm.get('company');
  }
  get companyName() {
    return this.hierarchyForm.get('companyName');
  }
  get factoryId() {
    return this.hierarchyForm.get('factoryId');
  }
  get factory() {
    return this.hierarchyForm.get('factory');
  }
  get factoryName() {
    return this.hierarchyForm.get('factoryName');
  }
  get departmentId() {
    return this.hierarchyForm.get('departmentId');
  }
  get department() {
    return this.hierarchyForm.get('department');
  }
  get departmentName() {
    return this.hierarchyForm.get('departmentName');
  }
  get sectionId() {
    return this.hierarchyForm.get('sectionId');
  }
  get section() {
    return this.hierarchyForm.get('section');
  }
  get sectionName() {
    return this.hierarchyForm.get('sectionName');
  }

  selectNode(node: OrganizationNode) {
    this.selectedNode = node;
    if (this.selectedNode.type == 1) {
      this.hierarchyForm.get('company').enable();
      this.hierarchyForm.get('companyName').enable();
      this.hierarchyForm.get('factory').enable();
      this.hierarchyForm.get('factoryName').enable();
      this.hierarchyForm.get('department').disable();
      this.hierarchyForm.get('departmentName').disable();
      this.hierarchyForm.get('section').disable();
      this.hierarchyForm.get('sectionName').disable();
    }
    else if (this.selectedNode.type == 2) {
      this.hierarchyForm.get('company').disable();
      this.hierarchyForm.get('companyName').disable();
      this.hierarchyForm.get('factory').enable();
      this.hierarchyForm.get('factoryName').enable();
      this.hierarchyForm.get('department').enable();
      this.hierarchyForm.get('departmentName').enable();
      this.hierarchyForm.get('section').disable();
      this.hierarchyForm.get('sectionName').disable();
    }
    else if (this.selectedNode.type == 3) {
      this.hierarchyForm.get('company').disable();
      this.hierarchyForm.get('companyName').disable();
      this.hierarchyForm.get('factory').disable();
      this.hierarchyForm.get('factoryName').disable();
      this.hierarchyForm.get('department').enable();
      this.hierarchyForm.get('departmentName').enable();
      this.hierarchyForm.get('section').enable();
      this.hierarchyForm.get('sectionName').enable();
    }
    else {
      this.hierarchyForm.get('company').disable();
      this.hierarchyForm.get('companyName').disable();
      this.hierarchyForm.get('factory').disable();
      this.hierarchyForm.get('factoryName').disable();
      this.hierarchyForm.get('department').disable();
      this.hierarchyForm.get('departmentName').disable();
      this.hierarchyForm.get('section').enable();
      this.hierarchyForm.get('sectionName').enable();
    }
    this.organizationService.getOrganizationLeaf(node.companyNumber, node.factoryNumber, node.departmentNumber, node.sectionNumber)
      .subscribe(results => {
        if (results != null) {
          this.companyId.setValue(results.companyId);
          this.company.setValue(results.companyNumber);
          this.companyName.setValue(results.companyDesc);
          this.factoryId.setValue(results.factoryId);
          this.factory.setValue(results.factoryNumber);
          this.factoryName.setValue(results.factoryDesc);
          this.departmentId.setValue(results.departmentId);
          this.department.setValue(results.departmentNumber);
          this.departmentName.setValue(results.departmentDesc);
          this.sectionId.setValue(results.sectionId);
          this.section.setValue(results.sectionNumber);
          this.sectionName.setValue(results.sectionDesc);
          console.log("CMP " + results.companyId + " " + results.companyNumber + "Desc " + results.companyDesc + " FAC " + results.factoryNumber + " DEP " + results.departmentNumber);
          console.log("SEC " + results.sectionId + " " + results.sectionNumber + "Desc " + results.sectionDesc );
        }
      },
        (error: any) => {
          console.log(error);
        });
  }


  get canManageOrganizations() {
    return true;
  }

  public createCompany() {
    this.companyData.number = Number(this.hierarchyForm.get('company').value);
    this.companyData.description = this.hierarchyForm.get('companyName').value;
    this.organizationService.createCompany(this.companyData)
      .subscribe(id => {
        this.companyData.id = id;
        this.handleCreateMessage(0, id);
        this.refreshTree(1,1);
      },
        (error: any) => {
          console.log(error);
        });
  }

  public editCompany() {
    this.companyData.id = this.hierarchyForm.get('companyId').value;
    this.companyData.number = Number(this.hierarchyForm.get('company').value);
    this.companyData.description = this.hierarchyForm.get('companyName').value;
    this.organizationService.updateCompany(this.companyData)
      .subscribe(data => {
        this.handleMessage(1, data)
        this.refreshTree(1,2);
      },
        (error: any) => {
          console.log(error);
        });
  }

  public deleteCompany() {
    this.companyData.id = this.hierarchyForm.get('companyId').value;
    this.organizationService.deleteCompany(this.companyData.id)
      .subscribe(data => {
        this.handleMessage(2, data)
        this.refreshTree(1,3);
      },
        (error: any) => {
          console.log(error);
        });
  }

  public createFactory() {
    this.factoryData.number = Number(this.hierarchyForm.get('factory').value);
    this.factoryData.description = this.hierarchyForm.get('factoryName').value;
    this.factoryData.companyId = this.hierarchyForm.get('companyId').value;
    this.organizationService.createFactory(this.factoryData)
      .subscribe(id => {
        this.factoryData.id = id;
        this.handleCreateMessage(0, id)
        this.refreshTree(2,1);
      },
        (error: any) => {
          console.log(error);
        });
  }

  public editFactory() {
    this.factoryData.id = this.hierarchyForm.get('factoryId').value;
    this.factoryData.companyId = this.hierarchyForm.get('companyId').value;
    this.factoryData.number = Number(this.hierarchyForm.get('factory').value);
    this.factoryData.description = this.hierarchyForm.get('factoryName').value;
    this.organizationService.updateFactory(this.factoryData)
      .subscribe(data => {
        this.handleMessage(1, data)
        this.refreshTree(2,2);
      },
        (error: any) => {
          console.log(error);
        });
  }

  public deleteFactory() {
    let companyId = this.hierarchyForm.get('companyId').value;
    let factoryId = this.hierarchyForm.get('factoryId').value;
    this.organizationService.deleteFactory(companyId, factoryId)
      .subscribe(data => {
        this.handleMessage(2, data)
        this.refreshTree(2,3);
      },
        (error: any) => {
          console.log(error);
        });
  }

  public createDepartment() {
    this.departmentData.number = Number(this.hierarchyForm.get('department').value);
    this.departmentData.description = this.hierarchyForm.get('departmentName').value;
    this.departmentData.factoryId = this.hierarchyForm.get('factoryId').value;
    this.organizationService.createDepartment(this.departmentData)
      .subscribe(id => {
        this.departmentData.id = id;
        this.handleCreateMessage(0, id)
        this.refreshTree(3,1);
      },
        (error: any) => {
          console.log(error);
        });
  }

  public editDepartment() {
    this.departmentData.id = this.hierarchyForm.get('departmentId').value;
    this.departmentData.factoryId = this.hierarchyForm.get('factoryId').value;
    this.departmentData.number = Number(this.hierarchyForm.get('department').value);
    this.departmentData.description = this.hierarchyForm.get('departmentName').value;
    this.organizationService.updateDepartment(this.departmentData)
      .subscribe(data => {
        this.handleMessage(1, data)
        this.refreshTree(3,2);
      },
        (error: any) => {
          console.log(error);
        });
  }

  public deleteDepartment() {
    let factoryId = this.hierarchyForm.get('factoryId').value;
    let departmentId = this.hierarchyForm.get('departmentId').value;
    console.log("DD1 " + departmentId);
    this.organizationService.deleteDepartment(factoryId, departmentId)
      .subscribe(data => {
        this.handleMessage(2, data)
        this.refreshTree(3, 3);
      },
        (error: any) => {
          console.log(error);
        });
  }

  public createSection() {
    this.sectionData.number = Number(this.hierarchyForm.get('section').value);
    this.sectionData.description = this.hierarchyForm.get('sectionName').value;
    this.sectionData.departmentId = this.hierarchyForm.get('departmentId').value;
    this.organizationService.createSection(this.sectionData)
      .subscribe(id => {
        console.log("CS " + id + " num " + this.sectionData.number + " desc " + this.sectionData.description  );
        this.sectionData.id = id;
        //this.sectionId.setValue(id);
        this.handleCreateMessage(0, id)
        console.log("CS after HCM " + id);
        //this.sectionData.id = this.hierarchyForm.get('sectionId').value;
        //console.log("CS2 " + this.sectionData.id); 
        this.refreshTree(4, 1);
      },
        (error: any) => {
          console.log("CS Errorr "  + error);
        });
  }

  public editSection() {
    this.sectionData.id = this.hierarchyForm.get('sectionId').value;
    this.sectionData.departmentId = this.hierarchyForm.get('departmentId').value;
    this.sectionData.number = Number(this.hierarchyForm.get('section').value);
    this.sectionData.description = this.hierarchyForm.get('sectionName').value;
    console.log("ES secData ID " + this.sectionData.id + " NUM " + this.sectionData.number);
    this.organizationService.updateSection(this.sectionData)
      .subscribe(data => {
        this.handleMessage(1, data)
        this.refreshTree(4, 2);
      },
        (error: any) => {
          console.log(error);
        });
  }

  public deleteSection() {
    let departmentId = this.hierarchyForm.get('departmentId').value;
    let sectionId = this.hierarchyForm.get('sectionId').value;
    console.log("DS1 " + sectionId);
    this.organizationService.deleteSection(departmentId, sectionId)
      .subscribe(data => {
        this.handleMessage(2, data)
        console.log("DS before refresh ");
        this.refreshTree(4, 3);
      },
        (error: any) => {
          console.log(error);
        });
  }

  refreshTree(orgType: number, operType: number) {
    console.log("RefreshTree  " + orgType + " Oper t " + operType);
    if (orgType == 1) {
      if (operType == 1) {
        this.companyId.setValue(this.companyData.id);
      }
      else if (operType == 3) {
        this.companyId.setValue(null);
        this.company.setValue(null);
        this.companyName.setValue(null);
      }
    }
    if (orgType == 2) {
      if (operType == 1) {
        this.factoryId.setValue(this.factoryData.id);
      }
      else if (operType == 3) {
        this.factoryId.setValue(null);
        this.factory.setValue(null);
        this.factoryName.setValue(null);
      }
    }
    if (orgType == 3) {
      if (operType == 1) {
        this.departmentId.setValue(this.departmentData.id);
      }
      else if (operType == 3) {
        this.departmentId.setValue(null);
        this.department.setValue(null);
        this.departmentName.setValue(null);
      }
    }
    else if (orgType == 4) {
      if (operType == 1) {
        this.sectionId.setValue(this.sectionData.id);
      }
      else if (operType == 3) {
        this.sectionId.setValue(null);
        this.section.setValue(null);
        this.sectionName.setValue(null);
      }
    }
    this.getOrganization();
  }

  private handleMessage(updType: number, data: string) {
    if (data.length > 0) {
      switch (updType) {
        case 1: //update
          this.alertService.error(this.translationService.getTranslation('notifications.UpdateRecordFailed'));
          break;
        case 2: //delete
          this.alertService.error(this.translationService.getTranslation('notifications.DeleteRecordFailed'));
          break;
      }
    }
    else {
      switch (updType) {
        case 1: //update
          this.alertService.successMessage(this.translationService.getTranslation('notifications.UpdateRecordSucceeded'));
          break;
        case 2: //delete
          this.alertService.successMessage(this.translationService.getTranslation('notifications.DeleteRecordSucceeded'));
          break;
      }
    }
  }

  private handleCreateMessage(updType: number, id: number) {
    if (id == 0) {
      this.alertService.error(this.translationService.getTranslation('notifications.InserteRecordFailed'));
    }
    else
      this.alertService.successMessage(this.translationService.getTranslation('notifications.InsertRecordSucceeded'));
  }

  fillOrganizationTree() {
    let len = this.organizations.length;
    console.log("FOT " + this.organizations.length);
    let companyNumber = -1;
    let companyIndex = -1;
    let factoryNumber = -1;
    let factoryIndex = -1;
    let departmentNumber = -1;
    let departmentIndex = -1;
    let sectionNumber = -1;
    let sectionIndex = -1;
    let currentType = 0;
    for (let index = 0; index < len; index++) {
      if (this.organizations[index].type == 1) {
        currentType = 1;
        if (this.organizations[index].companyNumber != companyNumber) {
          companyIndex = companyIndex + 1;
          companyNumber = this.organizations[index].companyNumber;
          this.orgTree[companyIndex] = new OrganizationNode();
          //console.log("IOT1 " + index + " cn " + companyNumber + " fn " + factoryNumber + " dn " + departmentNumber + " sn " + sectionNumber);
          if (this.companyNumber == companyNumber && this.factoryNumber == factoryNumber && this.departmentNumber == departmentNumber && this.sectionNumber == sectionNumber) {
            this.selectedNode = this.orgTree[companyIndex];
            this.orgTree[companyIndex].selected = true;
            //console.log("IOT1 found!")
          }
          this.orgTree[companyIndex].name = companyNumber + ' ' + this.organizations[index].description;
          this.orgTree[companyIndex].id = this.organizations[index].id;
          this.orgTree[companyIndex].companyNumber = companyNumber;
          this.orgTree[companyIndex].factoryNumber = factoryNumber;
          this.orgTree[companyIndex].departmentNumber = departmentNumber;
          this.orgTree[companyIndex].sectionNumber = sectionNumber;
          this.orgTree[companyIndex].description = this.organizations[index].description;
          this.orgTree[companyIndex].type = this.organizations[index].type;
          this.orgTree[companyIndex].children = [];
          this.orgTree[companyIndex].children[0] = new OrganizationNode();
          this.orgTree[companyIndex].children.pop();
          factoryIndex = -1;
          factoryNumber = -1;
        }
      }
      else if (this.organizations[index].type == 2) {
        currentType = 2;
        if (this.organizations[index].factoryNumber != factoryNumber) {
          factoryIndex = factoryIndex + 1;
          factoryNumber = this.organizations[index].factoryNumber;
          this.orgTree[companyIndex].children.push(new OrganizationNode());
          this.orgTree[companyIndex].children[factoryIndex].name = factoryNumber + ' ' + this.organizations[index].description;
          this.orgTree[companyIndex].children[factoryIndex].id = this.organizations[index].id;
          this.orgTree[companyIndex].children[factoryIndex].companyNumber = companyNumber;
          this.orgTree[companyIndex].children[factoryIndex].factoryNumber = factoryNumber;
          this.orgTree[companyIndex].children[factoryIndex].departmentNumber = departmentNumber;
          this.orgTree[companyIndex].children[factoryIndex].sectionNumber = sectionNumber;
          this.orgTree[companyIndex].children[factoryIndex].description = this.organizations[index].description;
          this.orgTree[companyIndex].children[factoryIndex].type = this.organizations[index].type;
          //console.log("IOT2 " + index + " cn " + companyNumber + " fn " + factoryNumber + " dn " + departmentNumber + " sn " + sectionNumber);
          if (this.companyNumber == companyNumber && this.factoryNumber == factoryNumber && this.departmentNumber == departmentNumber && this.sectionNumber == sectionNumber) {
            //console.log("IOT2 found!");
            this.selectedNode = this.orgTree[companyIndex].children[factoryIndex];
            this.orgTree[companyIndex].children[factoryIndex].selected = true;
          }
          this.orgTree[companyIndex].children[factoryIndex].children = [];
          this.orgTree[companyIndex].children[factoryIndex].children[0] = new OrganizationNode();
          this.orgTree[companyIndex].children[factoryIndex].children.pop();
          departmentIndex = -1;
          departmentNumber = -1;
        }
      }
      else if (this.organizations[index].type == 3) {
        if (currentType > 3) {
          sectionNumber = -1;
        }
        currentType = 3;
        if (this.organizations[index].departmentNumber != departmentNumber) {
          departmentIndex = departmentIndex + 1;
          departmentNumber = this.organizations[index].departmentNumber;
          this.orgTree[companyIndex].children[factoryIndex].children.push(new OrganizationNode());
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].name = departmentNumber + ' ' + this.organizations[index].description;
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].id = this.organizations[index].id;
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].companyNumber = companyNumber;
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].factoryNumber = factoryNumber;
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].departmentNumber = departmentNumber;
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].sectionNumber = sectionNumber;
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].description = this.organizations[index].description;
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].type = this.organizations[index].type;
          //console.log("IOT3 " + index + " cn " + companyNumber + " fn " + factoryNumber + " dn " + departmentNumber + " sn " + sectionNumber);
          if (this.companyNumber == companyNumber && this.factoryNumber == factoryNumber && this.departmentNumber == departmentNumber && this.sectionNumber == sectionNumber) {
            this.selectedNode = this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex];
            this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].selected = true;
            //console.log("IOT3 found!")
          }
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children = [];
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children[0] = new OrganizationNode();
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children.pop();
          sectionIndex = -1;
          sectionNumber = -1;
        }
      }
      else if (this.organizations[index].type == 4) {
        currentType = 4;
        if (this.organizations[index].sectionNumber != sectionNumber) {
          sectionIndex = sectionIndex + 1;
          sectionNumber = this.organizations[index].sectionNumber;
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children.push(new OrganizationNode());
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children[sectionIndex].name = sectionNumber + ' ' + this.organizations[index].description;
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children[sectionIndex].id = this.organizations[index].id;
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children[sectionIndex].companyNumber = companyNumber;
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children[sectionIndex].factoryNumber = factoryNumber;
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children[sectionIndex].departmentNumber = departmentNumber;
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children[sectionIndex].sectionNumber = sectionNumber;
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children[sectionIndex].description = this.organizations[index].description;
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children[sectionIndex].type = this.organizations[index].type;;
          //console.log("IOT4 " + index + " cn " + companyNumber + " fn " + factoryNumber + " dn " + departmentNumber + " sn " + sectionNumber);
          if (this.companyNumber == companyNumber && this.factoryNumber == factoryNumber && this.departmentNumber == departmentNumber && this.sectionNumber == sectionNumber) {
            this.selectedNode = this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children[sectionIndex];
            this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children[sectionIndex].selected = true;
            //console.log("IOT4 found!")
          }

        }
      }
    }
  }

}
