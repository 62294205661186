import { NgModule, Injectable } from '@angular/core';
import { Routes, RouterModule, DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';
import { HomeComponent } from "./components/home/home.component";
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { TranslateModule } from '@ngx-translate/core';
import { Utilities } from './services/utilities';

@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    const possibleSeparators = /[?;#]/;
    const indexOfSeparator = url.search(possibleSeparators);
    let processedUrl: string;

    if (indexOfSeparator > -1) {
      const separator = url.charAt(indexOfSeparator);
      const urlParts = Utilities.splitInTwo(url, separator);
      urlParts.firstPart = urlParts.firstPart.toLowerCase();

      processedUrl = urlParts.firstPart + separator + urlParts.secondPart;
    } else {
      processedUrl = url.toLowerCase();
    }

    return super.parse(processedUrl);
  }
}


const routes: Routes = [
  { path: "", component: HomeComponent, canActivate: [AuthGuard], data: { title: "Home" } },
  { path: "login", loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule) },
  { path: "about", loadChildren: () => import('./components/about/about.module').then(m => m.AboutModule) },
  { path: 'stampreport', loadChildren: () => import('./components/stampreport/stampreport.module').then(m => m.StampReportModule) },
  { path: 'overtimereport', loadChildren: () => import('./components/overtimereport/overtimereport.module').then(m => m.OvertimeReportModule) },
  { path: 'clockingreport', loadChildren: () => import('./components/clockingreport/clockingreport.module').then(m => m.ClockingReportModule) },
  { path: 'absencecodereport', loadChildren: () => import('./components/absencecodereport/absencecodereport.module').then(m => m.AbsenceCodeReportModule) },
  { path: 'absencecodesumreport', loadChildren: () => import('./components/absencecodesumreport/absencecodesumreport.module').then(m => m.AbsenceCodeSumReportModule) },
  { path: 'vacationreport', loadChildren: () => import('./components/vacationreport/vacationreport.module').then(m => m.VacationReportModule) },
  { path: 'statereport', loadChildren: () => import('./components/statereport/statereport.module').then(m => m.StateReportModule) },
  { path: 'saldoreport', loadChildren: () => import('./components/saldoreport/saldoreport.module').then(m => m.SaldoReportModule) },
  { path: 'sumreport', loadChildren: () => import('./components/sumreport/sumreport.module').then(m => m.SumReportModule) },
  { path: 'worknumberreport', loadChildren: () => import('./components/worknumberreport/worknumberreport.module').then(m => m.WorkNumberReportModule) },
  { path: 'maxworkinghours', loadChildren: () => import('./components/maxworkinghours/maxworkinghours.module').then(m => m.MaxWorkingHoursModule) },
  { path: 'stampcorrection', loadChildren: () => import('./components/stampcorrection/stampcorrection.module').then(m => m.StampCorrectionModule) },
  { path: 'saldocorrection', loadChildren: () => import('./components/saldocorrection/saldocorrection.module').then(m => m.SaldoCorrectionModule) },
  { path: 'unaccepteddays', loadChildren: () => import('./components/unaccepteddays/unaccepteddays.module').then(m => m.UnAcceptedDaysModule) },
  { path: 'infostate', loadChildren: () => import('./components/infostate/infostate.module').then(m => m.InfoStateModule) },
  { path: 'substitute', loadChildren: () => import('./components/substitute/substitute.module').then(m => m.SubstituteModule) },
  { path: "home", redirectTo: "/", pathMatch: "full" },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    TranslateModule
  ],
  exports: [
    RouterModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    { provide: UrlSerializer, useClass: LowerCaseUrlSerializer }]
 })
export class AppRoutingModule { }
