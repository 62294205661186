import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStoreManager } from './local-store-manager.service';
import { SaldoType } from '../models/saldotype.model';

@Injectable()
export class SaldoTypeService {
  private saldonumberUrl: string = 'api/saldonumbers';

  constructor(
    private http: HttpClient,
    private localStorage: LocalStoreManager) { }

  getsaldotypes(restr: number): Observable<SaldoType[]> {
    let language = this.localStorage.getDataObject("language");
    let sitename = this.localStorage.getDataObject("sitename");
    let endpointUrl = `${this.saldonumberUrl}/${sitename}:${language}:${restr}`;
    return this.http.get<SaldoType[]>(endpointUrl);
  }

}

