  <div class="dialog-title-actions-row">
    <h2 mat-dialog-title>{{'common.Organization' | translate}}</h2>
    <div class="action-buttons">
      <button mat-raised-button [mat-dialog-close]="data" color="primary">{{'helps.Update' | translate}}</button>
      <button mat-raised-button (click)="onNoClick()" color="warn">{{'helps.Cancel' | translate}}</button>
    </div>
  </div>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" [ngClass]="{'selected': selectedNode === node}" (click)="selectNode(node)">
      <li>{{node.name}}</li>
    </mat-tree-node>
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <li>
        <button mat-icon-button [attr.aria-label]="'Toggle ' + node.name" matTreeNodeToggle>
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <span [ngClass]="{ 'selected-node': selectedNode === node }"
              (click)="onNodeSelected(node)">
          {{ node.name }}
        </span>
        <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </li>
    </mat-nested-tree-node>
  </mat-tree>


