import { Component, Inject, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AuthService } from '../../services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppTranslationService } from "../../services/app-translation.service";
import { IMyDpOptions, IMyDateModel, IMyDate } from 'mydatepicker';
import { PersonNameService } from "../../services/personname.service";
import { ReportParam } from '../../models/reportparam.model';
import { Person } from '../../models/person.model';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { AlertService, DialogType } from '../../services/alert.service';
import { DataService } from '../../services/data.service';
import { EnterProjectService } from '../../services/enterproject.service';
import { ProjectLog } from '../../models/projectlog.model';
import { WeekDayReport } from '../../models/weekdayreport.model';
import { WeekDayProject } from '../../models/weekdayproject.model';
import { ProjectToolTipDialog } from '../project-tooltip/project-tooltip.component';


const defaultDialogConfig = new MatDialogConfig();

@Component({
  selector: 'project-enter.component.ts',
  templateUrl: './project-enter.component.html',
  styleUrls: ['./project-enter.component.scss',]
})
export class ProjectEnterComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    public dataservice: DataService,
    private alertService: AlertService,
    private authService: AuthService,
    private translationService: AppTranslationService,
    private personNameService: PersonNameService,
    private localStorage: LocalStoreManager,
    private enterProjectService: EnterProjectService) {
  }

  row = [];
  displayedColumns1: string[] = ['title', 'monData', 'tueData', 'wedData', 'thuData', 'friData', 'satData', 'sunData'];
  displayedColumns2: string[] = ['name', 'monData', 'tueData', 'wedData', 'thuData', 'friData', 'satData', 'sunData'];

  public weekdatas: MatTableDataSource<WeekDayReport>;
  public projectdatas: MatTableDataSource<WeekDayProject>;
  weekEsmiData: WeekDayReport[] = [];
  weekProjectData: WeekDayProject[] = [];
  currentDate: Date = new Date();
  dateNow: IMyDate = this.getToday();
  dateToday: Date;
  persons: Person[] = [];
  proj: ProjectLog = new ProjectLog();
  params = new ReportParam()
  guser: boolean;
  allowdate: boolean;
  personname: string;
  //employeeId: number;
  selectedPerson: Person;
  selectNameStr: string;
  newComment: string = "";
  personIndex: number;
  dialogRef: MatDialogRef<ProjectToolTipDialog> | null;
  lastAfterClosedResult: string;
  lastBeforeCloseResult: string;
  actionsAlignment: string;
  currentRow: number;
  currentCol: number;
  currentIndex: number;
  clickNumber: number;
  rights: number = 0;
  writeRights: number = 0;
  writeRight: boolean = false;

  @ViewChild(TemplateRef) template: TemplateRef<any>;

  ngOnInit() {
    this.personIndex = 0;
    this.rights = this.localStorage.getDataObject("readrights");
    this.writeRights = this.localStorage.getDataObject("userrights");
    this.writeRight = (this.writeRights & 8192) == 8192;
    this.selectNameStr = this.translationService.getTranslation('stampreport.SelectName');
    this.locale = this.localStorage.getDataObject("language");
    this.guser = this.localStorage.getDataObject("guser");
    if (this.dataservice.StampDate) {
      this.personIndex = this.dataservice.PersonIndex;
    }
    if (this.guser) {
      this.personname = this.localStorage.getDataObject("personname");
      this.getPersonNames(this.localStorage.getDataObject("employee_id"));
      this.params.employee_id = this.localStorage.getDataObject("employee_id");
      this.params.lang = this.localStorage.getDataObject("language");
      //this.fetchstamps(true);
    }
    else {
      //this.getPersonNames(0);
      this.personNameService.getpersonnames("")
        .subscribe(results => {
          this.persons = results;
          if (this.dataservice.PersonData) {
            this.selectedPerson = this.dataservice.PersonData;
          }
          else if (this.persons.length > 0)
            this.selectedPerson = this.persons[this.personIndex];
          else
            return;
          //this.fetchstamps(true);

        },
          (error: any) => {
            console.log(error);
          });

    }
  }

  onDateChanged(event: IMyDateModel): void {
    let newDate: Date = new Date(event.jsdate.getTime());
    this.currentDate = newDate;
    this.allowdate = this.dateToday >= newDate;
  }

  getToday(): IMyDate {
    let date: Date = new Date();
    date.setDate(date.getDate() - date.getDay() + 1);
    this.dateToday = date;
    this.currentDate = date;
    this.allowdate = false;
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
  }

  getNextDay(): IMyDate {
    this.currentDate.setDate(this.currentDate.getDate() + 7);
    let date: Date = new Date();
    date.setDate(date.getDate() - date.getDay());
    this.dateToday = date;
    this.allowdate = this.dateToday > this.currentDate;
    return { year: this.currentDate.getFullYear(), month: this.currentDate.getMonth() + 1, day: this.currentDate.getDate() };
  }

  getPreviousDay(): IMyDate {
    this.currentDate.setDate(this.currentDate.getDate() - 7);
    let date: Date = new Date();
    date.setDate(date.getDate() - date.getDay());
    this.dateToday = date;
    this.allowdate = this.dateToday > this.currentDate;
    return { year: this.currentDate.getFullYear(), month: this.currentDate.getMonth() + 1, day: this.currentDate.getDate() };
  }

  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'dd.mm.yyyy',
    disableSince: this.dateNow,
    disableWeekdays: ['tu', 'we', 'th', 'fr', 'sa', 'su'],
    width: "100%",
    showClearDateBtn: false,
    alignSelectorRight: false,
    showDecreaseDateBtn: false,
    showIncreaseDateBtn: false
  };

  public locale: string;
  public startDate: any = { date: { year: this.dateNow.year, month: this.dateNow.month, day: this.dateNow.day } };
  public repStart: Date;
  public repEnd: Date;


  getPersonNames(personid: string) {
    this.personNameService.getpersonnames(personid)
      .subscribe(results => {
        this.persons = results;
        this.selectedPerson = this.persons[this.personIndex];
      },
        (error: any) => {
          console.log(error);
        });
  }

  ngAfterViewInit() {
  }

  private enterProjectParameters() {
    this.params.lang = this.localStorage.getDataObject("language");
    if (this.startDate != null) {
      this.params.startDate = this.startDate.formatted;
      if (!this.params.startDate) {
        var d = this.startDate.date;
        this.params.startDate = ("0" + d.day).slice(-2) + "." + ("0" + d.month).slice(-2) + "." + d.year;
      }
    }
  }

  changePerson($event) {
    if (this.selectedPerson != null) {
      this.personIndex = this.persons.indexOf(this.selectedPerson);
      this.fetchstamps();
    }
  }


  previousname() {
    if (this.personIndex > 0) {
      this.personIndex--;
      this.selectedPerson = this.persons[this.personIndex];
      this.fetchstamps();
    }
  }

  nextname() {
    if (this.personIndex < this.persons.length - 1) {
      this.personIndex++;
      this.selectedPerson = this.persons[this.personIndex];
      this.fetchstamps();
    }
  }

  previousday() {
    this.startDate = { date: this.getPreviousDay() };
    this.fetchstamps();
  }

  nextday() {
    this.startDate = { date: this.getNextDay() };
    this.fetchstamps();
  }
  confirmError(ret: boolean) {
    let a = ret;

  }

  checkMaxValues(sum1str: string, sum2str: string, sum3str: string, data: string): boolean {
    let sum1Time = 0;
    let ret = false;
    if (sum1str != '') {
      var sum1 = sum1str.split(":");
      sum1Time = (parseInt(sum1[0], 10) * 60) + parseInt(sum1[1], 10) + 18;
    }
    sum2str = sum2str.replace(',', '.');
    sum3str = sum3str.replace(',', '.');
    data = data.replace(',', '.');
    var sum2Time = parseFloat(sum2str.substring(1)) * 60;
    var sum3Time = parseFloat(sum3str) * 60;
    var dataTime = parseFloat(data) * 60;
    if (dataTime == 0)
      return (true);
    var newTime = dataTime - sum3Time;
    sum2Time += newTime;
    if (sum2Time > sum1Time) {
      this.alertService.showDialog("Projektitunnit " + (Math.floor(sum2Time / 60)).toString() + ":" + ("00" + (sum2Time % 60).toString()).slice(-2) + " ylittävät työajan " + sum1str);
    }
    else
      ret = true;
    return (ret);

  }

  fetchstamps() {
    if (!this.guser) {
      this.params.employee_id = this.selectedPerson.employeeId;
      this.params.lang = this.selectedPerson.language;
      this.personname = this.selectedPerson.name;
    }
    if (this.personname.length > 0) {
      this.enterProjectParameters();
      this.enterProjectService.getWeekData(this.params)
        .subscribe(results => {
          this.weekdatas = new MatTableDataSource<WeekDayReport>(results);
          this.weekEsmiData = results;
          this.enterProjectService.getWeekProject(this.params)
            .subscribe(results2 => {
              this.projectdatas = new MatTableDataSource<WeekDayProject>(results2);
              this.weekProjectData = results2;
            },
              (error: any) => {
              });
        },
          (error: any) => {
          });
    }
  }

  deleteProject(proj: ProjectLog) {
    this.enterProjectService.deleteProject(proj)
      .subscribe(results => {
        this.projectdatas = new MatTableDataSource<WeekDayProject>(results);
        this.weekProjectData = results;
      },
        (error: any) => {
        });
  }

  newProject(proj: ProjectLog) {
    this.enterProjectService.addNewProject(proj)
      .subscribe(results => {
        this.projectdatas = new MatTableDataSource<WeekDayProject>(results);
        this.weekProjectData = results;
      },
        (error: any) => {
        });
  }

  updateProject(proj: ProjectLog) {
    this.enterProjectService.updateProject(proj)
      .subscribe(results => {
        this.projectdatas = new MatTableDataSource<WeekDayProject>(results);
        this.weekProjectData = results;
      },
        (error: any) => {
        });
  }

   checkNumber(data, str, index) {
    let errMess = null;
    let newstr = data.replace(',', '.');
    let notNumber = isNaN(newstr);
    if (notNumber) {
      errMess = this.translationService.getTranslation('notifications.WrongTimeSetting');
      errMess += " Pvm " + str + " rivi " + index;
      this.alertService.showDialog(errMess);
      return false;
    }
    // check if data ok
    let testnum = Number(newstr);
    if (testnum > 99) {
      errMess = this.translationService.getTranslation('notifications.TooHighTime');
      errMess += " Pvm " + str + " rivi " + index;
      this.alertService.showDialog(errMess);
      return false;
    }
    return true;
  }

  setMonData(data, index) {
    if (!this.checkNumber(data, this.weekProjectData[0].monData, index))
      return;
    this.getMonProj(index);
    let oldData = this.proj.sumTime;
    this.proj.sumTime = data;
    if (data == oldData) return;
    let sum1str = this.weekEsmiData[this.weekEsmiData.length - 1].monData;
    let sum2str = this.weekProjectData[this.weekProjectData.length - 1].monData;
    let sum3str = this.weekProjectData[index].monData;
    if (data != '0' )
      if (!this.checkMaxValues(sum1str, sum2str, sum3str, data))
        return;
    if (data == '' || data == '0')
      this.deleteProject(this.proj);
    else if (oldData == '0' && data != '0')
      this.newProject(this.proj);
    else
      this.updateProject(this.proj);
  }

  getMonProj(index) {
    this.proj.comment = this.weekProjectData[index].monComment;
    this.proj.date = this.weekProjectData[0].monData;
    this.proj.projectName = this.weekProjectData[index].name;
    this.proj.employeeId = (this.guser) ? this.params.employee_id : this.selectedPerson.employeeId;
    this.proj.sumTime = this.weekProjectData[index].monData;
  }


  setTueData(data, index) {
    if (!this.checkNumber(data, this.weekProjectData[0].tueData, index))
      return;
    this.getTueProj(index);
    let oldData = this.proj.sumTime;
    this.proj.sumTime = data;
    if (data == oldData) return;
    let sum1str = this.weekEsmiData[this.weekEsmiData.length - 1].tueData;
    let sum2str = this.weekProjectData[this.weekProjectData.length - 1].tueData;
    let sum3str = this.weekProjectData[index].tueData;
    if (data != '0')
      if (!this.checkMaxValues(sum1str, sum2str, sum3str, data))
        return;
    if (data == '' || data == '0')
      this.deleteProject(this.proj);
    else if (oldData == '0' && data != '0')
      this.newProject(this.proj);
    else
      this.updateProject(this.proj);
  }

  getTueProj(index) {
    this.proj.comment = this.weekProjectData[index].tueComment;
    this.proj.date = this.weekProjectData[0].tueData;
    this.proj.projectName = this.weekProjectData[index].name;
    this.proj.employeeId = (this.guser) ? this.params.employee_id : this.selectedPerson.employeeId;
    this.proj.sumTime = this.weekProjectData[index].tueData;
  }

  setWedData(data, index) {
    if (!this.checkNumber(data, this.weekProjectData[0].wedData, index))
      return;
    this.getWedProj(index);
    let oldData = this.proj.sumTime;
    this.proj.sumTime = data;
    if (data == oldData) return;
    let sum1str = this.weekEsmiData[this.weekEsmiData.length - 1].wedData;
    let sum2str = this.weekProjectData[this.weekProjectData.length - 1].wedData;
    let sum3str = this.weekProjectData[index].wedData;
    if (data != '0')
      if (!this.checkMaxValues(sum1str, sum2str, sum3str, data))
        return;
    if (data == '' || data == '0')
      this.deleteProject(this.proj);
    else if (oldData == '0' && data != '0')
      this.newProject(this.proj);
    else
      this.updateProject(this.proj);
  }

  getWedProj(index) {
    this.proj.comment = this.weekProjectData[index].wedComment;
    this.proj.date = this.weekProjectData[0].wedData;
    this.proj.projectName = this.weekProjectData[index].name;
    this.proj.employeeId = (this.guser) ? this.params.employee_id : this.selectedPerson.employeeId;
    this.proj.sumTime = this.weekProjectData[index].wedData;
  }

  setThuData(data, index) {
    if (!this.checkNumber(data, this.weekProjectData[0].thuData, index))
      return;
    this.getThuProj(index);
    let oldData = this.proj.sumTime;
    this.proj.sumTime = data;
    if (data == oldData) return;
    let sum1str = this.weekEsmiData[this.weekEsmiData.length - 1].thuData;
    let sum2str = this.weekProjectData[this.weekProjectData.length - 1].thuData;
    let sum3str = this.weekProjectData[index].thuData;
    if (data != '0')
      if (!this.checkMaxValues(sum1str, sum2str, sum3str, data))
        return;
    if (data == '' || data == '0')
      this.deleteProject(this.proj);
    else if (oldData == '0' && data != '0')
      this.newProject(this.proj);
    else
      this.updateProject(this.proj);
  }

  getThuProj(index) {
    this.proj.comment = this.weekProjectData[index].thuComment;
    this.proj.date = this.weekProjectData[0].thuData;
    this.proj.projectName = this.weekProjectData[index].name;
    this.proj.employeeId = (this.guser) ? this.params.employee_id : this.selectedPerson.employeeId;
    this.proj.sumTime = this.weekProjectData[index].thuData;
  }

  setFriData(data, index) {
    if (!this.checkNumber(data, this.weekProjectData[0].friData, index))
      return;
    this.getFriProj(index);
    let oldData = this.proj.sumTime;
    this.proj.sumTime = data;
    if (data == oldData) return;
    let sum1str = this.weekEsmiData[this.weekEsmiData.length - 1].friData;
    let sum2str = this.weekProjectData[this.weekProjectData.length - 1].friData;
    let sum3str = this.weekProjectData[index].friData;
    if (data != '0')
      if (!this.checkMaxValues(sum1str, sum2str, sum3str, data))
        return;
    if (data == '' || data == '0')
      this.deleteProject(this.proj);
    else if (oldData == '0' && data != '0')
      this.newProject(this.proj);
    else
      this.updateProject(this.proj);
  }

  getFriProj(index) {
    this.proj.comment = this.weekProjectData[index].friComment;
    this.proj.date = this.weekProjectData[0].friData;
    this.proj.projectName = this.weekProjectData[index].name;
    this.proj.employeeId = (this.guser) ? this.params.employee_id : this.selectedPerson.employeeId;
    this.proj.sumTime = this.weekProjectData[index].friData;
  }

  setSatData(data, index) {
    if (!this.checkNumber(data, this.weekProjectData[0].satData, index))
      return;
    this.getSatProj(index);
    let oldData = this.proj.sumTime;
    this.proj.sumTime = data;
    if (data == oldData) return;
    let sum1str = this.weekEsmiData[this.weekEsmiData.length - 1].satData;
    let sum2str = this.weekProjectData[this.weekProjectData.length - 1].satData;
    let sum3str = this.weekProjectData[index].satData;
    if (data != '0')
      if (!this.checkMaxValues(sum1str, sum2str, sum3str, data))
        return;
    if (data == '' || data == '0')
      this.deleteProject(this.proj);
    else if (oldData == '0' && data != '0')
      this.newProject(this.proj);
    else
      this.updateProject(this.proj);
  }

  getSatProj(index) {
    this.proj.comment = this.weekProjectData[index].satComment;
    this.proj.date = this.weekProjectData[0].satData;
    this.proj.projectName = this.weekProjectData[index].name;
    this.proj.employeeId = (this.guser) ? this.params.employee_id : this.selectedPerson.employeeId;
    this.proj.sumTime = this.weekProjectData[index].satData;
  }

  setSunData(data, index) {
    if (!this.checkNumber(data, this.weekProjectData[0].sunData, index))
      return;
    this.getSunProj(index);
    let oldData = this.proj.sumTime;
    this.proj.sumTime = data;
    if (data == oldData) return;
    let sum1str = this.weekEsmiData[this.weekEsmiData.length - 1].sunData;
    let sum2str = this.weekProjectData[this.weekProjectData.length - 1].sunData;
    let sum3str = this.weekProjectData[index].sunData;
    if (data != '0')
      if (!this.checkMaxValues(sum1str, sum2str, sum3str, data))
        return;
    if (data == '' || data == '0')
      this.deleteProject(this.proj);
    else if (oldData == '0' && data != '0')
      this.newProject(this.proj);
    else
      this.updateProject(this.proj);
  }

  getSunProj(index) {
    this.proj.comment = this.weekProjectData[index].sunComment;
    this.proj.date = this.weekProjectData[0].sunData;
    this.proj.projectName = this.weekProjectData[index].name;
    this.proj.employeeId = (this.guser) ? this.params.employee_id : this.selectedPerson.employeeId;
    this.proj.sumTime = this.weekProjectData[index].sunData;
  }

  editToolTip(rowno, colno) {
    let comment = null;
    switch (colno) {
      case 1:
        if (this.weekProjectData[rowno].monData == "0") return;
        comment = this.weekProjectData[rowno].monComment;
        break;
      case 2:
        if (this.weekProjectData[rowno].tueData == "0") return;
        comment = this.weekProjectData[rowno].tueComment;
        break;
      case 3:
        if (this.weekProjectData[rowno].wedData == "0") return;
        comment = this.weekProjectData[rowno].wedComment;
        break;
      case 4:
        if (this.weekProjectData[rowno].thuData == "0") return;
        comment = this.weekProjectData[rowno].thuComment;
        break;
      case 5:
        if (this.weekProjectData[rowno].friData == "0") return;
        comment = this.weekProjectData[rowno].friComment;
        break;
      case 6:
        if (this.weekProjectData[rowno].satData == "0") return;
        comment = this.weekProjectData[rowno].satComment;
        break;
      case 7:
        if (this.weekProjectData[rowno].sunData == "0") return;
        comment = this.weekProjectData[rowno].sunComment;
        break;
    }
    this.dialogRef = this.dialog.open(ProjectToolTipDialog, {
      width: '600px',
      data: {
        myComment: comment
      }
    });
    this.dialogRef.beforeClosed().subscribe((result: string) => {
      if (result != null) {
        this.lastBeforeCloseResult = result;
        switch (colno) {
          case 1:
            //this.weekProjectData[rowno].monComment = this.lastBeforeCloseResult;
            this.getMonProj(rowno);
            break;
          case 2:
            this.getTueProj(rowno);
            break;
          case 3:
            this.getWedProj(rowno);
            break;
          case 4:
            this.getThuProj(rowno);
            break;
          case 5:
            this.getFriProj(rowno);
            break;
          case 6:
            this.getSatProj(rowno);
            break;
          case 7:
            this.getSunProj(rowno);
            break;
        }
        this.proj.comment = this.lastBeforeCloseResult;
        this.updateProject(this.proj);
      }
    });
    this.dialogRef.afterClosed().subscribe((result: string) => {
      this.lastAfterClosedResult = result;
      this.dialogRef = null;
    });
  }

  setRow(index, rn) {
    this.currentRow = index;
    this.currentCol = rn;
  }

  getRecord(row) {
    let rowno = this.currentRow;
    let colno = this.currentCol;
    let newIndex = rowno * 10 + colno;
    if (this.currentIndex != newIndex) {
      this.currentIndex = newIndex;
      this.clickNumber = 0;
    }
    else {
      if (this.clickNumber > 0) {
        this.editToolTip(rowno, colno);
        this.clickNumber = 0;
      }
      else
        this.clickNumber++;
    }
  }


  ngOnDestroy() {
    this.dataservice.PersonData = this.selectedPerson;
    this.dataservice.PersonIndex = this.personIndex;
  }
}
