
import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { CostPlace } from '../../models/costplace.model';
import { Person } from '../../models/person.model';
import { Project } from '../../models/project.model';
import { AlertService } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { CostPlaceService } from '../../services/costplace.service';
import { PersonNameService } from "../../services/personname.service";
import { ProjectService } from '../../services/project.service';

@Component({
  selector: 'app-project-editor',
  templateUrl: './project-editor.component.html',
  styleUrls: ['./project-editor.component.scss']
})
export class ProjectEditorComponent implements OnChanges {
  @ViewChild('form', { static: true })
  private form: NgForm;

  public isNewProject = false;
  private onProjectSaved = new Subject<Project>();
  persons: Person[] = [];
  costplaces: CostPlace[] = [];
  employee_id: string ;

  @Input() project: Project = new Project();
  @Input() isEditMode = true;

  projectForm: FormGroup;
  projectSaved$ = this.onProjectSaved.asObservable();

  get number() {
    return this.projectForm.get('number');
  }

  get name() {
    return this.projectForm.get('name');
  }

  get description() {
    return this.projectForm.get('description');
  }

  get startDate() {
    return this.projectForm.get('startDate');
  }

  get endDate() {
    return this.projectForm.get('endDate');
  }

  get employeeId() {
    return this.projectForm.get('employeeId');
  }

  get costPlace() {
    return this.projectForm.get('costPlace');
  }

  get floatLabels(): string {
    return this.isEditMode ? 'auto' : 'always';
  }

  constructor(
    private alertService: AlertService,
    private projectService: ProjectService,
    private translationService: AppTranslationService,
    public costPlaceService: CostPlaceService,
    public personNameService: PersonNameService,
    private formBuilder: FormBuilder
  ) {
    this.buildForm();
  }

  ngOnInit() {
    this.getPerson();
    this.GetCostplace();
  }

  ngOnChanges() {
    if (this.project) {
      this.isNewProject = false;
    } else {
      this.isNewProject = true;
      this.project = new Project();
    }

    this.resetForm();
  }

  public save() {
    if (!this.form.submitted) {
      this.form.onSubmit(null);
      return;
    }

    if (!this.projectForm.valid) {
/*      this.alertService.showValidationError();*/
      return;
    }

    var editedProject = this.getEditedProject();
    editedProject.number = Number(editedProject.number);

    if (this.isNewProject) {
      this.projectService.newProject(editedProject).subscribe(
        project => this.saveSuccessHelper(project),
        error => this.saveFailedHelper(error));

    } else {
      this.projectService.updateProject(editedProject).subscribe(
        response => this.saveSuccessHelper(editedProject),
        error => this.saveFailedHelper(error));
    }
  }

  getPerson() {
    this.personNameService.getpersonnames("")
      .subscribe(results => {
        this.persons = results;
      },
        (error: any) => {
          console.log(error);
        });
  }

  GetCostplace() {
    this.costPlaceService.getcostplaces()
      .subscribe(results => {
        this.costplaces = results;
      },
        (error: any) => {
          console.log(error);
        });
  }

  private getEditedProject(): Project {
    const formModel = this.projectForm.value;

    return {
      id: this.project.id,
      number: formModel.number,
      name: formModel.name,
      description: formModel.description,
      startDate: formModel.startDate,
      endDate: formModel.endDate,
      employeeId: formModel.employeeId,
      personName: formModel.personName,
      costPlace: formModel.costPlace
    };
  }

  private saveSuccessHelper(project?: Project) {

    let mess: string;
    if (this.isNewProject) {
      mess = this.translationService.getTranslation('notifications.InsertRecordSucceeded');
    } else {
      mess = this.translationService.getTranslation('notifications.UpdateRecordSucceeded');
    }
    this.alertService.successMessage(mess);
    this.onProjectSaved.next(project);
  }

  private saveFailedHelper(error: any) {
    //this.alertService.stopLoadingMessage();
    //this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
    //this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  private cancel() {
    this.resetForm();

    //this.alertService.resetStickyMessage();
  }

  private buildForm() {
    this.projectForm = this.formBuilder.group({
      id:'',
      number: ['', Validators.required],
      name: ['', Validators.required],
      description: ['', Validators.required],
      startDate: null,
      endDate: null,
      employeeId: null,
      costPlace: null
    });
  }

  private resetForm(stopEditing = false) {
    if (stopEditing) {
      this.isEditMode = false;
    }

    if (!this.project) {
      this.isNewProject = true;
      this.project = new Project();
    }

    this.projectForm.reset({
      id: this.project.id || '',
      number: this.project.number || '',
      name: this.project.name || '',
      description: this.project.description || '',
      startDate: this.project.startDate || null,
      endDate: this.project.endDate || null,
      employeeId: this.project.employeeId || null,
      costPlace: this.project.costPlace || null
    });
  }

  get canManageProjects() {
    return true; //this.accountService.userHasPermission(Permission.manageRolesPermission);
  }
}
