import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStoreManager } from './local-store-manager.service';
import { Device } from '../models/device.model';

@Injectable()
export class DeviceService {
  private devicesUrl: string = 'api/devices';
  private devicesNameUrl: string = 'api/devicenames';

  constructor(
    private http: HttpClient,
    private localStorage: LocalStoreManager) { }

  getDevices(name: string): Observable<Device[]> {
    let sitename = this.localStorage.getDataObject("sitename");
    let endpointUrl = `${this.devicesUrl}/${sitename}:${name}`;
    return this.http.get<Device[]>(endpointUrl);
  }

  updateDevice(device): Observable<Device> {
    return this.http.put<Device>(this.devicesUrl, device);
  }

  newDevice(device): Observable<Device> {
    return this.http.post<Device>(this.devicesUrl, device);
  }

  deleteDevice(id: number) {
    let endpointUrl = `${this.devicesUrl}/${id}`;
    return this.http.delete<Device>(endpointUrl);
  }


}

