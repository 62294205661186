import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { AlertService, DialogType } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { EmployeeService } from '../../services/employee.service';
import { Employee } from '../../models/employee.model';
import { PersonsDialogComponent } from './persons-dialog.component';
import { ApiResult } from '../../base.service';
import { ConfirmComponent } from '../../components/confirm/confirm.component';
import { FormControl, FormGroup } from '@angular/forms';
import { LocalStoreManager } from '../../services/local-store-manager.service';

@Component({
  selector: 'persons-list',
  templateUrl: './persons-list.component.html',
  styleUrls: ['./persons-list.component.scss']
})
export class PersonsListComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['surname', 'forename', 'employee_no', 'company', 'companyName', 'actions'];
  public employees: MatTableDataSource<Employee>;
  employee: Employee = new Employee();
  editingEmployeeName: { name: string };
  public show: boolean = true;
  defaultPageIndex: number = 0;
  currentPageIndex: number = 0;
  defaultPageSize: number = 10;
  public defaultSortColumn: string = "surname";
  public defaultSortOrder: string = "asc";
  defaultFilterColumn: string = "surname";
  firstQuestion: string;
  filterQuery: string = null;
  tableNumber: number = 0;
  personData: FormGroup;
  selectedRow: any;
  numberOfRows: number = 0;
  rights: number = 0;
  writeRights: number = 0;
  writeRight: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  filterTextChanged: Subject<string> = new Subject<string>();

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private translationService: AppTranslationService,
    private localStorage: LocalStoreManager,
    private employeeService: EmployeeService,
    private dialog: MatDialog
  ) {
    this.personData = new FormGroup({
      searchName: new FormControl()
      //selectlist: new FormControl()
    });
  }

  ngOnInit() {
    //this.loadData();
    this.rights = this.localStorage.getDataObject("readrights");
    this.writeRights = this.localStorage.getDataObject("userrights");
    this.writeRight = (this.writeRights & 128) == 128;
  }
  get searchName() {
    return this.personData.get('searchName');
  }

  checkboxChanged(event: any) {
    this.tableNumber = (event.checked) > 0 ? 1 : 0;
    this.loadData(this.filterQuery);
  }

  loadNameData() {
    this.filterQuery= this.searchName.value;
    this.loadData(this.filterQuery);
  }

  loadData(query: string = null) {
    var pageEvent = new PageEvent();

    pageEvent.pageIndex = this.currentPageIndex;
    pageEvent.pageSize = this.defaultPageSize;
    if (query) {
      this.filterQuery = query;
    }
    else
      this.filterQuery = null;
    this.getData(pageEvent);
  }

  getData(event: PageEvent) {
    //console.log("GetDAta " + this.defaultFilterColumn + " Query " + this.filterQuery + " Sort " + this.defaultSortColumn + " Order ", this.defaultSortOrder);
    var sortColumn = (this.sort)
      ? this.sort.active
      : this.defaultSortColumn;

    var sortOrder = (this.sort)
      ? this.sort.direction
      : this.defaultSortOrder;

    var filterColumn = (this.filterQuery)
      ? this.defaultFilterColumn
      : null;

    var filterQuery = (this.filterQuery)
      ? this.filterQuery
      : null;
    //console.log("LD2 " + this.defaultFilterColumn + " Query " + this.filterQuery + " Sort " + this.defaultSortColumn + " Order ", this.defaultSortOrder);
    this.employeeService.getData<ApiResult<Employee>>(
      this.tableNumber,
      event.pageIndex,
      event.pageSize,
      sortColumn,
      sortOrder,
      filterColumn,
      filterQuery)
      .subscribe(result => {
        this.paginator.length = result.totalCount;
        this.paginator.pageIndex = result.pageIndex;
        this.currentPageIndex = result.pageIndex;
        this.paginator.pageSize = result.pageSize;
        this.employees = new MatTableDataSource<Employee>(result.data);
        this.numberOfRows = this.paginator.length;
        if (this.numberOfRows == 0) {
          var mess = this.translationService.getTranslation('notifications.NoDataAvailable');
          this.alertService.errorMessage(mess);
        }
        this.authService.refreshLogin()
          .subscribe(data => {
            if (data) {
              this.authService.refresh_userdata(data);
            }
          },
            (error: any) => {
            });
      }, error => console.error(error));

  }

  ngAfterViewInit() {
    //this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
  }

  public createEmployee() {
    const dialogRef = this.dialog.open(PersonsDialogComponent,
      {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '90%',
        width: '90%',
        panelClass: 'mat-dialog-md',
        data: { employee: null, wr: this.writeRight }
      });
    dialogRef.afterClosed().subscribe(r => {
      this.filterQuery = r;
      this.searchName.setValue(r);
      this.loadData(this.filterQuery);
    });
  }

  public editEmployee(employee?: Employee) {
    const dialogRef = this.dialog.open(PersonsDialogComponent,
      {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '85%',
        width: '90%',
        panelClass: 'mat-dialog-md',
        data: { employee, wr: this.writeRight }
      });
    dialogRef.afterClosed().subscribe(r => {
      this.filterQuery = r;
      this.searchName.setValue(r);
      this.loadData(this.filterQuery);
    });
  }

  public confirmDelete(employee: Employee) {

    if (employee.hidden)
      this.firstQuestion = this.translationService.getTranslation('persons.ShowPerson');
    else
      this.firstQuestion = this.translationService.getTranslation('persons.HidePerson');
    const dialogRef = this.dialog.open(ConfirmComponent,
      {
        data: {
          title: this.translationService.getTranslation('persons.RemovePerson'),
          message: '',
          confirmCaption: this.translationService.getTranslation('common.Yes'),
          cancelCaption: this.translationService.getTranslation('common.Cancel'),
          firstQuestion: this.firstQuestion,
          secondQuestion: this.translationService.getTranslation('persons.DeletePerson'),
          thirdQuestion: this.translationService.getTranslation('persons.DeleteAllPerson'),
          hiddenType: employee.hidden?2:3
        },
        height: '300px',
        width: '500px',
        disableClose: true,
      });
    dialogRef.afterClosed().subscribe((result: number) => {
      if (result > 4)
        this.checkEmployee(employee);
      else if (result > 1) {
        let delQ = this.translationService.getTranslation('notifications.DeleteEmployeeRecord');
        this.alertService.showDialog(delQ + '?',
          DialogType.confirm, () => this.deleteEmployee(employee, result))
      }
    });
  }

  public confirmFullDelete(employee: Employee, abText: string) {
    if (abText.length > 0) {
      const dialogRef = this.dialog.open(ConfirmComponent,
        {
          data: {
            title: this.translationService.getTranslation('persons.PersonConstraint'),
            message: abText,
            confirmCaption: this.translationService.getTranslation('common.Yes'),
            cancelCaption: this.translationService.getTranslation('common.Cancel'),
          },
          width: '400px',
          disableClose: true,
        });
      dialogRef.afterClosed().subscribe((result: number) => {
        if (result == 1) {
          let delQ = this.translationService.getTranslation('notifications.DeleteRecord');
          this.alertService.showDialog(delQ + '?',
            DialogType.confirm, () => this.deleteEmployee(employee, 5))
        }
      });
    }
    else {
      let delQ = this.translationService.getTranslation('notifications.DeleteRecord');
      this.alertService.showDialog(delQ + '?',
        DialogType.confirm, () => this.deleteEmployee(employee, 5))
    }
  }


  deleteEmployee(employee, delType: number) {
    let id = employee.employeeId;
    this.employeeService.deleteEmployee(id, delType)
      .subscribe(results => {
        this.alertService.successMessage(this.translationService.getTranslation('notifications.DeleteOk'));
        this.loadData(this.filterQuery);
      },
        error => {
        });
  }

  checkEmployee(employee) {
    let id = employee.employee_id;
    this.employeeService.checkEmployee(id)
      .subscribe(results => {
        this.confirmFullDelete(employee, results)
      },
        error => {
        });
  }

  isHiddenPersonRow(employee: any): boolean {
    return employee.hidden ; // Please change the condition accordingly.
  }

  public selectChanged(event: any) {
    console.log(event.value);
    this.defaultFilterColumn = event.value;
  }

  clearSearchField() {
    this.searchName.reset();
  }


}

