import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { LocalStoreManager } from './local-store-manager.service';
import { Employee } from '../models/employee.model';
import { EmployeeId } from '../models/employeeId.model';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService, ApiResult } from '../base.service';

@Injectable({
  providedIn: 'root',
})

export class EmployeeService
  extends BaseService {
  constructor(
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private localStorage: LocalStoreManager) {
    super(http, baseUrl);
  }

  private employeeUrl: string = 'api/employee';

  getData<ApiResult>(
    tableNumber: number,
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: string,
    filterColumn: string,
    filterQuery: string
  ): Observable<ApiResult> {
    var url = 'api/employee';
    var params = new HttpParams()
      .set("tableNumber", tableNumber.toString())
      .set("pageIndex", pageIndex.toString())
      .set("pageSize", pageSize.toString())
      .set("sortColumn", sortColumn)
      .set("sortOrder", sortOrder);

    if (filterQuery) {
      params = params
        .set("filterColumn", filterColumn)
        .set("filterQuery", filterQuery);
    }

    return this.http.get<ApiResult>(url, { params });
  }

  newEmployee(employee: Employee): Observable<EmployeeId> {
     return this.http.post<EmployeeId>(this.employeeUrl, employee).pipe()
  }


  //private handleError(error: HttpErrorResponse) {
  //  let errorMessage = 'Unknown error!';
  //  console.log(errorMessage);
  //  if (error.error instanceof ErrorEvent) {
  //    // Client-side errors
  //    errorMessage = `Error: ${error.error.message}`;
  //  } else {
  //    // Server-side errors
  //    errorMessage = `Error Code: ${error.status}\nMessage: ${error.error.message}`;
  //  }
  //  console.log(errorMessage);
  //  console.error(errorMessage);
  //  return throwError(errorMessage);
  //}


  updateEmployee(employee ) {
    return this.http.put(this.employeeUrl, employee, { responseType: 'text' })
      .pipe(
      )
  }

  checkEmployee(id) {
    let language = this.localStorage.getDataObject("language");
    let endpointUrl = `${this.employeeUrl}/${id}:${language}`;
    return this.http.get(endpointUrl, { responseType: 'text' })
      .pipe()
  }

  deleteEmployee(EmployeeId: string, DelType: number) {
    const params = {
      EmployeeId: EmployeeId,
      DelType: DelType
    };
    return this.http.delete<Employee>(this.employeeUrl, { params });
  }

  get<Employee>(id): Observable<Employee> {
    let siteName = this.localStorage.getDataObject("sitename");
    var url = "api/employee/" + id;
    return this.http.get<Employee>(url);
  }

  put<Employee>(item): Observable<Employee> {
    var url = "api/employee/" + item.id;
    return this.http.put<Employee>(url, item);
  }

  post<Employee>(item): Observable<Employee> {
    var url = "api/employee";
    return this.http.post<Employee>(url, item);
  }

  delete<Employee>(id): Observable<Employee> {
    var url = "api/employee/" + id;
    return this.http.delete<Employee>(url);
  }

}
