export class Employee {
  updateScreen: number;
  employeeId: string;
  employee_id: number;
  employeeNo: string;
  surname: string;
  forename: string;
  ssn: string;
  guserTypeId: number;
  guserPwd: string;
  cardId: number;
  gender: number;
  acceptLevel: number;
  add_employee_no: string;
  salarysys_user: string;
  worktime_data: string;
  userLanguage: string;
  mobileTelephone: string;
  premiVisor: string;
  hidden: boolean;
  workingPos: string;
  lunchGroup: number;
  lunchDisable: boolean;
  additionalSupervisor: number;
  email: string;
  occupation: string;
  professionId: string;
  svId: number;
  additInfo: string;
  employeeGroup: number;
  emplStart: string;
  emplEnd: string;
  text: string;
  position: string;
  companyId: number;
  company: string;
  companyName: string;
  factoryId: number;
  factory: string;
  factoryName: string;
  departmentId: number;
  department: string;
  departmentName: string;
  sectionId: number;
  section: string;
  sectionName: string;
  lastChange: string;
  changemadeby: string;
}
