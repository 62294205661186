import { NgModule } from '@angular/core';
import { SharedModule } from '../shared.module';
import { SharedMaterialModule } from '../shared-mat.module';
import { ProjectRoutingModule } from './project-routing.module';
import { ProjectComponent } from './project.component';
import { ProjectListComponent } from './project-list/project-list.component';
import { ProjectReportComponent } from './projectreport/projectreport.component';
import { EditProjectDialogComponent } from './project-list/edit-project-dialog.component';
import { ProjectEditorComponent } from './project-list/project-editor.component';
import { ProjectPersonListComponent } from './projectperson-list/projectperson-list.component';
import { EditProjectPersonDialogComponent } from './projectperson-list/edit-projectperson-dialog.component';
import { ProjectPersonEditorComponent } from './projectperson-list/projectperson-editor.component';
import { ProjectEnterComponent } from './project-enter/project-enter.component';
import { PersonNameService } from '../services/personname.service';
import { EnterProjectService } from '../services/enterproject.service';
import { ProjectReportService } from '../services/projectreport.service';
import { CostPlaceService } from '../services/costplace.service';
import { ProjectToolTipDialog } from './project-tooltip/project-tooltip.component';

@NgModule({
  imports: [
    SharedModule,
    SharedMaterialModule,
    ProjectRoutingModule
  ],
  declarations: [
    ProjectComponent,
    ProjectListComponent,
    EditProjectDialogComponent,
    ProjectEditorComponent,
    ProjectPersonListComponent,
    EditProjectPersonDialogComponent,
    ProjectPersonEditorComponent,
    ProjectEnterComponent,
    ProjectReportComponent,
    ProjectToolTipDialog
  ],
  providers: [
    PersonNameService,
    EnterProjectService,
    ProjectReportService,
    CostPlaceService
  ]
})
export class ProjectModule {
}
