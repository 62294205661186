<h1 mat-dialog-title>{{'usertype.UserRights' | translate}}</h1>
<div mat-dialog-actions style="display: flex; justify-content: end; align-items: end; gap: 20px;">
  <button mat-raised-button (click)="save()" color="primary">{{ 'common.Save' | translate }}</button>
  <button mat-raised-button (click)="cancel()" color="warn">{{ 'common.Cancel' | translate }}</button>
</div>
<mat-list dense class="scrollable-list">
  <!-- Header Row -->
  <div class="list-header">
    <ng-container *ngFor="let col of userRightsSchema">
      <div class="header-item">{{ col.label }}</div>
    </ng-container>
  </div>

  <!-- Data Rows -->
  <mat-list-item *ngFor="let element of editableDataSource">
    <div class="list-item-container">
      <ng-container *ngFor="let col of userRightsSchema">
        <div class="list-item-content">
          <span *ngIf="col.type === 'boolean'">
            <mat-checkbox [checked]="element[col.key]" (change)="element[col.key] = $event.checked">
            </mat-checkbox>
          </span>
          <span *ngIf="col.type !== 'boolean'">
            <input matInput [required]="col.required" [pattern]="col.pattern" [readonly]="col.readonly"
                   [type]="col.type" [(ngModel)]="element[col.key]" class="borderless-input">
          </span>
        </div>
      </ng-container>
    </div>
  </mat-list-item>
</mat-list>






