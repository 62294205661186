import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStoreManager } from './local-store-manager.service';
import { Person } from '../models/person.model';
import { PersonNameId } from '../models/personidname.model';
import { Param } from '../models/param.model';
import { Organization } from '../models/organization.model';
import { Observable } from 'rxjs';

@Injectable()
export class PersonNameService {
  private personnamesUrl: string = 'api/personnames';
  private personstringUrl: string = 'api/personstring';
  private personidnameUrl: string = 'api/personidnames';
  public searchField = null;

  constructor(
    private http: HttpClient,
    private localStorage: LocalStoreManager) { }


  getorganizationpersonnames(organization: Organization[]): Observable<Person[]> {
    organization[0].showSvHierarchy = this.localStorage.getDataObject("showSvHierarchy");
    return this.http.post<Person[]>(this.personnamesUrl, organization);
  }

  getpersonidnames(): Observable<PersonNameId[]> {
    return this.http.get<PersonNameId[]>(this.personidnameUrl);
  }

  getpersonnames(personid: string): Observable<Person[]> {
    var param = new Param();
    param.showSvHierarchy = this.localStorage.getDataObject("showSvHierarchy");
    param.withEmployeeNo = this.localStorage.getDataObject("withEmployeeNo");
    param.employee_id = personid;
    return this.http.put<Person[]>(this.personnamesUrl, param);
  }

  getpersonstrings(personstring: string): Observable<Person[]> {
    var param = new Param();
    param.employee_id = personstring;
    return this.http.put<Person[]>(this.personstringUrl, param);
  }

}

