import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormsModule, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppTranslationService } from '../../services/app-translation.service';
import { UserTypeAright } from '../../models/usertypearight.model';
import { MatTableDataSource } from '@angular/material/table';
import { UserTypeService } from '../../services/usertype.service';

@Component({
  selector: 'usertypeaccessrights-dialog',
  templateUrl: './usertypeaccessrights.component.html',
  styleUrls: ['./usertypeaccessrights.component.css']
})
export class AccessRightsDialogComponent implements OnInit {
  accessRight: UserTypeAright = new UserTypeAright;
  userTypeId: number;
  enableOwnData: boolean;
  disableAutoRights: boolean;
  companyHierarchy: string;
  reportAccess: string;

  constructor(
    private userTypeService: UserTypeService,
    public dialogRef: MatDialogRef<AccessRightsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    
   }

  ngOnInit(): void {
    //this.userTypeId = this.data.userType;
    this.userTypeService.getUserTypeARights(this.data.userType)
      .subscribe(result => {
        this.enableOwnData = result.enableOwnData
        this.disableAutoRights = result.disableAutoRights;
        this.companyHierarchy = result.companyHierarchy;
        this.reportAccess = result.reportAccess;
    },
        (error: any) => {
  //console.log(error);
});
  }

  save(): void {
    this.accessRight.user_type_id = this.data.userType;
    this.accessRight.enableOwnData = this.enableOwnData;
    this.accessRight.disableAutoRights = this.disableAutoRights;
    this.accessRight.companyHierarchy = this.companyHierarchy;
    this.accessRight.reportAccess = this.reportAccess;
    this.dialogRef.close(this.accessRight);
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
