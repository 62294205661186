import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LocalStoreManager } from './local-store-manager.service';
import { Project } from '../models/project.model';
import { ProjectPerson } from '../models/projectperson.model';
import { ProjectData } from '../models/projectdata.model';
import { Observable } from 'rxjs';
import { BaseService, ApiResult } from '../base.service';

@Injectable({
  providedIn: 'root',
})

export class ProjectService
  extends BaseService {
  constructor(
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private localStorage: LocalStoreManager) {
    super(http, baseUrl);
  }

  private projectUrl: string = 'api/projects';
  private projectPersonUrl: string = 'api/projectpersons';

  getData<ApiResult>(
    tableNumber: number,
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: string,
    filterColumn: string,
    filterQuery: string
  ): Observable<ApiResult> {
    var url = 'api/projects';
    var params = new HttpParams()
      .set("tableNumber", tableNumber.toString())
      .set("pageIndex", pageIndex.toString())
      .set("pageSize", pageSize.toString())
      .set("sortColumn", sortColumn)
      .set("sortOrder", sortOrder);

    if (filterQuery) {
      params = params
        .set("filterColumn", filterColumn)
        .set("filterQuery", filterQuery);
    }

    return this.http.get<ApiResult>(url, { params });
  }

  get<Project>(id): Observable<Project> {
    let siteName = this.localStorage.getDataObject("sitename");
    var url = "api/projects/" + id;
    return this.http.get<Project>(url);
  }

  put<Project>(item): Observable<Project> {
    var url = "api/projects/" + item.id;
    return this.http.put<Project>(url, item);
  }

  post<Project>(item): Observable<Project> {
    var url = "api/projects";
    return this.http.post<Project>(url, item);
  }

  delete<Project>(id): Observable<Project> {
    var url = "api/projects/" + id;
    return this.http.delete<Project>(url);
  }

  getProjects(listType: string): Observable<ProjectData[]> {
    let endpointUrl = `${this.projectUrl}/${listType}`;
    return this.http.get<ProjectData[]>(endpointUrl);
  }

  newProject(project): Observable<Project> {
    return this.http.post<Project>(this.projectUrl, project);
  }

  updateProject(project): Observable<Project> {
    return this.http.put<Project>(this.projectUrl, project);
  }

  deleteProject(id): Observable<Project> {
    let endpointUrl = `${this.projectUrl}/${id}`;
    return this.http.delete<Project>(endpointUrl);
  }

  getProjectPerson(id:string): Observable<ProjectPerson[]> {
    let endpointUrl = `${this.projectPersonUrl}/${id}`;
    return this.http.get<ProjectPerson[]>(endpointUrl);
  }

  newProjectPerson(project): Observable<ProjectPerson> {
    return this.http.post<ProjectPerson>(this.projectPersonUrl, project);
  }

  updateProjectPerson(project): Observable<ProjectPerson> {
    return this.http.put<ProjectPerson>(this.projectPersonUrl, project);
  }

  deleteProjectPerson(id): Observable<ProjectPerson> {
     let endpointUrl = `${this.projectPersonUrl}/${id}`;
    return this.http.delete<ProjectPerson>(endpointUrl);
  }

}
