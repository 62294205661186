import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStoreManager } from './local-store-manager.service';
import { CostPlace } from '../models/costplace.model';

@Injectable()
export class CostPlaceService {
  private costplaceUrl: string = 'api/costplace';

    constructor(
      private http: HttpClient,
		  private localStorage: LocalStoreManager) { }

  getcostplaces(): Observable<CostPlace[] > {
    let username = this.localStorage.getDataObject("username");
    let siteName = this.localStorage.getDataObject("sitename");
    let endpointUrl = `${this.costplaceUrl}/${siteName}:${username}`;
    return this.http.get<CostPlace[]>(endpointUrl);
	}

}

