export class UserTypeUright {
  user_type_id: number;
  gui_id: number;
  description: string;
  readRight: boolean;
  writeRight: boolean;
}

export const UserRightsColumns = [
  {
    key: 'description',
    readonly: 'true',
    type: 'text',
    label: ''
  },
  {
    key: 'readRight',
    type: 'boolean',
    label: ''
  },
  {
    key: 'writeRight',
    type: 'boolean',
    label: ''
  }
];

