import { Component, ViewChild, Inject  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Employee } from '../../models/employee.model';
import { PersonsEditorComponent } from './persons-editor.component';
import { PersonsTaEditorComponent } from './persons-taeditor.component';
import { AppTranslationService } from "../../services/app-translation.service";
import { AlertService, DialogType } from '../../services/alert.service';

@Component({
  selector: 'app-persons-dialog',
  templateUrl: 'persons-dialog.component.html',
  styleUrls: ['persons-dialog.component.scss']
})
export class PersonsDialogComponent {
  @ViewChild(PersonsEditorComponent, { static: true })
  employeeEditor: PersonsEditorComponent;
  @ViewChild(PersonsTaEditorComponent, { static: false })
  employeeTaEditor: PersonsTaEditorComponent;
  writeRight: boolean = false;
  isTab1Disabled = false;
  isTab2Disabled = false;
  isNewRecord = false;

  get employeeName(): any {
    return this.data.employee ? { name: this.data.employee.surname } : null;
  }

  constructor(
    public dialogRef: MatDialogRef<PersonsEditorComponent>,
    private alertService: AlertService,
    public translationService: AppTranslationService,
    @Inject(MAT_DIALOG_DATA) public data: { employee: Employee, wr: boolean }) {
    if (data.employee == null) {
      this.isTab1Disabled = true;
      this.isTab2Disabled = true;
      this.isNewRecord = true;
    }
    this.writeRight = data.wr;
  }

  cancel(): void {
    let sname = this.employeeEditor.surname.value;
    this.dialogRef.close(sname);
  }

  onTabChanged(event: MatTabChangeEvent) {
    const tabIndex = event.index;
    const tab = event.tab;
    let saveQuestion = this.translationService.getTranslation('notifications.SaveQuestion');
    if (this.employeeEditor.employeeForm.dirty) 
    {
      this.alertService.showDialog(saveQuestion + '?',
        DialogType.confirm, () => this.employeeEditor.savePerson());
    }
    if (this.employeeTaEditor.employeeTaForm.dirty) {
      let saveQuestion = this.translationService.getTranslation('notifications.SaveQuestion');
      this.alertService.showDialog(saveQuestion + '?',
        DialogType.confirm, () => this.employeeTaEditor.saveTa());
    }
  }

  receiveMessage(eventData: any) {
    this.isTab1Disabled = false;
    this.isTab2Disabled = false;
  }

}
