import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LocalStoreManager } from './local-store-manager.service';
import { AbsenceCode } from '../models/absencecode.model';
import { BaseService, ApiResult } from '../base.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AbsenceCodeService
  extends BaseService {

  constructor(
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private localStorage: LocalStoreManager) {
    super(http, baseUrl);
  }

  private absencecodesUrl: string = 'api/absencecodes';

  newAbsenceCode(table) {
    return this.http.post(this.absencecodesUrl, table, { responseType: 'text' })
      .pipe(
    )
  }

  getData(): any {
    // No operation or return null/undefined if not used
    return null; // or undefined or an empty object based on your requirements
  }

  updateAbsenceCode(table) {
    return this.http.put(this.absencecodesUrl, table, { responseType: 'text' })
      .pipe(
        catchError((error) => {
          // Log the error or do other error handling here
          console.error('Update failed', error);
          // Rethrow the error or return a default value
          return throwError(error);
        })
      );
  }


  deleteBaseTable(id: string, tablenumber: number): Observable<any> {
    const params = {
      tableId: id,
      tableNumber: tablenumber
    };
    return this.http.delete<AbsenceCode>(this.absencecodesUrl, { params });
  }

  getSpecificAbsenceCode(id): Observable<AbsenceCode[]> {

    let language = this.localStorage.getDataObject("language");
    let sitename = this.localStorage.getDataObject("sitename");
    let endpointUrl = `${this.absencecodesUrl}/${sitename}:${language}:${id}`;
    return this.http.get<AbsenceCode[]>(endpointUrl);
  }

  delete<AbsenceCode>(id): Observable<AbsenceCode> {
    var url = this.absencecodesUrl + id;
    return this.http.delete<AbsenceCode>(url);
  }

  getabsencecodes(num: number, empId: string, inOut: string, corrDate: string): Observable<AbsenceCode[]> {
    let params = new HttpParams()
      .set('employee_id', empId)
      .set('language', this.localStorage.getDataObject<string>("language"))
      .set('siteName', this.localStorage.getDataObject<string>("sitename"))
      .set('inOut', inOut)
      .set('abstype', num.toString())
      .set('corrDate', corrDate);
    return this.http.get<AbsenceCode[]>(`${this.absencecodesUrl}`, { params: params });
  }

}


