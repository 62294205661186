
import { Component, OnDestroy, Input, ViewChild, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { WorkNumber } from '../../models/worknumber.model';
import { Company } from '../../models/company.model';
import { AppTranslationService } from '../../services/app-translation.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { WorkNumberService } from '../../services/worknumber.service';
import { OrganizationService } from '../../services/organization.service';


@Component({
  selector: 'app-worknumber-editor',
  templateUrl: './worknumber-editor.component.html',
  styleUrls: ['./worknumber-editor.component.scss']
})
export class WorkNumberEditorComponent implements OnChanges {
  @Input() tableData: WorkNumber = new WorkNumber();
  @Input() tableNumber: number;
  @ViewChild('form', { static: true }) form: NgForm;
  public isFormDirty$ = new Subject<boolean>();
  public isNewTable = false;
  private onTableSaved = new Subject<any>();
  public extraText: string;
  companies: Company[] = [];

  @Input() createNew: boolean = false;
  @Input() isEditMode = true;

  worknumberForm: FormGroup;
  tableSaved$ = this.onTableSaved.asObservable();

  ngAfterViewInit() {
    if (this.worknumberForm) {
      // Subscribe to changes in the form's values or status
      this.worknumberForm.valueChanges.subscribe(() => {
        this.updateFormDirtyStatus();
      });
    }
  }

  updateFormDirtyStatus() {
    const formIsDirty = this.worknumberForm.dirty;
    this.isFormDirty$.next(formIsDirty); // Emit the dirty state
    this.cdr.detectChanges();
    console.log('Form dirty status:', formIsDirty);
  }

  get tablenumber() {
    return this.worknumberForm.get('tablenumber');
  }
  get number() {
    return this.worknumberForm.get('number');
  }
  get reasoncode_type() {
    return this.worknumberForm.get('reasoncode_type');
  }
  get infotext() {
    return this.worknumberForm.get('infotext');
  }
  get title() {
    return this.worknumberForm.get('title');
  }
  get description() {
    return this.worknumberForm.get('description');
  }

  get infoReasoncode() {
    return this.worknumberForm.get('infoReasoncode');
  }
  
  get floatLabels(): string {
    return this.isEditMode ? 'auto' : 'always';
  }

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private workNumberService: WorkNumberService,
    private translationService: AppTranslationService,
    private organizationService: OrganizationService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.buildForm();
  }

  ngOnInit() {
    this.organizationService.getCompanies()
      .subscribe(data => {
        this.companies = data;
      },
        (error: any) => {
          //console.log(error);
        });
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('TableData received in TaBasetableEditorComponent:', changes);
    if (changes['tableData']) {
      console.log('Previous:', changes['tableData'].previousValue);
      console.log('Current:', changes['tableData'].currentValue);
    }
    if (this.tableData) {
      this.isNewTable = false;
      this.resetForm(); // Ensure form is reset with the current tableData
    }
  }

  updateTableData(changes: { previousValue: any; currentValue: any; firstChange: boolean }) {
    console.log('Manually triggered updateTableData:', changes);
    if (changes.currentValue) {
      this.isNewTable = this.createNew;;
      this.resetForm(); // Reset the form with the new tableData
    }
  }

  public save() {
    if (!this.form.submitted) {
      this.form.onSubmit(null);
      return;
    }

    if (!this.worknumberForm.valid) {
      /*      this.alertService.showValidationError();*/
      return;
    }

    var editedWorkNumbertable = this.getEditedWorkNumbertable();
    editedWorkNumbertable.tableNumber = Number(editedWorkNumbertable.tableNumber);

    if (this.isNewTable) {
      this.workNumberService.newWorkNumber(editedWorkNumbertable)
        .subscribe(response => this.saveSuccessHelper(0, response),
          error => {
            if (error == "Unauthorized")
              this.authService.logout();
            else
              this.saveFailedHelper(error);
          });
    }
    else {
      this.workNumberService.updateWorkNumber(editedWorkNumbertable).subscribe(
        response => this.saveSuccessHelper(1, response),
        error => {
          if (error == "Unauthorized")
            this.authService.logout();
          else
            this.saveFailedHelper(error);
        });
    }
  }

  private getEditedWorkNumbertable(): WorkNumber {
    const formModel = this.worknumberForm.value;

    return {
      tasknumber: formModel.tasknumber,
      description: formModel.description,
      closedate: formModel.closedate,
      company_id: formModel.company_id,
      costplaceA: formModel.costplaceA,
      costplaceB: formModel.costplaceB,
      tableNumber: formModel.tablenumber,
    };
  }

  private saveSuccessHelper(eType: number, mess: string) {
    const response = {
      message: eType === 0
        ? this.translationService.getTranslation('notifications.InsertRecordSucceeded')
        : this.translationService.getTranslation('notifications.UpdateRecordSucceeded'),
      tableNumber: this.tableData.tableNumber
    };
    this.alertService.successMessage(response.message);
    this.onTableSaved.next(response);
  }

  private saveFailedHelper(mess: string) {
    this.alertService.errorMessage(mess);
    //this.onTableSaved.next(mess);
  }

  private cancel() {
    this.resetForm();

    //this.alertService.resetStickyMessage();
  }

  private buildForm() {
    this.worknumberForm = this.formBuilder.group({
      tablenumber: [''],
      tasknumber: ['', Validators.required],
      description: ['', Validators.required],
      closedate: null,
      company_id: null,
      costplaceA: null,
      costplaceB: null,

    })
  }

  private resetForm() {
    if (!this.tableData) {
      this.isNewTable = true;
      this.tableData = new WorkNumber();
    }
    this.worknumberForm.reset({
      tablenumber: this.tableNumber,
      tasknumber: this.tableData.tasknumber || '',
      description: this.tableData.description,
      closedate: this.tableData.closedate || null,
      company_id: this.tableData.company_id || null,
      costplaceA: this.tableData.costplaceA || null,
      costplaceB: this.tableData.costplaceB || null

    });
  }


  get canManageTaDataa() {
    return true; //this.accountService.userHasPermission(Permission.manageRolesPermission);
  }
}

