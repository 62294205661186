<div class="page-content">
  <h3>{{'project.Projects' | translate}}</h3>
  <div fxLayout="row" fxLayoutGap="10px">
    <mat-form-field>
      <input matInput (keyup)="onFilterTextChanged($event.target.value)" placeholder="{{'common.FilterByName' | translate}}">
    </mat-form-field>
    <button mat-raised-button color="primary" class="custom-button" [disabled]="!writeRight" (click)="createProject()">{{'project.NewProject' | translate}}</button>
  </div>

  <div class="table-striped mat-elevation-z8" [hidden]="numberOfRows == 0">
    <mat-table [dataSource]="projects"
               matSort (matSortChange)="loadData()"
               matSortActive="{{defaultSortColumn}}" matSortDirection="{{defaultSortOrder}}">

      <ng-container matColumnDef="number">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'common.Number' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let project"> {{project.number}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'common.Name' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let project"> {{project.name}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'common.Descr' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let project"> {{project.description}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'stampreport.StartDate'| translate }} </mat-header-cell>
        <mat-cell *matCellDef="let project"> {{project.startDate| date: 'dd.MM.yyyy'}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'stampreport.EndDate'| translate }} </mat-header-cell>
        <mat-cell *matCellDef="let project"> {{project.endDate | date: 'dd.MM.yyyy'}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="personName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'common.ProjectOwner'| translate }} </mat-header-cell>
        <mat-cell *matCellDef="let project"> {{project.personName}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="costPlace">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'common.CostPlace'| translate }} </mat-header-cell>
        <mat-cell *matCellDef="let project"> {{project.costPlace}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef fxFlex="150px">
        </mat-header-cell>
        <mat-cell *matCellDef="let project" fxFlex="100px" fxFlexOffset="30px">
          <button mat-icon-button matTooltip="{{'common.Delete' | translate}}" [disabled]="!writeRight" (click)="$event.stopPropagation(); confirmDelete(project)">
            <mat-icon color="warn">delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"
               (click)="editProject(row)">
      </mat-row>
    </mat-table>
  </div>

  <mat-paginator [hidden]="numberOfRows < 11"
                 (page)="getData($event)"
                 [pageSize]="10"
                 [pageSizeOptions]="[10, 20, 50]"
                 showFirstLastButtons></mat-paginator>
</div>








