import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Substitute } from '../models/substitute.model';
import { Observable } from 'rxjs';
import { LocalStoreManager } from './local-store-manager.service';

@Injectable()
export class SubstituteService {
  private substituteUrl: string = 'api/substitutes';
	constructor(
      private http: HttpClient,
      private localStorage: LocalStoreManager)
    { }

  getSubstitutes(username: string): Observable<Substitute[]> {
    let sitename = this.localStorage.getDataObject("sitename");
    let endpointUrl = `${this.substituteUrl}/${sitename}:${username}`;
    return this.http.get<Substitute[]>(endpointUrl);
  }

  addSubstitute(sub: Substitute) {
    return this.http.post<Substitute>(this.substituteUrl, sub);
  }

  updateSubstitute(sub: Substitute) {
    return this.http.put<Substitute>(this.substituteUrl, sub);
  }

  deleteSubstitute(username: string, substitute: string) {
    let sitename = this.localStorage.getDataObject("sitename");
    let endpointUrl = `${this.substituteUrl}/${sitename}:${username}:${substitute}`;
    return this.http.delete(endpointUrl, { responseType: 'text' }).pipe(
    );
  }
}
