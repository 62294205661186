import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStoreManager } from './local-store-manager.service';
import { WorkingPos } from '../models/workingpos.model';

@Injectable()
export class WorkingPosService {
  private workingposUrl: string = 'api/workingpos';

  constructor(
    private http: HttpClient,
    private localStorage: LocalStoreManager) { }

  getWorkingPoss(): Observable<WorkingPos[]> {
    let sitename = this.localStorage.getDataObject("sitename");
    let endpointUrl = `${this.workingposUrl}/${sitename}`;
    return this.http.get<WorkingPos[]>(endpointUrl);
  }

}

