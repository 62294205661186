import { Component, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Project } from '../../models/project.model';
import { ProjectEditorComponent } from './project-editor.component';

@Component({
  selector: 'app-edit-project-dialog',
  templateUrl: 'edit-project-dialog.component.html',
  styleUrls: ['edit-project-dialog.component.scss']
})
export class EditProjectDialogComponent implements AfterViewInit {
  @ViewChild(ProjectEditorComponent, { static: true })
  projectEditor: ProjectEditorComponent;

  get projectName(): any {
    return this.data.project ? { name: this.data.project.name } : null;
  }

  constructor(
    public dialogRef: MatDialogRef<ProjectEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { project: Project, projectNumber }
  ) {
  }

  ngAfterViewInit() {
    this.projectEditor.projectSaved$.subscribe(project => this.dialogRef.close(project));
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

  get canManageProjects() {
    return true; //this.accountService.userHasPermission(Permission.manageRolesPermission);
  }
}
