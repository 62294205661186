import { NgModule, LOCALE_ID, ErrorHandler } from "@angular/core";
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from '@ng-select/ng-select';
import { registerLocaleData } from '@angular/common';
import localeFi from '@angular/common/locales/fi';
registerLocaleData(localeFi);
import localeSv from '@angular/common/locales/sv';
registerLocaleData(localeSv);
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { TreeviewModule } from 'ngx-treeview';
import { AppErrorHandler } from './app-error.handler';
import { MyDatePickerModule } from 'mydatepicker';
import { NgxSpinnerModule } from "ngx-spinner";
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationComponent } from './components/organization/organization.component';
import { OrganizationService } from './services/organization.service';

@NgModule({
  imports: [
    FlexLayoutModule,
    CommonModule,
    NgSelectModule,
    FormsModule, ReactiveFormsModule,
    MyDatePickerModule,
    NgxSpinnerModule,
    NgxDatatableModule,
    TranslateModule,
    NgbModule,
    TooltipModule.forRoot(),
    TreeviewModule.forRoot(),
    ModalModule.forRoot()
  ],
  declarations: [
    OrganizationComponent
  ],
  providers: [
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: LOCALE_ID, useValue: "fi" },
    OrganizationService
  ],
  exports: [
    FlexLayoutModule,
    CommonModule,
    NgSelectModule,
    MyDatePickerModule,
    NgxSpinnerModule,
    NgxDatatableModule,
    OrganizationComponent,
    TranslateModule,
    FormsModule, ReactiveFormsModule,
    NgbModule,
    ModalModule,
    TooltipModule,
    TreeviewModule
    ]
})
export class SharedModule {
}


