import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PersonCalendar } from '../models/personcalendar.model';
import { Observable } from 'rxjs';

@Injectable()

export class PersonCalendarService {
  private personcalendarUrl: string = 'api/personcalendars';

  constructor(
    private http: HttpClient) { }

  getCalendars(employeeId:number): Observable<PersonCalendar[]> {
    let endpointUrl = `${this.personcalendarUrl}/${employeeId}`;
    return this.http.get<PersonCalendar[]>(endpointUrl);
  }

  addPersonCalendar(personCalendar): Observable<PersonCalendar> {
    return this.http.post<PersonCalendar>(this.personcalendarUrl, personCalendar);
  }

  updatePersonCalendar(personCalendar): Observable<PersonCalendar> {
    return this.http.put<PersonCalendar>(this.personcalendarUrl, personCalendar);
  }

  deletePersonCalendar(empid: number, startdate: Date, basecalendar: number) {
    let sd = new Date(startdate);
    let fullyear = sd.getFullYear();
    let month = ('00' + (sd.getMonth() + 1)).slice(-2);
    let day = ('00' + sd.getDate()).slice(-2);
    let stDate = fullyear + "-" + month + "-" + day ;
    const params = {
      EmployeeId: empid,
      StartDate: stDate,
      Basecalendar: basecalendar
    };
    return this.http.delete(this.personcalendarUrl, { params, responseType: 'text' });



    //return this.http.delete<PersonCalendar>(this.personcalendarUrl, { params, responseType: 'text' });
  }
  
}
