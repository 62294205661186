import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { LocalStoreManager } from './local-store-manager.service';
import { UserLogin } from '../models/user-login.model';
import { HttpClient } from '@angular/common/http';
import { JwtHelper } from './jwt-helper';
import { RefreshToken } from '../models/refreshtoken.model';

@Injectable({
  providedIn: 'root' // This ensures a single instance is used application-wide
})
export class AuthService {

  public systemName: string = "";
  public systemNameChange: BehaviorSubject<string> = new BehaviorSubject<string>(this.systemName);
  public userLogInChange: Subject<boolean> = new Subject<boolean>();
  public loginRedirectUrl: string;
  public logoutRedirectUrl: string;
  private previousIsLoggedInCheck = false;
  private _loginStatus = new Subject<boolean>();
  private _loginUrl: string = '/users/authenticate';
  private _baseUrl: string = this.baseUrl().replace(/\/$/, '');
  public get loginUrl() { return this._baseUrl + this._loginUrl; }
  public get homeUrl() { return "/"; }
  public reLoginDelegate: () => void;
  systemNamePromise: Promise<string>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private localStorage: LocalStoreManager) {
    this.initializeLoginStatus();
  }

  baseUrl() {
    if (window.location.origin)
      return window.location.origin
    return window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
  }

  private initializeLoginStatus() {
    this.localStorage.getInitEvent().subscribe(() => {
      this.reevaluateLoginStatus();
    });
  }

  getDefaultLanguage() {
    let endpointUrl = `${this.loginUrl}/language`;
    return this.http.get(endpointUrl, { responseType: 'text' })
      .pipe(
    )
  }

  login(logindata: UserLogin) {
    if (this.isLoggedIn)
      this.logout();
    logindata.userRights = 0; // real login
    logindata.reportRights = 0; // real login
    var d = new Date();
    let fullyear = d.getFullYear();
    let month = ('00' + (d.getMonth() + 1)).slice(-2);
    let day = ('00' + d.getDate()).slice(-2);
    let hour = ('00' + d.getHours()).slice(-2);
    let minute = ('00' + d.getMinutes()).slice(-2);
    logindata.logintime = day + "/" + month + "/" + fullyear + " " + hour + ":" + minute + ":00";
    return this.http.post(this.loginUrl, logindata)
      .pipe(
    )
  }

  public store_userdata(user) {
    let jwtHelper = new JwtHelper();
    let decodedIdToken = jwtHelper.decodeToken(user.access_token);
    let expires = decodedIdToken.exp;
    let username = decodedIdToken.username;
    let sitename = decodedIdToken.sitename;
    //this.setSystemName(sitename);
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    this.localStorage.saveSyncedSessionData(user, "current_user");
    this.localStorage.saveSyncedSessionData(expires, "expires_in");
    this.localStorage.saveSyncedSessionData(sitename, "sitename");
    this.localStorage.saveSyncedSessionData(user.siteid, "siteid");
    if (user.password == "substitute") {
      this.localStorage.saveSyncedSessionData(this.localStorage.getDataObject("username"), "substitute");
      this.localStorage.saveSyncedSessionData(username, "username");
    }
    else {
      if (user.rememberMe) {
        this.localStorage.savePermanentData(username, "username");
        this.localStorage.savePermanentData(user.password, "password");
        this.localStorage.savePermanentData(user.rememberMe, "rememberme");
      }
      else {
        this.localStorage.saveSyncedSessionData(username, "username");
        this.localStorage.saveSyncedSessionData(user.password, "password");
        this.localStorage.saveSyncedSessionData(user.rememberMe, "rememberme");
      }
    }
    this.localStorage.saveSyncedSessionData(user.access_token, "id_token");
    this.localStorage.saveSyncedSessionData(user.refresh_token, "refresh_token");
    this.localStorage.saveSyncedSessionData(user.hasOrgRight, "orgright");
    this.localStorage.saveSyncedSessionData(user.userRights, "userrights");
    this.localStorage.saveSyncedSessionData(user.readRights, "readrights");
    this.localStorage.saveSyncedSessionData(user.reportRights, "reportrights");
    this.localStorage.saveSyncedSessionData(user.language, "language");
    this.localStorage.saveSyncedSessionData(user.guser, "guser");
    this.localStorage.saveSyncedSessionData(user.personCount, "personcount");
    this.localStorage.saveSyncedSessionData(user.isAdmin, "isadmin");
    this.localStorage.saveSyncedSessionData(user.hasSubstitutes, "hassubstitutes");
    this.localStorage.saveSyncedSessionData(user.sendMail, "sendmail");
    this.localStorage.saveSyncedSessionData(user.showSvHierarchy, "showSvHierarchy");
    this.localStorage.saveSyncedSessionData(user.withEmployeeNo, "withEmployeeNo");
    this.localStorage.saveSyncedSessionData(user.personName, "personname");
    this.localStorage.saveSyncedSessionData(user.employee_id, "employee_id");
    this.localStorage.saveSyncedSessionData(user.projects, "projects");
    this.reevaluateLoginStatus(user);
  }

  refresh_userdata(user) {
    let jwtHelper = new JwtHelper();
    let decodedIdToken = jwtHelper.decodeToken(user.access_token);
    let expires = decodedIdToken.exp;
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    this.localStorage.saveSyncedSessionData(expires, "expires_in");
    this.localStorage.saveSyncedSessionData(user.access_token, "id_token");
    this.localStorage.saveSyncedSessionData(user.refresh_token, "refresh_token");
    this.reevaluateLoginStatus(user);
  }

  delete_userdata() {
    this.localStorage.deleteData("current_user");
    let substitute = this.localStorage.getDataObject("substitute");
    if (substitute != null) {
      this.localStorage.saveSyncedSessionData(true, "rememberme");
      this.localStorage.savePermanentData(substitute, "username");
    }
    let remember = this.localStorage.getDataObject("rememberme");
    if (!remember) {
      this.localStorage.deleteData("username");
      this.localStorage.deleteData("password");
      this.localStorage.deleteData("remember");
    }
    this.localStorage.deleteData("id_token");
    this.localStorage.deleteData("orgright");
    this.localStorage.deleteData("rights");
    this.localStorage.deleteData("expires_in");
    this.localStorage.deleteData("refresh_token");
    this.localStorage.deleteData("language");
    this.localStorage.deleteData("sitename");
    this.localStorage.deleteData("guser");
    this.localStorage.deleteData("personcount");
    this.localStorage.deleteData("isadmin");
    this.localStorage.deleteData("hassubstitutes");
    this.localStorage.deleteData("personname");
    this.localStorage.deleteData("employee_id");
    this.localStorage.deleteData("substitute");
    this.localStorage.deleteData("sendmail");
    this.localStorage.deleteData("showSvHierarchy");
    this.localStorage.deleteData("withEmployeeNo");
    this.localStorage.deleteData("projects");
  }


  logout() {
    // send logout message to server
    let userLogin = new UserLogin();
    userLogin.username = this.localStorage.getDataObject("username");
    userLogin.sitename = this.localStorage.getDataObject("sitename");
    var d = new Date();
    let fullyear = d.getFullYear();
    let month = ('00' + (d.getMonth() + 1)).slice(-2);
    let day = ('00' + d.getDate()).slice(-2);
    let hour = ('00' + d.getHours()).slice(-2);
    let minute = ('00' + d.getMinutes()).slice(-2);
    userLogin.logintime = day + "/" + month + "/" + fullyear + " " + hour + ":" + minute + ":00";
    this.delete_userdata();
    this.reevaluateLoginStatus();
    return this.http.put(this.loginUrl, userLogin)
      .pipe(
    )
  }

  get accessTokenExpiryDate(): Date {

    this.reevaluateLoginStatus();
    return this.localStorage.getDataObject<Date>("expires_in", true);
  }

  get isSessionExpired(): boolean {

    if (this.accessTokenExpiryDate == null) {
      return true;
    }

    return !(this.accessTokenExpiryDate.valueOf() * 1000 > new Date().valueOf());
  }

  private reevaluateLoginStatus(currentUser?: UserLogin) {

    let user = currentUser || this.localStorage.getDataObject<UserLogin>("current_user");
    let isLoggedIn = user != null;
    if (isLoggedIn) {
      let language = user.language;
    }

    if (this.previousIsLoggedInCheck != isLoggedIn) {
      setTimeout(() => {
        this._loginStatus.next(isLoggedIn);
      });
    }

    this.previousIsLoggedInCheck = isLoggedIn;
  }

  getLoginStatusEvent(): Observable<boolean> {
    return this._loginStatus.asObservable();
  }

  get currentUser(): UserLogin {
    let user = this.localStorage.getDataObject<UserLogin>("current_user");
    this.reevaluateLoginStatus(user);
    return user;
  }

  get isLoggedIn(): boolean {
    return this.currentUser != null;
  }

  redirectLoginUser() {
    let redirect = this.loginRedirectUrl ? this.loginRedirectUrl : this.homeUrl;
    this.loginRedirectUrl = null;
    this.router.navigate([redirect]);
  }

  redirectLogoutUser() {
    let redirect = this.logoutRedirectUrl ? this.logoutRedirectUrl : "/login"; //+ "?id=" + this.localStorage.getDataObject("system_id");
    this.logoutRedirectUrl = null;
    let num = this.localStorage.getDataObject("siteid");
    this.router.navigate([redirect], { queryParams: { id: num } });
  }

  autoLogout() { // jwt token refresh is used as auto logout if no use
    //    if (this.isLoggedIn) {
    let userLogin = new UserLogin();
    userLogin.username = this.localStorage.getDataObject("username");
    userLogin.sitename = this.localStorage.getDataObject("sitename");
    var d = new Date();
    let fullyear = d.getFullYear();
    let month = ('00' + (d.getMonth() + 1)).slice(-2);
    let day = ('00' + d.getDate()).slice(-2);
    let hour = ('00' + d.getHours()).slice(-2);
    let minute = ('00' + d.getMinutes()).slice(-2);
    userLogin.logintime = day + "/" + month + "/" + fullyear + " " + hour + ":" + minute + ":00";
    userLogin.userRights = 1; // refresh login
    this.delete_userdata();
    this.reevaluateLoginStatus();
    this.redirectLogoutUser();
    return this.http.post(this.loginUrl, userLogin)
      .subscribe(responce => {
        //this.delete_userdata()
        //this.redirectLogoutUser();
      })
    //   }
  }

  // In AuthService
  setSystemName(newName: string) {
    //this.systemNamePromise = new Promise((resolve, reject) => {
    //  this.systemName = newName;
    //  resolve(this.systemName);
     this.systemName = newName;
     console.log("SystemName " + this.systemName);
     this.systemNameChange.next(this.systemName); // Emit the change
    }

  get currentSystemName(): string {
    return this.systemName;
  }

  refreshLogin() { // 
    let user = this.localStorage.getDataObject("username");
    let site = this.localStorage.getDataObject("sitename");
    let lang = this.localStorage.getDataObject("language");
    let endpointUrl = `${this.loginUrl}/${user}:${site}:${lang}`;
    return this.http.get<RefreshToken>(endpointUrl)
      .pipe()
  }
}


