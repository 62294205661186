<div [formGroup]="personCalendarForm">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <input type="hidden" formControlName="employeeId">
    <input type="hidden" formControlName="employee_id">
    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'employee.Surname' | translate}}</mat-label>
      <input matInput formControlName="surname" maxlength="30" readonly />
    </mat-form-field>
    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'employee.Forename' | translate}}</mat-label>
      <input matInput formControlName="forename" maxlength="20" readonly />
    </mat-form-field>
    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'employee.EmployeeNo' | translate}}</mat-label>
      <input matInput formControlName="employeeNo" maxlength="12" readonly />
    </mat-form-field>
  </div>
</div>
<div>
  <button mat-raised-button (click)="addRow()" [disabled]="!wr" color="primary" style="float: right;">{{'common.AddRow' | translate}}</button>
</div>

<table mat-table [dataSource]="dataSource">
  <ng-container [matColumnDef]="col.key" *ngFor="let col of columnsSchema">
    <th mat-header-cell *matHeaderCellDef [ngSwitch]="col.key">
      <span *ngSwitchDefault>{{ col.label }}</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <div [ngSwitch]="col.type" *ngIf="!element.isEdit">
        <div class="btn-edit" *ngSwitchCase="'isEdit'">
          <button mat-button [disabled]="!wr" (click)="element.isEdit = !element.isEdit">
            {{'common.Change' | translate}}
          </button>
          <button mat-button [disabled]="!wr" class="button-remove" (click)="confirmDeleteCalendar(element)">
            {{'common.Delete' | translate}}
          </button>
        </div>
        <span *ngSwitchCase="'startdate'">
          {{ element[col.key] | date: 'dd.MM.yyyy' }}
        </span>
        <span *ngSwitchCase="'enddate'">
          {{ element[col.key] | date: 'dd.MM.yyyy' }}
        </span>
        <span *ngSwitchDefault>
          {{ element[col.key] }}
        </span>
      </div>
      <div [ngSwitch]="col.type" *ngIf="element.isEdit">
        <div class="btn-edit" *ngSwitchCase="'isEdit'">
          <button mat-button (click)="element.isEdit = !element.isEdit">
            {{'common.Cancel' | translate}}
          </button>
          <button mat-button (click)="editRow(element)" [disabled]="disableSubmit(element.id)">
            {{'common.Edit' | translate}}
          </button>
        </div>
        <mat-form-field class="form-input" *ngSwitchCase="'startdate'" appearance="fill">
          <mat-label>{{'common.ChooseDate' | translate}}</mat-label>
          <input matInput
                 [required]="col.required"
                 [matDatepicker]="picker"
                 [(ngModel)]="element[col.key]"
                 [readonly]="true" />
          <mat-datepicker-toggle *ngIf="adding" matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="form-input"
                        *ngSwitchCase="'enddate'"
                        appearance="fill">
          <mat-label>{{'common.ChooseDate' | translate}}</mat-label>
          <input matInput
                 [required]="col.required"
                 [matDatepicker]="picker"
                 [(ngModel)]="element[col.key]"
                 [readonly]="true"/>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>

        </mat-form-field>

        <mat-form-field *ngSwitchCase="'select'"
                        appearance="fill">
          <mat-select [(ngModel)]="element[col.key]" required>
            <mat-option *ngFor="let calendarRow of basecalendars" [value]="calendarRow.basecalendarId">
              {{ calendarRow.basecalendarId }} {{ calendarRow.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="form-input" *ngSwitchDefault>
          <input matInput
                 [required]="col.required"
                 [pattern]="col.pattern"
                 [readonly]="col.readonly"
                 [type]="col.type"
                 [(ngModel)]="element[col.key]"
                 (change)="inputHandler($event, element.id, col.key)" />
        </mat-form-field>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
