<form (keydown.enter)="loadNameData()">
  <div class="container">
    <h3>{{'employee.Employees' | translate}}</h3>
    <div class="persondata" [formGroup]="personData">
      <div fxLayout="row" fxLayoutGap="10px">
        <mat-form-field fxFlex>
          <input matInput formControlName="searchName" placeholder="{{'common.personSearch' | translate}}">
          <button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchName.value" (click)="clearSearchField()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <button mat-raised-button color="primary" class="custom-button" (click)="loadNameData()">{{'common.Refresh' | translate}}</button>
        <mat-checkbox (change)="checkboxChanged($event)">{{'common.ShowHidden' | translate}}</mat-checkbox>
        <button mat-raised-button color="primary" class="custom-button" [disabled]="!writeRight" (click)="createEmployee()">{{'employee.NewEmployee' | translate}}</button>
      </div>
    </div>
    <div class="mat-elevation-z8" [hidden]="numberOfRows == 0">
      <mat-table [dataSource]="employees"
                 matSort (matSortChange)="loadData()"
                 matSortActive="{{defaultSortColumn}}"
                 matSortDirection="{{defaultSortOrder}}">
        <ng-container matColumnDef="surname">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{'employee.Surname' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let employee"> {{employee.surname}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="forename">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{'employee.Forename' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let employee"> {{employee.forename}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="employee_no">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{'employee.EmployeeNo' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let employee"> {{employee.employeeNo}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="company">
          <mat-header-cell *matHeaderCellDef> {{'common.Company' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let employee"> {{employee.company}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="companyName">
          <mat-header-cell *matHeaderCellDef> {{'common.Descr' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let employee"> {{employee.companyName}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef fxFlex="150px">
          </mat-header-cell>
          <mat-cell *matCellDef="let employee" fxFlex="100px" fxFlexOffset="30px">
            <button mat-icon-button matTooltip="{{'common.Delete' | translate}}" [disabled]="!writeRight" (click)="$event.stopPropagation(); confirmDelete(employee)">
              <mat-icon color="warn">delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"
                 (click)="editEmployee(row)"
                 [ngClass]="{'hidden-row': isHiddenPersonRow(row)}">
        </mat-row>


      </mat-table>
    </div>

    <mat-paginator [hidden]="numberOfRows < 11"
                   (page)="getData($event)"
                   [pageSize]="10"
                   [pageSizeOptions]="[10, 20, 50]"
                   showFirstLastButtons></mat-paginator>
  </div>

</form>






