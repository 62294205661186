<form #form="ngForm" [formGroup]="absenceCodeForm" novalidate (ngSubmit)="save()" fxLayout="column" [fxLayoutGap]="isEditMode ? '1em' : '0'" autocomplete="off">
  <mat-form-field class="invisible example-full-width">
    <input matInput formControlName="tablenumber" [readonly]="true" />
  </mat-form-field>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field [floatLabel]="floatLabels" fxFlex="150px">
      <mat-label>{{'common.Number' | translate}}</mat-label>
      <input matInput max="32767" formControlName="number" [readonly]="!isNewTable" [attr.tabindex]="!isNewTable ? -1 : null" />
    </mat-form-field>
    <mat-form-field [floatLabel]="floatLabels" fxFlex="100px">
      <mat-label>{{'common.Title' | translate}}</mat-label>
      <input matInput formControlName="title" maxlength="3" />
    </mat-form-field>
    <mat-form-field [floatLabel]="floatLabels" fxFlex="200px">
      <mat-label>{{'common.Infotext' | translate}}</mat-label>
      <input matInput formControlName="infotext" maxlength="16" />
    </mat-form-field>
    <mat-form-field [floatLabel]="floatLabels" fxFlex="400px">
      <mat-label>{{'common.Descr' | translate}}</mat-label>
      <input matInput formControlName="description" maxlength="40" />
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field [floatLabel]="floatLabels" fxFlex="400px">
      <mat-label>{{'AbsenceCode.ReasoncodeType'|translate}}</mat-label>
      <mat-select id="reasoncode_type" formControlName="reasoncode_type">
        <mat-option *ngFor="let rctype of reaconcodetypes" [value]="rctype.itemNumber">
          {{rctype.itemNumber}} {{rctype.description}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field [floatLabel]="floatLabels" fxFlex="100px">
      <mat-label>{{'AbsenceCode.InfoReasonCode' | translate}}</mat-label>
      <input matInput formControlName="infoReasoncode" maxlength="3" />
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
    <mat-slide-toggle formControlName="long_term">{{'AbsenceCode.LongTerm' | translate}}</mat-slide-toggle>
    <mat-slide-toggle formControlName="no_eff_on_info">{{'AbsenceCode.NoEffOnInfo' | translate}}</mat-slide-toggle>
    <mat-slide-toggle formControlName="advance_booking">{{'AbsenceCode.AdvanceBooking' | translate}}</mat-slide-toggle>
    <mat-slide-toggle formControlName="no_ct600_returntime">{{'AbsenceCode.NoCTReturnTime' | translate}}</mat-slide-toggle>
  </div>
  <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
    <mat-slide-toggle formControlName="Not_allowed">{{'AbsenceCode.NotAllowed' | translate}}</mat-slide-toggle>
    <mat-slide-toggle formControlName="Not_allowed_in_client">{{'AbsenceCode.NotAllowedInClient' | translate}}</mat-slide-toggle>
    <mat-slide-toggle formControlName="extended_calctm">{{'AbsenceCode.ExtendedCalc' | translate}}</mat-slide-toggle>
  </div>
  <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
    <mat-slide-toggle formControlName="acc_to_flex_saldo">{{'AbsenceCode.AccToFlexSaldo' | translate}}</mat-slide-toggle>
    <mat-slide-toggle formControlName="acc_to_def_job">{{'AbsenceCode.AccToDefJob' | translate}}</mat-slide-toggle>
    <mat-slide-toggle formControlName="acc_to_job">{{'AbsenceCode.AccToJob' | translate}}</mat-slide-toggle>
    <mat-slide-toggle formControlName="acc_to_circ_add">{{'AbsenceCode.AccToCircAdd' | translate}}</mat-slide-toggle>
  </div>
  <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
    <mat-slide-toggle formControlName="acc_to_worktime">{{'AbsenceCode.AccToWorkTime' | translate}}</mat-slide-toggle>
    <mat-slide-toggle formControlName="no_eff_on_overtime">{{'AbsenceCode.NoEffOnOvertime' | translate}}</mat-slide-toggle>
    <mat-slide-toggle formControlName="hide_worktime">{{'AbsenceCode.HideWorktime' | translate}}</mat-slide-toggle>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field>
      <mat-label>{{'AbsenceCode.AcceptLevel' | translate}}</mat-label>
      <mat-select formControlName="acceptlevel">
        <mat-option [value]="">{{'helps.NoValue' | translate}}</mat-option>
        <mat-option *ngFor="let al of acceptlevels" [value]="al.acceptlevel_id">
          {{ al.acceptlevel_id }} {{ al.description }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'AbsenceCode.AccAddsToSaldo' | translate}}</mat-label>
      <mat-select formControlName="acc_adds_to_saldo">
        <mat-option [value]="">{{'helps.NoValue' | translate}}</mat-option>
        <mat-option *ngFor="let st of saldotypes" [value]="st.saldonumber_id">
          {{ st.saldonumber_id }} {{ st.descr }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'AbsenceCode.AccDecsFromSaldo' | translate}}</mat-label>
      <mat-select formControlName="acc_decs_from_saldo">
        <mat-option [value]="">{{'helps.NoValue' | translate}}</mat-option>
        <mat-option *ngFor="let st of saldotypes" [value]="st.saldonumber_id">
          {{ st.saldonumber_id }} {{ st.descr }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'AbsenceCode.CalcRuleId' | translate}}</mat-label>
      <mat-select formControlName="calc_rule_id">
        <mat-option [value]="">{{'helps.NoValue' | translate}}</mat-option>
        <mat-option *ngFor="let cr of calcrules" [value]="cr.calc_rule_id">
          {{ cr.calc_rule_id }} {{ cr.descr }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <div *ngIf="reasoncodeType == 1">
      <mat-form-field>
        <mat-label>{{'AbsenceCode.ExtraReasoncodeId' | translate}}</mat-label>
        <mat-select formControlName="extra_reasoncode_id">
          <mat-option [value]="">{{'helps.NoValue' | translate}}</mat-option>
          <mat-option *ngFor="let ac of absencecodes" [value]="ac.extra_reasoncode_id">
            {{ ac.extra_reasoncode_id }} {{ ac.infotext }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="reasoncodeType == 23">
      <mat-form-field>
        <mat-label>{{'AbsenceCode.VacSatReasoncodeId' | translate}}</mat-label>
        <mat-select formControlName="vac_sat_reasoncode_id">
          <mat-option [value]="">{{'helps.NoValue' | translate}}</mat-option>
          <mat-option *ngFor="let ac1 of absencecodesvac" [value]="ac1.vac_sat_reasoncode_id">
            {{ ac1.number }} {{ ac1.infotext }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{'AbsenceCode.VacSunReasoncodeId' | translate}}</mat-label>
        <mat-select formControlName="vac_sun_reasoncode_id">
          <mat-option [value]="">{{'helps.NoValue' | translate}}</mat-option>
          <mat-option *ngFor="let ac2 of absencecodesvac" [value]="ac2.vac_sun_reasoncode_id">
            {{ ac2.number }} {{ ac2.infotext }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
    <mat-slide-toggle formControlName="norm_work">{{'AbsenceCode.NormWork' | translate}}</mat-slide-toggle>
    <mat-slide-toggle formControlName="transfer_system1">{{'AbsenceCode.TransferSystem1' | translate}}</mat-slide-toggle>
    <mat-slide-toggle formControlName="transfer_system2">{{'AbsenceCode.TransferSystem2' | translate}}</mat-slide-toggle>
    <mat-form-field [floatLabel]="floatLabels" fxFlex="200px">
      <mat-label>{{'AbsenceCode.SalarytypeId' | translate}}</mat-label>
      <input matInput formControlName="salarytype_id" maxlength="3" />
    </mat-form-field>
  </div>

</form>

