import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStoreManager } from './local-store-manager.service';
import { UserLanguage } from '../models/userlanguage.model';

@Injectable()
export class UserLanguageService {
  private userLanguageUrl: string = 'api/userlanguages';

  constructor(
    private http: HttpClient,
    private localStorage: LocalStoreManager) { }

  getUserLanguages(): Observable<UserLanguage[]> {
    let sitename = this.localStorage.getDataObject("sitename");
    let endpointUrl = `${this.userLanguageUrl}/${sitename}`;
    return this.http.get<UserLanguage[]>(endpointUrl);
  }

}

