import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TimeAttendanceComponent } from './timeattendance.component';
import { TaBasetableComponent } from './tabasetable/tabasetable.component';
import { AuthService } from '../services/auth.service';
import { AuthGuard } from '../services/auth-guard.service';

const timeattendanceRoutes: Routes = [
  {
    path: 'timeattendance', component: TimeAttendanceComponent,
    children: [
      { path: 'tabasetable3', component: TaBasetableComponent, canActivate: [AuthGuard] },
      { path: 'tabasetable4', component: TaBasetableComponent, canActivate: [AuthGuard] },
      { path: 'tabasetable5', component: TaBasetableComponent, canActivate: [AuthGuard] },
      { path: 'tabasetable6', component: TaBasetableComponent, canActivate: [AuthGuard] },
      { path: 'tabasetable7', component: TaBasetableComponent, canActivate: [AuthGuard] }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(timeattendanceRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: [
    AuthService, AuthGuard
  ]
})
export class TimeAttendanceRoutingModule { }
