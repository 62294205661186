import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserType } from '../models/usertype.model';
import { UserTypeUright } from '../models/usertypeuright.model';
import { UserTypeAright } from '../models/usertypearight.model';

@Injectable()
export class UserTypeService {
  private userTypesUrl: string = 'api/usertype/usertypes';
  private userTypeRightsUrl: string = 'api/usertype/userurights';
  private userTypeARightsUrl: string = 'api/usertype/userarights';
  constructor(
    private http: HttpClient) { }

  getGeneralUserTypes(): Observable<UserType[]> {
    let id = "guser";
    let endpointUrl = `${this.userTypesUrl}/${id}`;
    return this.http.get<UserType[]>(endpointUrl);
  }

  getUserTypes(id: string): Observable<UserType[]> {
    let endpointUrl = `${this.userTypesUrl}/${id}`;
    return this.http.get<UserType[]>(endpointUrl);
  }

  updateUserType(usertype:any): Observable<UserType> {
    return this.http.put<UserType>(this.userTypesUrl, usertype);
  }

  newUserType(usertype: UserType) {
    return this.http.post<number>(this.userTypesUrl, usertype);
  }

  deleteUserType(id: number) {
    let endpointUrl = `${this.userTypesUrl}/${id}`;
    return this.http.delete<UserType>(endpointUrl);
  }

  getUserTypeRights(id:number): Observable<UserTypeUright[]> {
    let endpointUrl = `${this.userTypeRightsUrl}/${id}`;
    return this.http.get<UserTypeUright[]>(endpointUrl);
  }

  getUserTypeARights(id: number): Observable<UserTypeAright> {
    let endpointUrl = `${this.userTypeARightsUrl}/${id}`;
    return this.http.get<UserTypeAright>(endpointUrl);
  }

  updateUserARights(useraright: any): Observable<UserTypeAright> {
    return this.http.put<UserTypeAright>(this.userTypeARightsUrl, useraright);
  }

  updateUserURights(useruright: UserTypeUright[]): Observable<UserTypeUright> {
    return this.http.put<UserTypeUright>(this.userTypeRightsUrl, useruright);
  }

  newUserURight(id: number) {
    return this.http.post(this.userTypeRightsUrl, { id });
  }

  newUserARight(id: number) {
    return this.http.post(this.userTypeARightsUrl, { id });
  }

}

