import { Component, ViewChild, Inject, AfterViewInit, ViewContainerRef, ChangeDetectorRef, ComponentFactoryResolver } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TaBasetableService } from '../../services/tabasetable.service';
import { BaseTable } from '../../models/basetable.model';
import { AbsenceCode } from '../../models/absencecode.model';
import { WorkNumber } from '../../models/worknumber.model';
import { TaBasetableEditorComponent } from './tabasetable-editor.component';
import { AbsenceCodeEditorComponent } from './absencecode-editor.component';
import { WorkNumberEditorComponent } from './worknumber-editor.component';
import { EditorComponent } from './editor-component.interface'; // Assuming the interface is saved in this path

@Component({
  selector: 'app-tabasetable-dialog',
  templateUrl: 'tabasetable-dialog.component.html',
  styleUrls: ['tabasetable-dialog.component.scss']
})
export class TabasetableDialogComponent implements AfterViewInit {
  // ViewContainerRef will hold the dynamically loaded component
  @ViewChild('dynamicComponentContainer', { read: ViewContainerRef, static: true })
  dynamicComponentContainer: ViewContainerRef;
  private editorComponentRef: any; // Store the reference to the loaded component
  editText: string;
  newText: string;
  isFormDirty: boolean = false; // To track the form's dirty state

  constructor(
    public dialogRef: MatDialogRef<TaBasetableEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { baseTable: any, tableNumber, createNew, editText, newText },
/*    private taBasetableServiceService: TaBasetableService,*/
    private componentFactoryResolver: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef
  ) {
    this.editText = data.editText;
    this.newText = data.newText;
  }

  ngAfterViewInit() {
    this.loadEditorComponent(this.data.tableNumber); // Load the editor component dynamically based on tableNumber
    this.cdr.detectChanges(); // Run change detection manually to prevent ExpressionChangedAfterItHasBeenCheckedError
  }

  loadEditorComponent(tableNumber: number) {
    this.dynamicComponentContainer.clear(); // Clear any previously loaded components

    let componentFactory;

    // Check the tableNumber and dynamically load the respective component
    if (tableNumber === 5) {
      // Load AbsenceCodeEditorComponent
      componentFactory = this.componentFactoryResolver.resolveComponentFactory(AbsenceCodeEditorComponent);
    } else if (tableNumber === 7) {
      // Load WorkNumberEditorComponent
      componentFactory = this.componentFactoryResolver.resolveComponentFactory(WorkNumberEditorComponent);
    } else {
      // Load TaBasetableEditorComponent
      componentFactory = this.componentFactoryResolver.resolveComponentFactory(TaBasetableEditorComponent);
    }

    if (componentFactory) {
      this.editorComponentRef = this.dynamicComponentContainer.createComponent(componentFactory);
      const instance = this.editorComponentRef.instance as EditorComponent; // Cast to EditorComponent

      // Handle different data types here, setting the tableData accordingly
      if (tableNumber === 5) {
        if (this.data.createNew) {
          instance.tableData = new AbsenceCode();
        } else {
          instance.tableData = { ...this.data.baseTable };
        }
      } else if (tableNumber === 7) {
        if (this.data.createNew) {
          instance.tableData = new WorkNumber();
        } else {
          instance.tableData = { ...this.data.baseTable };
        }
      } else {
        if (this.data.createNew) {
          instance.tableData = new BaseTable();
        } else {
          instance.tableData = { ...this.data.baseTable };
        }
      }
      instance.createNew = this.data.createNew;
      instance.tableNumber = this.data.tableNumber; // Ensure tableNumber is also set
      console.log('Loaded component instance:', instance);
      console.log('Assigned tableData:', instance.tableData, ' Number ', instance.tableNumber);

      // Call the method if it exists
      if (instance.updateTableData) {
        instance.updateTableData({
          previousValue: null,
          currentValue: instance.tableData,
          firstChange: true
        });
      }

      //this.isFormDirty = instance.isFormDirty;

      if (this.editorComponentRef) {
        const instance = this.editorComponentRef.instance as EditorComponent;
        // Subscribe to the isFormDirty$ observable from the child component
        if (instance.isFormDirty$) {
          instance.isFormDirty$.subscribe((isDirty: boolean) => {
            this.isFormDirty = isDirty;
            this.cdr.detectChanges(); // Ensure that the UI updates accordingly
          });
        }
      }

      // Subscribe to tableSaved$ if the component has it
      if (instance.tableSaved$) {
        instance.tableSaved$.subscribe((response: any) => {
          // Check if the response contains an error message
          if (response.message && response.message.includes('Failed')) {
            console.log('Ignoring error response:', response);
            return;
          }
          console.log('Received tableSaved response:', response);
          this.dialogRef.close(response); // Ensure response is an object with the necessary data
        });
      }
    }
  }
    
  

  // Save function to trigger the save method in the loaded component
  save() {
    if (this.editorComponentRef) {
      const editorInstance = this.editorComponentRef.instance as EditorComponent;
      if (editorInstance && typeof editorInstance.save === 'function') {
        editorInstance.save();
      }
    }
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

  get canManageTaData() {
    return true; 
  }

}
