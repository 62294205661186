
import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AppTranslationService } from '../../services/app-translation.service';
import { AlertService, DialogType } from '../../services/alert.service';
import { ParameterService } from "../../services/parameter.service";
import { AuthService } from '../../services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { Parameter, ParameterColumns } from '../../models/parameter.model';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { CircAdds } from '../../models/circadds.model';
import { ApiResult } from '../../base.service';

@Component({
  selector: 'parameterlist',
  templateUrl: './parameter-list.component.html',
  styleUrls: ['./parameter-list.component.scss']
})
export class ParameterListComponent implements OnInit, AfterViewInit {


  sectionids: CircAdds[] = [
    { circ_add_id: "History", description: this.translationService.getTranslation('parameter.History') },
    { circ_add_id: "WTDefinitions", description: this.translationService.getTranslation('parameter.WorkTime') },
    { circ_add_id: "General", description: this.translationService.getTranslation('parameter.General') },
    { circ_add_id: "User", description: this.translationService.getTranslation('parameter.User') },
    { circ_add_id: "Link", description: this.translationService.getTranslation('parameter.Link') }
  ];

  displayedColumns: string[] = ParameterColumns.map((col) => col.key);
  columnsSchema: any = ParameterColumns;
/*  public parameters: MatTableDataSource<Parameter>;*/
  sourceParameter: Parameter;
  public parameters = new MatTableDataSource<Parameter>();
  parameterdatas: Parameter[] = [];
  public show: boolean = true;
  defaultPageIndex: number = 0;
  defaultPageSize: number = 10;
  public defaultSortColumn: string = "value";
  public defaultSortOrder: string = "asc";
  defaultFilterColumn: string = "section";
  filterQuery: string = null;
  isEdit: boolean = false;
  valid: any = {};
  adding: boolean = false;
  @Input() isEditMode = true;
  parameterForm: FormGroup;
  sectionName: string;
  tableNumber: number = 0;
  numberOfRows: number = 0;
  rights: number = 0;
  writeRights: number = 0;
  writeRight: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  get parameter() {
    return this.parameterForm.get('parameter_id');
  }

  get data() {
    return this.parameterForm.get('data');
  }

  get floatLabels(): string {
    return this.isEditMode ? 'auto' : 'always';
  }

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private translationService: AppTranslationService,
    public parameterService: ParameterService,
    private fb: FormBuilder,
    public localStorage: LocalStoreManager
  ) {
    this.buildForm();
  }

  ngOnInit() {
    ParameterColumns[0].label = this.translationService.getTranslation('common.Name');
    ParameterColumns[1].label = this.translationService.getTranslation('common.Value');
    ParameterColumns[2].label = this.translationService.getTranslation('common.Descr');
    console.log(this.sectionids); // Check the state before using it
    this.rights = this.localStorage.getDataObject("readrights");
    this.writeRights = this.localStorage.getDataObject("userrights");
    this.writeRight = (this.writeRights & 16384) == 16384;

    const historySection = this.sectionids.find(sec => sec.circ_add_id === 'History');
    if (historySection) {
      this.selectedSection = historySection;
    }
    this.sectionName = this.selectedSection.circ_add_id;
    this.loadData(this.sectionName);
  }

  ngAfterViewInit() {
    this.parameters.paginator = this.paginator;
    this.parameters.sort = this.sort;
  }

  selectedSection = this.sectionids[0];

  toSectionId() {
    this.sectionName = this.selectedSection.circ_add_id;
    this.loadData(this.sectionName);
  }

  loadData(query: string = null) {
    var pageEvent = new PageEvent();
    pageEvent.pageIndex = this.defaultPageIndex;
    pageEvent.pageSize = this.defaultPageSize;
    if (query) {
      this.filterQuery = query;
    }
    else
      this.filterQuery = null;
    this.getData(pageEvent);
  }

  getData(event: PageEvent) {
    var sortColumn = (this.sort)
      ? this.sort.active
      : this.defaultSortColumn;
    var sortOrder = (this.sort)
      ? this.sort.direction
      : this.defaultSortOrder;
    var filterColumn = (this.filterQuery)
      ? this.defaultFilterColumn
      : null;
    var filterQuery = (this.filterQuery)
      ? this.filterQuery
      : null;
    this.parameterService.getData<ApiResult<Parameter>>(
      this.tableNumber,
      event.pageIndex,
      event.pageSize,
      sortColumn,
      sortOrder,
      filterColumn,
      filterQuery)
      .subscribe(result => {
        this.paginator.length = result.totalCount;
        this.paginator.pageIndex = result.pageIndex;
        this.paginator.pageSize = result.pageSize;
        this.parameters = new MatTableDataSource<Parameter>(result.data);
        this.parameterdatas = result.data;
        this.numberOfRows = this.paginator.length;
        if (this.numberOfRows == 0) {
          var mess = this.translationService.getTranslation('notifications.NoDataAvailable');
          this.alertService.errorMessage(mess);
        }
        this.authService.refreshLogin()
          .subscribe(data => {
            this.authService.refresh_userdata(data);
          },
            (error: any) => {
            });
      }, error => console.error(error));
  }


  private cancel() {
    this.resetForm();
  }

  private buildForm() {
    this.parameterForm = this.fb.group({
      section: ['', Validators.required]
    });
  }

  get canManageParameters() {
    return true;
  }

  private resetForm(stopEditing = false) {
    if (stopEditing) {
      this.isEditMode = false;
    }

    this.parameterForm.reset({

    });
  }

  //editCalendar(row: Parameter) {
  //  row.isEdit = true;
  //}

  editRow(row: Parameter) {
    let index = this.parameterdatas.findIndex(x => x.parameter_id === row.parameter_id)
    row.value = this.parameterdatas[index].value;
    this.parameterService.updateParameter(row)
      .subscribe(response => this.saveSuccessHelper(row),
        error => {
          if (error == "Unauthorized")
            this.authService.logout();
          else
            this.loadData(this.sectionName);
        });
  }

  private saveSuccessHelper(row) {
    let mess: string;
    row.isEdit = false;
    mess = this.translationService.getTranslation('notifications.UpdateRecordSucceeded');
    this.alertService.successMessage(mess);
  }

  inputHandler(e: any, id: number, key: string) {
    if (!this.valid[id]) {
      this.valid[id] = {}
    }
    this.valid[id][key] = e.target.validity.valid
  }

  disableSubmit(id: number) {
    if (this.valid[id]) {
      return Object.values(this.valid[id]).some((item) => item === false)
    }
    return false
  }

}
