export class AbsenceCode {
  number: number;
  title: string;
  infotext: string;
  description: string; // char 40
  reasoncode_type: number;
  infoReasoncode: number ;
  long_term: boolean;
  no_eff_on_info: boolean ;
  advance_booking: boolean ;
  no_ct600_returntime: boolean;
  not_allowed: boolean ;
  extended_calctm: boolean ;
  not_allowed_in_client: boolean;
  acc_to_flex_saldo: boolean ;
  acc_to_def_job: boolean ;
  acc_to_job: boolean ;
  acc_to_circ_add: boolean ;
  acc_to_worktime: boolean ;
  no_eff_on_overtime: boolean ;
  hide_worktime: boolean ;
  overtimeAsVacation: boolean ;
  accept_level: number ;
  acc_adds_to_saldo: number ;
  acc_decs_from_saldo: number ;
  calc_rule_id: number;
  extra_reasoncode_id: number;
  vac_sat_reasoncode_id: number;
  vac_sun_reasoncode_id: number ;
  //Default_time: number ;
  //Enquiry_rc_type: number ;
  //Enquiry_saldo: number ;
  //Dlyprog_seltype: number ;
  //SaldoMultiplier: number ;
  norm_work: boolean ;
  transfer_system1: boolean ;
  transfer_system2: boolean;
  salarytype_id: number ;
  withExtraData: number;
  tableNumber: number;
}
