import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProjectComponent } from './project.component';
import { ProjectListComponent } from './project-list/project-list.component';
import { ProjectPersonListComponent } from './projectperson-list/projectperson-list.component';
import { ProjectEnterComponent } from './project-enter/project-enter.component';
import { ProjectReportComponent } from './projectreport/projectreport.component';
import { AuthService } from '../services/auth.service';
import { AuthGuard } from '../services/auth-guard.service';

const projectRoutes: Routes = [
  {
    path: 'project', component: ProjectComponent,
    children: [
      { path: 'projects', component: ProjectListComponent, canActivate: [AuthGuard] },
      { path: 'projectpersons', component: ProjectPersonListComponent, canActivate: [AuthGuard] },
      { path: 'projectenter', component: ProjectEnterComponent, canActivate: [AuthGuard] },
      { path: 'projectreport', component: ProjectReportComponent, canActivate: [AuthGuard] }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(projectRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: [
    AuthService, AuthGuard
  ]
})
export class ProjectRoutingModule { }
