import { Substitute } from "./substitute.model"

export class PersonUser {
  username: string;
  password: string;
  userTypeId: number;
  employeeId: number;
  startDate: string;
  endDate: string;
  text: string;
  lastChange: string;
  changemadeby: string;
  noWsUse: boolean;
  rightsEndDate: string;
  releases: Substitute[]; // Array of Substitute instances
  obtainers: Substitute[]; // Array of Substitute instances
}
