<form #form="ngForm" [formGroup]="employeeTaForm" fxLayout="column" [fxLayoutGap]="isEditMode ? '1em' : '0'" autocomplete="off">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <input type="hidden" formControlName="employeeId">
    <input type="hidden" formControlName="employee_id">
    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'employee.Surname' | translate}}</mat-label>
      <input matInput formControlName="surname" maxlength="30" readonly />
    </mat-form-field>
    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'employee.Forename' | translate}}</mat-label>
      <input matInput formControlName="forename" maxlength="20" readonly />
    </mat-form-field>
    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'employee.EmployeeNo' | translate}}</mat-label>
      <input matInput formControlName="employeeNo" maxlength="12" readonly />
    </mat-form-field>
  </div>
  <mat-grid-list cols="3" rowHeight="450px" gutterSize="20px">
    <mat-grid-tile>
      <div class="w-100" fxLayout="column" fxLayoutAlign="space-evenly none">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
          <mat-form-field [floatLabel]="floatLabels" fxFlex.gt-sm="0 1 calc(50% - 0.5em)">
            <mat-label>{{'employee.EmplStart' | translate}}</mat-label>
            <input matInput formControlName="emplstart" [matDatepicker]="picker1" (click)="picker1.open()" />
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>

          <mat-form-field [floatLabel]="floatLabels" fxFlex.gt-sm="0 1 calc(50% - 0.5em)">
            <mat-label>{{'employee.EmplEnd' | translate}}</mat-label>
            <input matInput formControlName="emplend" [matDatepicker]="picker2" (click)="picker2.open()" />
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
        <mat-form-field>
          <mat-label>{{'employee.MobileTelephone' | translate}}</mat-label>
          <input matInput formControlName="mobileTelephone" />
          <mat-error *ngIf="employeeTaForm.get('mobileTelephone').errors?.maxlength">{{'notifications.MaximumLength30' | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'employee.Email' | translate}}</mat-label>
          <input matInput formControlName="email"/>
        </mat-form-field>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
          <mat-form-field>
            <mat-label>{{'employee.GuserTypeId' | translate}}</mat-label>
            <mat-select formControlName="guserTypeId">
              <mat-option [value]="">{{'helps.NoValue' | translate}}</mat-option>
              <mat-option *ngFor="let generalUser of generalUsers" [value]="generalUser.user_type_id">
                {{ generalUser.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'employee.GuserPwd' | translate}}</mat-label>
            <input matInput formControlName="guserPwd" (click)="openPasswordDialog()" type="password" readonly />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
          <mat-form-field>
            <mat-label>{{'employee.PremiVisor' | translate}}</mat-label>
            <mat-select formControlName="premivisor">
              <mat-option *ngFor="let yesno of yesnos" [value]="yesno.id">
                {{ yesno.descr }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'employee.Position' | translate}}</mat-label>
            <mat-select formControlName="position">
              <mat-option *ngFor="let yesno of yesnos" [value]="yesno.id">
                {{ yesno.descr }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
          <mat-form-field>
            <mat-label>{{'common.LastChange' | translate}}</mat-label>
            <input matInput formControlName="lastChange" readonly />
          </mat-form-field>
          <mat-form-field>
            <input matInput formControlName="changemadeby" readonly />
          </mat-form-field>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="w-100" fxLayout="column" fxLayoutAlign="space-evenly none">

        <mat-form-field>
          <mat-label>{{'employee.Gender' | translate}}</mat-label>
          <mat-select formControlName="gender">
            <mat-option [value]="">{{'helps.NoValue' | translate}}</mat-option>
            <mat-option *ngFor="let genderRow of genders" [value]="genderRow.itemNumber">
              {{ genderRow.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'employee.UserLanguage' | translate}}</mat-label>
          <mat-select formControlName="userlanguage">
            <mat-option [value]="">{{'helps.NoValue' | translate}}</mat-option>
            <mat-option *ngFor="let ul of userlanguages" [value]="ul.lang">
              {{ ul.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'employee.EmployeeGroup' | translate}}</mat-label>
          <mat-select formControlName="employeegroup">
            <mat-option [value]="">{{'helps.NoValue' | translate}}</mat-option>
            <mat-option *ngFor="let eg of employeegroups" [value]="eg.employee_grp_id">
              {{ eg.descr }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'employee.Profession' | translate}}</mat-label>
          <mat-select formControlName="professionId">
            <mat-option [value]="">{{'helps.NoValue' | translate}}</mat-option>
            <mat-option *ngFor="let prof of professions" [value]="prof.profession_id">
              {{ prof.name }} {{ prof.descr }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'employee.Occupation' | translate}}</mat-label>
          <input matInput formControlName="occupation"/>
          <mat-error *ngIf="employeeTaForm.get('occupation').errors?.maxlength">{{'notifications.MaximumLength40' | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'employee.WorkingPos' | translate}}</mat-label>
          <mat-select formControlName="workingpos">
            <mat-option [value]="">{{'helps.NoValue' | translate}}</mat-option>
            <mat-option *ngFor="let wp of workingposs" [value]="wp.working_pos_id">
              {{ wp.descr }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="w-100" fxLayout="column" fxLayoutAlign="space-evenly none">
        <mat-form-field>
          <mat-label>{{'employee.PersonCode' | translate}}</mat-label>
          <input matInput formControlName="add_employee_no" />
          <mat-error *ngIf="employeeTaForm.get('add_employee_no').errors?.maxlength">{{'notifications.MaximumLength8' | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'employee.AcceptLevel' | translate}}</mat-label>
          <mat-select formControlName="acceptlevel">
            <mat-option [value]="">{{'helps.NoValue' | translate}}</mat-option>
            <mat-option *ngFor="let al of acceptlevels" [value]="al.acceptlevel_id">
              {{ al.acceptlevel_id }} {{ al.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'employee.SalarysysUser' | translate}}</mat-label>
          <input matInput formControlName="salarysys_user" />
          <mat-error *ngIf="employeeTaForm.get('salarysys_user').errors?.maxlength">{{'notifications.MaximumLength15' | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'employee.LunchGroup' | translate}}</mat-label>
          <mat-select formControlName="lunchgroup">
            <mat-option [value]="">{{'helps.NoValue' | translate}}</mat-option>
            <mat-option *ngFor="let sg of suppliergroups" [value]="sg.suppliergroup_id">
              {{ sg.suppliergroup_id }} {{ sg.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'employee.LunchDisable' | translate}}</mat-label>
          <mat-select formControlName="lunchdisable">
            <mat-option *ngFor="let yesno of yesnos" [value]="yesno.id">
              {{ yesno.descr }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'employee.WorktimeData' | translate}}</mat-label>
          <input matInput formControlName="worktime_data" />
          <mat-error *ngIf="employeeTaForm.get('worktime_data').errors?.maxlength">{{'notifications.MaximumLength40' | translate}}</mat-error>
        </mat-form-field>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</form>

