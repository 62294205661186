<div style="margin-left: 20px; margin-right: 20px;">
  <h1 mat-dialog-title>{{'usertype.AccessRights' | translate}}</h1>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field fxFlex="100%">
      <mat-label>{{'usertype.CompanyHierarchy' | translate}}</mat-label>
      <input matInput [(ngModel)]="companyHierarchy" maxlength="128" />
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-checkbox [(ngModel)]="disableAutoRights">{{'usertype.DisableAutoRights' | translate}}</mat-checkbox>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-checkbox [(ngModel)]="enableOwnData">{{'usertype.EnableOwnData' | translate}}</mat-checkbox>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field fxFlex="100%">
      <mat-label>{{'usertype.ReportAccess' | translate}}</mat-label>
      <input matInput [(ngModel)]="reportAccess" maxlength="128" />
    </mat-form-field>
  </div>
  <div mat-dialog-actions style="display: flex; justify-content: center; align-items: center; gap: 20px;">
    <button mat-raised-button (click)="save()" color="primary">{{ 'common.Save' | translate }}</button>
    <button mat-raised-button (click)="cancel()" color="warn">{{ 'common.Cancel' | translate }}</button>
  </div>
</div>
