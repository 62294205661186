<!--<head>
  <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.13.0/css/all.css">
</head>-->
<div class="container">
  <h3>{{'menu.ProjectEnter' | translate}}</h3>
  <div class="form-group" *ngIf="guser">
    <input type="text" class="form-control" [(ngModel)]="personname" name="personname" readonly>
  </div>
  <div class="form-group" *ngIf="!guser">
    <div class="row">
      <div class="col-1">
        <button class="btn pull-left" (click)="previousname()" [disabled]="(personIndex<=0)"><i class="fa fa-angle-left"></i></button>
      </div>
      <div class="col-10">
        <ng-select [items]="persons"
                   bindLabel="name"
                   (close)="changePerson($event)"
                   placeholder={{selectNameStr}}
                   [(ngModel)]="selectedPerson">
        </ng-select>
      </div>
      <div class="col-1">
        <button class="btn pull-right" (click)="nextname()" [disabled]="(!guser && !selectedPerson)"><i class="fa fa-angle-right"></i></button>
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="row">
      <div class="col-6">
        <my-date-picker name="startdate" [options]="myDatePickerOptions" [locale]="locale" [(ngModel)]="startDate" required #dateField="ngModel" (dateChanged)="onDateChanged($event)"></my-date-picker>
      </div>
      <div class="col-6">
        <button class="btn" (click)="previousday()" [disabled]="(!guser && !selectedPerson)"><span class="fa fa-angle-left" aria-hidden="true"></span></button>
        <button class="btn" (click)="fetchstamps()" [disabled]="(!guser && !selectedPerson)"><strong>{{'common.GetStamps' | translate}}</strong></button>
        <button class="btn" (click)="nextday()" [disabled]="((!guser && !selectedPerson) ||!allowdate)"><span class="fa fa-angle-right" aria-hidden="true"></span></button>
      </div>
    </div>
  </div>

  <div class="table-striped mat-elevation-z8" >
    <mat-table [dataSource]="weekdatas">
      <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef> {{''}} </mat-header-cell>
        <mat-cell *matCellDef="let weekdata"> {{weekdata.title}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="monData">
        <mat-header-cell *matHeaderCellDef> {{''}} </mat-header-cell>
        <mat-cell *matCellDef="let weekdata"> {{weekdata.monData}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="tueData">
        <mat-header-cell *matHeaderCellDef> {{''}} </mat-header-cell>
        <mat-cell *matCellDef="let weekdata"> {{weekdata.tueData}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="wedData">
        <mat-header-cell *matHeaderCellDef> {{''}} </mat-header-cell>
        <mat-cell *matCellDef="let weekdata"> {{weekdata.wedData}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="thuData">
        <mat-header-cell *matHeaderCellDef> {{''}} </mat-header-cell>
        <mat-cell *matCellDef="let weekdata"> {{weekdata.thuData}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="friData">
        <mat-header-cell *matHeaderCellDef> {{''}} </mat-header-cell>
        <mat-cell *matCellDef="let weekdata"> {{weekdata.friData}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="satData">
        <mat-header-cell *matHeaderCellDef> {{''}} </mat-header-cell>
        <mat-cell *matCellDef="let weekdata"> {{weekdata.satData}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="sunData">
        <mat-header-cell *matHeaderCellDef> {{''}} </mat-header-cell>
        <mat-cell *matCellDef="let weekdata"> {{weekdata.sunData}} </mat-cell>
      </ng-container>

      <mat-header-row style="display: none;" *matHeaderRowDef="displayedColumns1"></mat-header-row>
      <mat-row [ngClass]="{'make-disable': true}" *matRowDef="let row; columns: displayedColumns1;">
      </mat-row>
    </mat-table>
  </div>
  <p [innerHTML]=" "></p>
  <div class="table-striped mat-elevation-z8" >
    <mat-table [dataSource]="projectdatas">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> {{''}} </mat-header-cell>
        <mat-cell *matCellDef="let projectdata"> {{projectdata.name}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="monData">
        <mat-header-cell *matHeaderCellDef > {{''}} </mat-header-cell>
        <mat-cell [matTooltip]="projectdata.monComment" *matCellDef="let projectdata; let cellIndex = index;">
          <div *ngIf="projectdata.monData[0] < 58 ; else elseBlock">
              <input matInput [value]="projectdata.monData" (focusout)="setMonData($event.target.value, cellIndex)" (focusin)="setRow(cellIndex, 1)" />
          </div>
          <ng-template #elseBlock> {{projectdata.monData}} </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="tueData">
        <mat-header-cell *matHeaderCellDef> {{''}} </mat-header-cell>
        <mat-cell [matTooltip]="projectdata.tueComment" *matCellDef="let projectdata; let cellIndex = index;">
          <div *ngIf="projectdata.tueData[0] < 58 ; else elseBlock">
            <input matInput [value]="projectdata.tueData" (focusout)="setTueData($event.target.value, cellIndex)" (focusin)="setRow(cellIndex, 2)" />
          </div>
          <ng-template #elseBlock> {{projectdata.tueData}} </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="wedData">
        <mat-header-cell *matHeaderCellDef> {{''}} </mat-header-cell>
        <mat-cell [matTooltip]="projectdata.wedComment" *matCellDef="let projectdata; let cellIndex = index;">
          <div *ngIf="projectdata.wedData[0] < 58 ; else elseBlock">
            <input matInput [value]="projectdata.wedData" (focusout)="setWedData($event.target.value, cellIndex)" (focusin)="setRow(cellIndex, 3)" />
          </div>
          <ng-template #elseBlock> {{projectdata.wedData}} </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="thuData">
        <mat-header-cell *matHeaderCellDef> {{''}} </mat-header-cell>
        <mat-cell [matTooltip]="projectdata.thuComment" *matCellDef="let projectdata; let cellIndex = index;">
          <div *ngIf="projectdata.thuData[0] < 58 ; else elseBlock">
            <input matInput [value]="projectdata.thuData" (focusout)="setThuData($event.target.value, cellIndex)" (focusin)="setRow(cellIndex, 4)" />
          </div>
          <ng-template #elseBlock> {{projectdata.thuData}} </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="friData">
        <mat-header-cell *matHeaderCellDef> {{''}} </mat-header-cell>
        <mat-cell [matTooltip]="projectdata.friComment" *matCellDef="let projectdata; let cellIndex = index;">
          <div *ngIf="projectdata.friData[0] < 58 ; else elseBlock">
            <input matInput [value]="projectdata.friData" (focusout)="setFriData($event.target.value, cellIndex)" (focusin)="setRow(cellIndex, 5)" />
          </div>
          <ng-template #elseBlock> {{projectdata.friData}} </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="satData">
        <mat-header-cell *matHeaderCellDef> {{''}} </mat-header-cell>
        <mat-cell [matTooltip]="projectdata.satComment" *matCellDef="let projectdata; let cellIndex = index;">
          <div *ngIf="projectdata.satData[0] < 58 ; else elseBlock">
            <input matInput [value]="projectdata.satData" (focusout)="setSatData($event.target.value, cellIndex)" (focusin)="setRow(cellIndex, 6)" />
          </div>
          <ng-template #elseBlock> {{projectdata.satData}} </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="sunData">
        <mat-header-cell *matHeaderCellDef> {{''}} </mat-header-cell>
        <mat-cell [matTooltip]="projectdata.sunComment" *matCellDef="let projectdata; let cellIndex = index;">
          <div *ngIf="projectdata.sunData[0] < 58 ; else elseBlock">
            <input matInput [value]="projectdata.sunData" (focusout)="setSunData($event.target.value, cellIndex)" (focusin)="setRow(cellIndex, 7)" />
          </div>
          <ng-template #elseBlock> {{projectdata.sunData}} </ng-template>
        </mat-cell>
      </ng-container>

      <mat-header-row style="display: none;" *matHeaderRowDef="displayedColumns2"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns2" (click)="getRecord(row)">
      </mat-row>
    </mat-table>
  </div>
</div>

