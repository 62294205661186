import { Component, Input, OnInit, ViewChild, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AppTranslationService } from '../../services/app-translation.service';
import { AlertService, DialogType } from '../../services/alert.service';
import { PersonNameService } from "../../services/personname.service";
import { SiteService } from '../../services/site.service';
import { Employee } from '../../models/employee.model';
import { PersonUser } from '../../models/personuser.model';
import { PersonNameId } from '../../models/personidname.model';
import { ChangePassword } from '../../persons/persons-list/setpassword.component';
import { SetPassword } from '../../models/setpassword.model';
import { UserType } from '../../models/usertype.model';
import { ReleasedRightsColumns, ObtainedRightsColumns, Substitute } from '../../models/substitute.model';
import { AuthService } from '../../services/auth.service';
import { PersonUserService } from '../../services/personuser.service';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { YesNo } from '../../models/yesno.model';
import { UserTypeService } from '../../services/usertype.service';
import { SubstituteService } from '../../services/substitute.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'personuser',
  templateUrl: './personuser.component.html',
  styleUrls: ['./personuser.component.scss']
})
export class PersonUserComponent implements OnInit, OnChanges {
  @ViewChild('form', { static: true })
  //private form: NgForm;

  public isNewPersonUser = false;
  personUsers: PersonUser[] = [];
  personuser: PersonUser = null;
  userTypes: UserType[] = [];
  personidnames: PersonNameId[] = [];
  setPasswd = new (SetPassword);
  employees: Employee[] = [];
  filterQuery: string = null;
  currentIndex:number = 0; // Starts from the first record
  lastIndex = 0;
  yesnos: YesNo[] = [
    { id: 1, descr: this.translationService.getTranslation('common.No') },
    { id: 2, descr: this.translationService.getTranslation('common.Yes') }
  ];
  displayedColumns1: string[] = ReleasedRightsColumns.map((col) => col.key);
  columnsSchema1: any = ReleasedRightsColumns;
  displayedColumns2: string[] = ObtainedRightsColumns.map((col) => col.key);
  columnsSchema2: any = ObtainedRightsColumns;
  dataSource1 = new MatTableDataSource<Substitute>();
  dataSource2 = new MatTableDataSource<Substitute>();
  adding: boolean = false;
  valid: any = {};
  rights: number = 0;
  writeRights: number = 0;
  writeRight: boolean = false;

  @Input() isEditMode = true;

  searchForm: FormGroup;
  personUserForm: FormGroup;
  get searchName() {
      return this.searchForm.get('searchName');
  }
  get username() {
    return this.personUserForm.get('username');
  }

  get employeeId() {
    return this.personUserForm.get('employeeId');
  }
  get userTypeId() {
    return this.personUserForm.get('userTypeId');
  }
  
  get floatLabels(): string {
    return this.isEditMode ? 'auto' : 'always';
  }


  constructor(
    public dialog: MatDialog,
    private siteService: SiteService,
    private alertService: AlertService,
    private authService: AuthService,
    private personUserService: PersonUserService,
    private userTypeService: UserTypeService,
    public personNameService: PersonNameService,
    private translationService: AppTranslationService,
    private substituteService: SubstituteService,
    private formBuilder: FormBuilder,
    public localStorage: LocalStoreManager
  ) {
    this.searchForm = this.formBuilder.group({
      searchName: [''], // Initialize your search field
    });
    this.buildForm();
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.personNameService.getpersonidnames()
      .subscribe(results => {
        this.personidnames = results;
      },
        (error: any) => {
          console.log(error);
        });
    this.loadData();
    this.userTypeService.getUserTypes("null")
      .subscribe(data => {
        this.userTypes = data;
      },
        (error: any) => {
          console.log(error);
        });

    ReleasedRightsColumns[0].label = this.translationService.getTranslation('personuser.Releaser');
    ReleasedRightsColumns[1].label = this.translationService.getTranslation('persons.StartDate');
    ReleasedRightsColumns[2].label = this.translationService.getTranslation('persons.EndDate');
    ObtainedRightsColumns[0].label = this.translationService.getTranslation('personuser.Obtainer');
    ObtainedRightsColumns[1].label = this.translationService.getTranslation('persons.StartDate');
    ObtainedRightsColumns[2].label = this.translationService.getTranslation('persons.EndDate');

    this.rights = this.localStorage.getDataObject("readrights");
    this.writeRights = this.localStorage.getDataObject("userrights");
    this.writeRight = (this.writeRights & 16384) == 16384;
    //this.personUserService.getPersonUsers(null)
    //  .subscribe(data => {
    //    this.personUsers = data;
    //  },
    //    (error: any) => {
    //      //console.log(error);
    //    });

  }

  clearSearchField(): void {
    this.searchForm.get('searchName').setValue(''); // This sets the searchName control to an empty string
  }


  //loadNameData() {
  //  this.filterQuery = this.searchForm.get('searchName').value === '' ? null : this.searchForm.get('searchName').value;
  //  this.loadData(this.filterQuery);
  //}

  loadData() {
    this.filterQuery = this.searchForm.get('searchName').value === '' ? null : this.searchForm.get('searchName').value;
    this.personUserService.getPersonUsers(this.filterQuery)
      .subscribe(data => {
        this.personUsers = data;
        if (this.personUsers.length > 0) {
          //this.currentIndex = 0; // Reset to first item
          this.personuser = this.personUsers[this.currentIndex];
          this.lastIndex = this.personUsers.length - 1;
          this.dataSource1.data = this.personUsers[this.currentIndex].releases;
          this.dataSource2.data = this.personUsers[this.currentIndex].obtainers;
          this.updateFormValues();
        }
        else {
          var mess = this.translationService.getTranslation('notifications.NoDataAvailable');
          this.alertService.errorMessage(mess);
        }
        this.authService.refreshLogin()
          .subscribe(data => {
            this.authService.refresh_userdata(data);
          },
            (error: any) => {
            });
      },
        (error: any) => {
          //console.log(error);
        });
  }

  showNextRecord() {
    if (this.currentIndex < this.lastIndex) {
      this.currentIndex++;
      this.personuser = this.personUsers[this.currentIndex];
      this.updateFormValues();
    }
  }

  showPreviousRecord() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.personuser = this.personUsers[this.currentIndex];
      this.updateFormValues();
    }
  }

  showFirstRecord() {
    if (this.currentIndex > 0) {
      this.currentIndex = 0;
      this.personuser = this.personUsers[this.currentIndex];
      this.updateFormValues();
    }
  }

  showLastRecord() {
    if (this.currentIndex < this.lastIndex) {
      this.currentIndex = this.lastIndex;
      this.personuser = this.personUsers[this.currentIndex];
      this.updateFormValues();
    }
  }

  updateFormValues() {
    if (this.isNewPersonUser) {
      this.resetForm();
    } else {
      const currentUser = this.personUsers[this.currentIndex];
      this.personUserForm.patchValue({
        searchName: null,
        username: currentUser.username,
        password: currentUser.password,
        employeeId: currentUser.employeeId,
        userTypeId: currentUser.userTypeId,
        startDate: currentUser.startDate,
        endDate: currentUser.endDate,
        noWsUse: currentUser.noWsUse ? 2 : 1 || '1',
        rightsEndDate: currentUser.rightsEndDate,
        lastChange: currentUser.lastChange,
        changemadeby: currentUser.changemadeby,
        text: currentUser.text
      });
      this.dataSource1.data = currentUser.releases || [];
      this.dataSource2.data = currentUser.obtainers || [];
    }
  }


  ngOnChanges() {
    //if (this.employee) {
    //  this.isNewPersonUser = false;
    //} else {
    //  this.isNewPersonUser = true;
    //  this.employee = new Employee();
    //}
    this.resetForm();
  }

  //onFilterTextChanged(filterText: string) {
  //  if (this.filterTextChanged.observers.length === 0) {
  //    this.filterTextChanged
  //      .pipe(debounceTime(1000), distinctUntilChanged())
  //      .subscribe(query => {
  //        this.loadData(query);
  //      });
  //  }
  //  this.filterTextChanged.next(filterText);
  //}

  createPersonUser() {
    this.isNewPersonUser = true; // Set the flag to indicate creating a new PersonUser
    this.personuser = new PersonUser(); // Create a new PersonUser object
    this.resetForm(); // Reset the form values
    this.clearFormFields();
    this.updateFormValues(); // Update form values based on the new personuser object
    this.currentIndex = 0;
    //this.personUserService.newPersonUser(this.personuser);
  }

  public savePersonUser() {
    if (!this.personUserForm.valid) {
      this.alertService.errorMessage("Validation error");
      return;
    }
    var editedPersonUser = this.getEditedPersonUser();
    this.personUserForm.markAsPristine();

    if (this.isNewPersonUser) {
      this.personUserService.newPersonUser(editedPersonUser)
        .subscribe(id => {
          this.saveSuccessHelper(editedPersonUser)
          this.isNewPersonUser = false;
        },
          error => {
            if (error == "Unauthorized")
              this.authService.logout();
            else
              this.saveFailedHelper(error);
          });
    }
    this.searchForm.get('searchName').setValue(editedPersonUser.username);
    this.personUserService.updatePersonUser(editedPersonUser).subscribe(
      response => this.saveSuccessHelper(editedPersonUser),
      error => {
        if (error == "Unauthorized")
          this.authService.logout();
        else
          this.saveFailedHelper(error);
      });
    this.clearSearchField();
  }

  onInputChange(field: string, event: Event) {
    const value = (event.target as HTMLInputElement).value;
    console.log("OnInputChange F " + field + " V " + value);
    this.personUserForm.markAsDirty();
  }

  private getEditedPersonUser(): PersonUser {
    const formModel = this.personUserForm.value;
    return {
      employeeId: formModel.employeeId,
      username: formModel.username,
      password: formModel.password,
      startDate: formModel.startDate,
      endDate: formModel.endDate,
      text: formModel.text,
      userTypeId: formModel.userTypeId,
      noWsUse: (formModel.noWsUse == 2) ? true : false,
      rightsEndDate: formModel.rightsEndDate,
      lastChange: formModel.lastChange,
      changemadeby: formModel.changemadeby,
      releases: null,
      obtainers: null
    };
  }

  private saveSuccessHelper(personuser?: PersonUser) {
    let mess: string;
    this.loadData();
    if (this.isNewPersonUser) {
      mess = this.translationService.getTranslation('notifications.InsertRecordSucceeded');
    } else {
    mess = this.translationService.getTranslation('notifications.UpdateRecordSucceeded');
    }
    this.alertService.successMessage(mess);
  }

  private saveFailedHelper(personuser?: PersonUser) {
  }

  private buildForm() {
    this.personUserForm = this.formBuilder.group({
      searchName: null,
      employeeId: null,
      username: ['', Validators.required],
      password: null,
      userTypeId:null,
      startDate: null,
      endDate: null,
      text: null,
      noWsUse: null,
      rightsEndDate: null,
      lastChange: null,
      changemadeby: null,
      releases: null,
      obtainers: null
    });
  }

  private resetForm(stopEditing = false) {
    if (stopEditing) {
      this.isEditMode = false;
    }
    this.clearFormFields();
    this.personUserForm.reset({
      employeeId: '',
      username: '',
      password: '',
      startDate: null,
      endDate: null,
      userTypeId: null,
      noWsUse: '1', // Default to 'No' if applicable
      rightsEndDate: null,
      lastChange: null,
      changemadeby: '',
      text: null,
      releases: null,
      obtainers: null
    });

  }

  private clearFormFields() {
    this.dataSource1.data = this.dataSource1.data.map(item => ({
      ...item,
      username: ''
    }));
  this.dataSource1.data = [];
  this.dataSource2.data = [];
      //startdate: null,
      //enddate: null

  }

  openPasswordDialog(): void {
    const dialogConfig2 = new MatDialogConfig();
    dialogConfig2.disableClose = true;
    dialogConfig2.autoFocus = true;
    dialogConfig2.height = '300px';
    dialogConfig2.width = '300px';
    dialogConfig2.data = {
      employee_id: null
    };
    const dialogRef = this.dialog.open(ChangePassword, dialogConfig2);

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.password1 && result.password2) {
        // Handle the case where you have valid password1 and password2
        this.setPasswd.newpasswd = result.password1;
        this.setPasswd.oldpasswd = result.password2;
      }
      else {
        // Handle the case where password fields should be cleared
        this.setPasswd.newpasswd = null;
      }
      this.setPasswd.onlyCheck = true;
      this.siteService.updatePassword(this.setPasswd, true)
        .subscribe(data => {
          this.handlePassword(data);
        });
    });
  }


  private handlePassword(data: string) {
    if (Array.from(data)[0] == '=') {
      this.personuser.password = data.substring(1);
      this.personUserForm.controls['password'].markAsDirty();
      this.personUserForm.patchValue({
        password: this.personuser.password,
      })
    }
    else if (Array.from(data)[0] == '+') {
      this.alertService.success(data.substring(1));
    }
    else
      this.alertService.error(data);
  }

  public confirmDelete(personUser: PersonUser) {
    let delQ = this.translationService.getTranslation('notifications.DeleteRecord');
    this.alertService.showDialog(delQ + '?',
      DialogType.confirm, () => this.deletePersonUser(personUser));
  }

  deletePersonUser(personUser: PersonUser) {
    this.personUserService.deletePersonUser(personUser.username)
      .subscribe(results => {
        var mess = this.translationService.getTranslation('notifications.DeleteRecordSucceeded');
        this.alertService.successMessage(mess);
        this.loadData();
      },
        error => {
        });
  }

  editRow(row: Substitute) {
    this.adding = false;
    let index = this.personUsers.findIndex(x => x.username === row.substitute);
    row.username = this.personuser.username;
    if (row.id === -1) {
      this.substituteService.addSubstitute(row)
        .subscribe(response => {
          this.alertService.successMessage(this.translationService.getTranslation('notifications.CreateOk'));
          this.loadData();
        },
          error => {
            if (error == "Unauthorized")
              this.authService.logout();
            else
              this.loadData();
          });
    }
    else {
      this.personUsers[row.id].username = this.personUsers[index].username;
      this.substituteService.updateSubstitute(row)
        .subscribe(response => {
          this.alertService.successMessage(this.translationService.getTranslation('notifications.SaveOk'));
          this.loadData();
        },
          error => {
            if (error == "Unauthorized")
              this.authService.logout();
            else
              this.loadData();
          });
    }
  }


  addRow() {
    this.adding = true;
    const newRow: Substitute = {
      id: -1,
      username:null,
      substitute: null,
      startDate: null,
      endDate: null,
      isEdit: true
    }
    this.dataSource1.data = [newRow, ...this.dataSource1.data]
  }

  inputHandler(e: any, id: number, key: string) {
    if (!this.valid[id]) {
      this.valid[id] = {}
    }
    this.valid[id][key] = e.target.validity.valid
  }

  disableSubmit(id: number) {
    if (this.valid[id]) {
      return Object.values(this.valid[id]).some((item) => item === false)
    }
    return false
  }

  confirmDeleteSubstitute(row: Substitute) {
    this.substituteService.deleteSubstitute(this.username.value, row.substitute )
      .subscribe(results => {
        this.alertService.successMessage(this.translationService.getTranslation('notifications.deleteok'));
        this.loadData();
      },
        error => {
        });
  }

  cancelCalendar() {
    //this.matEditOpen = false;
    /*    this.personCal = null;*/
  }

  cancel(): void {
    this.isNewPersonUser = false; 
    this.resetForm();
    this.personuser = this.personUsers[this.currentIndex];
    this.updateFormValues();
    this.loadData();
  }

  get canManagePersonUsers() {
    return true; //this.accountService.userHasPermission(Permission.manageRolesPermission);
  }
}

 


