import { Component, Input, OnInit, ViewChild, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AppTranslationService } from '../../services/app-translation.service';
import { AlertService, DialogType } from '../../services/alert.service';
import { Device } from '../../models/device.model';
import { AuthService } from '../../services/auth.service';
import { DeviceService } from '../../services/device.service';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
export class DeviceComponent implements OnInit, OnChanges {
  @ViewChild('form', { static: true })
  //private form: NgForm;

  public isNewDevice = false;
  devices: Device[] = [];
  device: Device = null;
  filterQuery: string = null;
  currentIndex: number = 0; // Starts from the first record
  lastIndex = 0;
  adding: boolean = false;
  valid: any = {};
  rights: number = 0;
  writeRights: number = 0;
  writeRight: boolean = false;
  devIsTablet: boolean = false;

  @Input() isEditMode = true;

  searchForm: FormGroup;
  deviceForm: FormGroup;
  get searchName() {
    return this.searchForm.get('searchName');
  }
  get floatLabels(): string {
    return this.isEditMode ? 'auto' : 'always';
  }

  constructor(
    public dialog: MatDialog,
    private alertService: AlertService,
    private authService: AuthService,
    private deviceService: DeviceService,
    private translationService: AppTranslationService,
    private formBuilder: FormBuilder,
    public localStorage: LocalStoreManager
  ) {
    this.searchForm = this.formBuilder.group({
      searchName: [''], // Initialize your search field
    });
    this.buildForm();
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.loadData();
    this.rights = this.localStorage.getDataObject("readrights");
    this.writeRights = this.localStorage.getDataObject("userrights");
    this.writeRight = (this.writeRights & 16384) == 16384;
  }

  clearSearchField(): void {
    this.searchForm.get('searchName').setValue(''); // This sets the searchName control to an empty string
  }

  loadData() {
    this.filterQuery = this.searchForm.get('searchName').value === '' ? null : this.searchForm.get('searchName').value;
    this.deviceService.getDevices(this.filterQuery)
      .subscribe(data => {
        this.devices = data;
        if (this.devices.length > 0) {
          //this.currentIndex = 0; // Reset to first item
          this.device = this.devices[this.currentIndex];
          this.lastIndex = this.devices.length - 1;
          this.updateFormValues();
        }
        else {
          var mess = this.translationService.getTranslation('notifications.NoDataAvailable');
          this.alertService.errorMessage(mess);
        }
        this.authService.refreshLogin()
          .subscribe(data => {
            this.authService.refresh_userdata(data);
          },
            (error: any) => {
            });
      },
        (error: any) => {
          //console.log(error);
        });
  }

  showNextRecord() {
    if (this.currentIndex < this.lastIndex) {
      this.currentIndex++;
      this.device = this.devices[this.currentIndex];
      this.updateFormValues();
    }
  }

  showPreviousRecord() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.device = this.devices[this.currentIndex];
      this.updateFormValues();
    }
  }

  showFirstRecord() {
    if (this.currentIndex > 0) {
      this.currentIndex = 0;
      this.device = this.devices[this.currentIndex];
      this.updateFormValues();
    }
  }

  showLastRecord() {
    if (this.currentIndex < this.lastIndex) {
      this.currentIndex = this.lastIndex;
      this.device = this.devices[this.currentIndex];
      this.updateFormValues();
    }
  }

  updateFormValues() {
    if (this.isNewDevice) {
      this.resetForm();
    } else {
      const currentDevice = this.devices[this.currentIndex];
      this.devIsTablet = this.device.isTablet;
      this.deviceForm.patchValue({
        searchName: null,
        deviceId: currentDevice.deviceId,
        address: currentDevice.address,
        devicetype: currentDevice.devicetype,
        isTablet: currentDevice.isTablet,
        name: currentDevice.name,
        description: currentDevice.description
      });
    }
  }

  ngOnChanges() {
    this.resetForm();
  }

  //onFilterTextChanged(filterText: string) {
  //  if (this.filterTextChanged.observers.length === 0) {
  //    this.filterTextChanged
  //      .pipe(debounceTime(1000), distinctUntilChanged())
  //      .subscribe(query => {
  //        this.loadData(query);
  //      });
  //  }
  //  this.filterTextChanged.next(filterText);
  //}

  createDevice() {
    this.isNewDevice = true; // Set the flag to indicate creating a new Device
    this.device = new Device(); // Create a new Device object
    this.resetForm(); // Reset the form values
    this.clearFormFields();
    this.updateFormValues(); // Update form values based on the new device object
    this.currentIndex = 0;
    this.devIsTablet = true;
    //this.deviceForm.markAsDirty();
  }

  public saveDevice() {
    if (!this.deviceForm.valid) {
      this.alertService.errorMessage("Validation error");
      return;
    }
    var editedDevice = this.getEditedDevice();
    this.deviceForm.markAsPristine();

    if (this.isNewDevice) {
      editedDevice.deviceId = 0;
      this.deviceService.newDevice(editedDevice)
        .subscribe(id => {
          this.deviceForm.patchValue({
            deviceId: id
          })
          this.saveSuccessHelper(editedDevice)
          this.isNewDevice = false;
        },
          error => {
            if (error == "Unauthorized")
              this.authService.logout();
            else
              this.saveFailedHelper(error);
          });
    }
    this.searchForm.get('searchName').setValue(editedDevice.name);
    this.deviceService.updateDevice(editedDevice).subscribe(
      response => this.saveSuccessHelper(editedDevice),
      error => {
        if (error == "Unauthorized")
          this.authService.logout();
        else
          this.saveFailedHelper(error);
      });
    this.clearSearchField();
  }

  onInputChange(field: string, event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.deviceForm.markAsDirty();
  }

  private getEditedDevice(): Device {
    const formModel = this.deviceForm.value;
    return {
      deviceId: formModel.deviceId,
      name: formModel.name,
      address: formModel.address,
      devicetype: formModel.devicetype,
      isTablet: formModel.isTablet,
      description: formModel.description
    };
  }

  private saveSuccessHelper(personuser?: Device) {
    let mess: string;
    this.loadData();
    if (this.isNewDevice) {
      mess = this.translationService.getTranslation('notifications.InsertRecordSucceeded');
    } else {
      mess = this.translationService.getTranslation('notifications.UpdateRecordSucceeded');
    }
    this.alertService.successMessage(mess);
  }

  private saveFailedHelper(device?: Device) {
  }

  private buildForm() {
    this.deviceForm = this.formBuilder.group({
      deviceId: 0,
      name: null,
      address: null,
      devicetype: null,
      isTablet: true,
      description: null
    });
  }

  private resetForm(stopEditing = false) {
    if (stopEditing) {
      this.isEditMode = false;
    }
    this.clearFormFields();
    this.deviceForm.reset({
      deviceId: 0,
      name: '',
      address: null,
      isTablet: true,
      devicetype: null,
      description: ''
    });

  }

  private clearFormFields() {
  }

  public confirmDelete(device: Device) {
    let delQ = this.translationService.getTranslation('notifications.DeleteRecord');
    this.alertService.showDialog(delQ + '?',
      DialogType.confirm, () => this.deleteDevice(device));
  }

  deleteDevice(device: Device) {
    this.deviceService.deleteDevice(device.deviceId)
      .subscribe(results => {
        var mess = this.translationService.getTranslation('notifications.DeleteRecordSucceeded');
        this.alertService.successMessage(mess);
        this.loadData();
      },
        error => {
        });
  }

  inputHandler(e: any, id: number, key: string) {
    if (!this.valid[id]) {
      this.valid[id] = {}
    }
    this.valid[id][key] = e.target.validity.valid
  }

  disableSubmit(id: number) {
    if (this.valid[id]) {
      return Object.values(this.valid[id]).some((item) => item === false)
    }
    return false
  }

  cancel(): void {
    this.isNewDevice = false;
    this.resetForm();
    this.device = this.devices[this.currentIndex];
    this.updateFormValues();
    this.loadData();
  }

  get canManageDevices() {
    return true; 
  }
}




