<form #form="ngForm" [formGroup]="employeeForm" fxLayout="column" [fxLayoutGap]="isEditMode ? '1em' : '0'" autocomplete="off">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <input type="hidden" formControlName="employeeId">
    <input type="hidden" formControlName="employee_id">
    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'employee.Surname' | translate}}</mat-label>
      <input matInput formControlName="surname" (change)="onInputChange('surname', $event)" maxlength="30" />
    </mat-form-field>
    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'employee.Forename' | translate}}</mat-label>
      <input matInput formControlName="forename" (change)="onInputChange('forename', $event)" maxlength="20" />
    </mat-form-field>
    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'employee.EmployeeNo' | translate}}</mat-label>
      <input matInput formControlName="employeeNo" (change)="onInputChange('employeeNo', $event)" maxlength="12" />
    </mat-form-field>
  </div>
  <mat-grid-list cols="2" rowHeight="450px">
    <mat-grid-tile>
      <div class="w-100" fxLayout="column" fxLayoutAlign="space-evenly none">
        <mat-form-field>
          <mat-label>{{'common.CardId' | translate}}</mat-label>
          <input matInput type="number" max="99999999" formControlName="cardId" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'employee.Ssn' | translate}}</mat-label>
          <input matInput formControlName="ssn" maxlength="11" />
        </mat-form-field>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
          <mat-form-field [floatLabel]="floatLabels" fxFlex>
            <mat-label>{{'employee.Supervisor' | translate}}</mat-label>
            <mat-select formControlName="svid">
              <mat-option [value]="">{{'helps.NoValue' | translate}}</mat-option>
              <mat-option *ngFor="let pidname of personidnames" [value]="pidname.employeeId">
                {{ pidname.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
          <mat-form-field [floatLabel]="floatLabels" fxFlex>
            <mat-label>{{'employee.AdditionalSupervisor' | translate}}</mat-label>
            <mat-select formControlName="additionalsupervisor">
              <mat-option [value]="">{{'helps.NoValue' | translate}}</mat-option>
              <mat-option *ngFor="let pidname of personidnames" [value]="pidname.employeeId">
                {{ pidname.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-form-field>
          <mat-label>{{'employee.AdditInfo' | translate}}</mat-label>
          <input matInput formControlName="additinfo" maxlength="30" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'employee.Text' | translate}}</mat-label>
          <input matInput formControlName="text" />
          <mat-error *ngIf="employeeForm.get('text').errors?.maxlength">{{'notifications.MaximumLength60' | translate}}</mat-error>
        </mat-form-field>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-card>
        <mat-card-header>
          <mat-card-title> {{'common.Organization' | translate}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
            <input type="hidden" formControlName="companyId">
            <mat-form-field [floatLabel]="floatLabels" fxFlex>
              <mat-label>{{'common.Company' | translate}}</mat-label>
              <input matInput formControlName="company" required readonly />
            </mat-form-field>
            <mat-form-field [floatLabel]="floatLabels" fxFlex>
              <mat-label>{{'common.Descr' | translate}}</mat-label>
              <input matInput formControlName="companyName" readonly />
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
            <input type="hidden" formControlName="factoryId">
            <mat-form-field [floatLabel]="floatLabels" fxFlex>
              <mat-label>{{'common.Factory' | translate}}</mat-label>
              <input matInput formControlName="factory" readonly />
            </mat-form-field>
            <mat-form-field [floatLabel]="floatLabels" fxFlex>
              <mat-label>{{'common.Descr' | translate}}</mat-label>
              <input matInput formControlName="factoryName" readonly />
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
            <input type="hidden" formControlName="departmentId">
            <mat-form-field [floatLabel]="floatLabels" fxFlex>
              <mat-label>{{'common.Department' | translate}}</mat-label>
              <input matInput formControlName="department" readonly />
            </mat-form-field>
            <mat-form-field [floatLabel]="floatLabels" fxFlex>
              <mat-label>{{'common.Descr' | translate}}</mat-label>
              <input matInput formControlName="departmentName" readonly />
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
            <input type="hidden" formControlName="sectionId">
            <mat-form-field [floatLabel]="floatLabels" fxFlex>
              <mat-label>{{'common.Section' | translate}}</mat-label>
              <input matInput formControlName="section" readonly />
            </mat-form-field>
            <mat-form-field [floatLabel]="floatLabels" fxFlex>
              <mat-label>{{'common.Descr' | translate}}</mat-label>
              <input matInput formControlName="sectionName" readonly />
            </mat-form-field>
          </div>
        </mat-card-content>
        <mat-card-actions>
          <button mat-button style="background-color:grey; color:white" (click)="organizationClick()">{{'common.OrganizationPlace' | translate}}
          </button>
        </mat-card-actions>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>

</form>

