import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStoreManager } from './local-store-manager.service';
import { WorkNumber } from '../models/worknumber.model';
import { RealStampParam } from '../models/realstampparam.model';
import { BaseService, ApiResult } from '../base.service';

@Injectable()
export class WorkNumberService extends BaseService {
  private worknumberUrl: string = 'api/worknumber';

    constructor(
      http: HttpClient,
      @Inject('BASE_URL') baseUrl: string,
      private localStorage: LocalStoreManager) {
      super(http, baseUrl);    }

  getworknumbers(empid: string, abs: number): Observable<WorkNumber[]> {
    let language = this.localStorage.getDataObject("language");
    let sitename = this.localStorage.getDataObject("sitename");
    let defaultEmpId = empid || "-1";
    let endpointUrl = `${this.worknumberUrl}/${sitename}:${language}:${abs}?empid=${encodeURIComponent(defaultEmpId)}`;
    return this.http.get<WorkNumber[]>(endpointUrl);
    //return this.http.put<WorkNumber[]>(this.worknumberUrl, realstampParam);
  }

  newWorkNumber(table) {
    return this.http.post(this.worknumberUrl, table, { responseType: 'text' })
      .pipe()
  }

  getData(): any {
    // No operation or return null/undefined if not used
    return null; // or undefined or an empty object based on your requirements
  }

  updateWorkNumber(table) {
    return this.http.put(this.worknumberUrl, table, { responseType: 'text' })
      .pipe()
  }

}

