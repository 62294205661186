<form #form="ngForm" [formGroup]="projectForm" novalidate (ngSubmit)="save()" fxLayout="column" [fxLayoutGap]="isEditMode ? '1em' : '0'" autocomplete="off">

  <div  *ngIf="isNewProject" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field>
      <mat-label>{{'common.Name'|translate}}</mat-label>
      <mat-select id="employeeId" formControlName="employeeId">
        <mat-option *ngFor="let person of persons" [value]="person.employeeId">
          {{person.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="!isNewProject" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field >
      <mat-label>{{'common.Name'|translate}}</mat-label>
      <input matInput formControlName="name" readonly  [attr.tabindex]="!isNewProject ? -1 : null"/>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field [floatLabel]="floatLabels" fxFlex.gt-sm="0 1 calc(50% - 0.5em)">
      <mat-label>{{'stampreport.StartDate' | translate}}</mat-label>
      <input matInput formControlName="startDate" [matDatepicker]="picker1" (click)="picker1.open()" />
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>

    <mat-form-field [floatLabel]="floatLabels" fxFlex.gt-sm="0 1 calc(50% - 0.5em)">
      <mat-label>{{'stampreport.EndDate' | translate}}</mat-label>
      <input matInput formControlName="endDate" [matDatepicker]="picker2" (click)="picker2.open()" />
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'common.Percent' | translate}}</mat-label>
      <input matInput formControlName="targetTime" [readonly]="!isEditMode" />
    </mat-form-field>
  </div>
  <div>&nbsp;</div>
</form>

