import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStoreManager } from './local-store-manager.service';
import { AcceptLevel } from '../models/acceptlevel.model';

@Injectable()
export class AcceptLevelService {
  private acceptlevelUrl: string = 'api/acceptlevels';

  constructor(
    private http: HttpClient,
    private localStorage: LocalStoreManager) { }

  getAcceptLevels(): Observable<AcceptLevel[]> {
    let sitename = this.localStorage.getDataObject("sitename");
    let endpointUrl = `${this.acceptlevelUrl}/${sitename}`;
    return this.http.get<AcceptLevel[]>(endpointUrl);
  }

}

