<div class="container">
  <h3>{{'menu.CorrectionJob' | translate}}</h3>
  <div class="form-group">
    <div class="row">
      <div class="col-8">
        <strong>{{'correctionjob.CorrectionJobType' | translate}}</strong>&nbsp;&nbsp;
        <select [(ngModel)]="selectedReportId" name="reportid" (change)="toReportId()">
          <option *ngFor="let rid of reportids" [selected]="rid.booking_id==0" [ngValue]=rid>
            {{rid.descr}}
          </option>
        </select>
      </div>
    </div>
  </div>
  <br />
  <div class="form-group">
    <div class="row">
      <div class="col-1">
        <button class="btn pull-left" (click)="previousname()" [disabled]="(personIndex<=0)"><i class="fa fa-angle-left"></i></button>
      </div>
      <div class="col-6">
        <ng-select [items]="persons"
                   bindLabel="name"
                   (clear)="clearPerson()"
                   (close)="changePerson()"
                   (open)="getPerson()"
                   placeholder={{selectNameStr}}
                   [(ngModel)]="selectedPerson">
        </ng-select>
      </div>
      <div class="col-1">
        <button class="btn pull-right" (click)="nextname()" [disabled]="(!guser && !selectedPerson)"><i class="fa fa-angle-right"></i></button>
      </div>
      <div class="col-4">
        <button class="btn" (click)="organizationClick()"><strong>{{'common.Organization' | translate}}</strong></button>
      </div>
    </div>
  </div>
  <br />
  <div class="form-group">
    <div class="row">
      <div class="col-8">
        <p><strong>{{'stampreport.StartDate' | translate}}</strong></p>
      </div>
      <div class="col-4">
        <p><strong>{{'stampreport.EndDate' | translate}}</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <my-date-picker name="startdate" [options]="myDatePickerOptions" [locale]="locale" [(ngModel)]="startDate" required #dateField="ngModel"></my-date-picker>
      </div>
      <div class="col-2">
        <button class="btn" (click)="execQuery()"><strong>{{'common.Run' | translate}}</strong></button>
      </div>
      <div class="col-4">
        <my-date-picker name="enddate" [options]="myDatePickerOptions" [locale]="locale" [(ngModel)]="endDate" required #dateField="ngModel"></my-date-picker>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
  <div class="form-group">
    <ngx-datatable class="material"
                   [rows]="correctionjobs"
                   [headerHeight]="30"
                   [messages]="{emptyMessage: emptyMess, selectedMessage: false}"
                   [rowHeight]="'auto'">
      <ngx-datatable-column name="{{'employee.Surname' | translate}}" [width]="250">
        <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
          {{row.surname}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'employee.Forename' | translate}}" [width]="150">
        <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
          {{row.forename}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'common.Days' | translate}}" [width]="70">
        <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
          {{row.days}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'common.FirstDate' | translate}}" [width]="150">
        <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
          {{row.start_date | date: 'dd.MM.yyyy' }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'common.LastDate' | translate}}" [width]="150">
        <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
          {{row.end_date | date: 'dd.MM.yyyy' }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'common.CorrectionInfo' | translate}}" [width]="400">
        <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
          {{row.info}}
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>

