
import { Component, OnDestroy, Input, Output, ViewChild, OnChanges, EventEmitter } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { AppTranslationService } from '../../services/app-translation.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { PersonNameService } from "../../services/personname.service";
import { EmployeeService } from '../../services/employee.service';
import { Employee } from '../../models/employee.model';
import { PersonNameId } from '../../models/personidname.model';
import { AuthService } from '../../services/auth.service';
import { OrganizationComponent } from '../../components/organization/organization.component';
import { Organization } from '../../models/organization.model';
import { OrganizationService } from '../../services/organization.service';
import { DataService } from '../../services/data.service';
import { SharedDataService } from '../../services/shareddata.service';
import { OrganizationTreeComponent } from '../organizationtree/organizationtree.component';
import { LocalStoreManager } from '../../services/local-store-manager.service';

@Component({
  selector: 'app-persons-editor',
  templateUrl: './persons-editor.component.html',
  styleUrls: ['./persons-editor.component.scss']
})
export class PersonsEditorComponent implements OnChanges {
  @ViewChild('form', { static: true })
  private form: NgForm;

  public isNewEmployee = false;
  public showPassword = false;
  private onEmployeeSaved = new Subject<Employee>();
  employees: Employee[] = [];
  personidnames: PersonNameId[] = [];
  writeRight: boolean = false;

  organizationTree: OrganizationComponent = new OrganizationComponent(this.dataservice, this.organizationService, null, null, null);

  @Input() employee: Employee = new Employee();
  @Input() wr: boolean;
  @Input() isEditMode = true;
  @Output() messageEvent = new EventEmitter<string>();


  employeeForm: FormGroup;
  employeeSaved$ = this.onEmployeeSaved.asObservable();

  get employeeId() {
    return this.employeeForm.get('employeeId');
  }

  get employee_id() {
    return this.employeeForm.get('employee_id');
  }

  get surname() {
    return this.employeeForm.get('surname');
  }

  get forename() {
    return this.employeeForm.get('forename');
  }

  get employeeNo() {
    return this.employeeForm.get('employeeNo');
  }
  get cardId() {
    return this.employeeForm.get('cardId');
  }

  get ssn() {
    return this.employeeForm.get('ssn');
  }
  get company() {
    return this.employeeForm.get('company');
  }

  get companyName() {
    return this.employeeForm.get('companyName');
  }

  get floatLabels(): string {
    return this.isEditMode ? 'auto' : 'always';
  }

  constructor(
    public dialog: MatDialog,
    private alertService: AlertService,
    private authService: AuthService,
    private employeeService: EmployeeService,
    private translationService: AppTranslationService,
    public personNameService: PersonNameService,
    public organizationService: OrganizationService,
    public dataservice: DataService, 
    private sharedDataService: SharedDataService,
    private formBuilder: FormBuilder,
    public localStorage: LocalStoreManager
  ) {
    this.buildForm();
    this.writeRight = this.wr;
  }

  ngOnInit() {
    this.personNameService.getpersonidnames()
      .subscribe(results => {
        this.personidnames = results;
      },
        (error: any) => {
          console.log(error);
        });
  }

  ngOnChanges() {
    if (!this.employee || this.isNewEmployee) {
      this.employee = new Employee();
      this.isNewEmployee = true;
    }
    if (this.wr) {
      this.writeRight = this.wr;
    }
    this.resetForm();
  }

  public savePerson() {
    if (!this.employeeForm.valid) {
            this.alertService.errorMessage("SavePerson error");
      return;
    }
    var editedEmployee = this.getEditedEmployee();
    let cardId = Number(editedEmployee.cardId);
    editedEmployee.cardId = (cardId == 0) ? null : cardId;
    this.employeeForm.markAsPristine();
    if (this.isNewEmployee) {
      this.employeeService.newEmployee(editedEmployee)
        .subscribe(id => {
          this.employee.employeeId = id.employeeId;
          this.employee.employee_id = id.employee_id;
          this.localStorage.saveSyncedSessionData(id.employeeId, "employee_id");
          this.employeeForm.patchValue({
            employee_id: this.employee.employee_id,
            employeeId: this.employee.employeeId 
          })
          this.saveSuccessHelper(this.employee)
          this.isNewEmployee = false;
        },        
          error => {
            if (error == "Unauthorized")
              this.authService.logout();
            else
              this.saveFailedHelper(error);
          });
    } else {
      editedEmployee.updateScreen = 0;
      this.employeeService.updateEmployee(editedEmployee).subscribe(
        response => this.saveSuccessHelper(editedEmployee),
        error => {
          if (error == "Unauthorized")
            this.authService.logout();
          else
            this.saveFailedHelper(error);
        });
    }
  }

  private getEditedEmployee(): Employee {
    const formModel = this.employeeForm.value;
    console.log("GetEDited empl " + formModel.ssn);
    return {
      updateScreen: 0,
      employeeId: formModel.employeeId,
      employee_id: formModel.employee_id,
      surname: formModel.surname,
      forename: formModel.forename,
      employeeNo: formModel.employeeNo,
      ssn: formModel.ssn,
      email: this.employee.email,
      hidden: this.employee.hidden,
      userLanguage: this.employee.userLanguage,
      mobileTelephone: this.employee.mobileTelephone,
      professionId: this.employee.professionId,
      guserTypeId: this.employee.guserTypeId,
      guserPwd: this.employee.guserPwd,
      employeeGroup: this.employee.employeeGroup,
      occupation: this.employee.occupation,
      workingPos: this.employee.workingPos,
      emplStart: this.employee.emplStart,
      emplEnd: this.employee.emplEnd,
      text: formModel.text,
      premiVisor: this.employee.premiVisor,
      position: this.employee.position,
      svId: formModel.svid,
      cardId: formModel.cardId,
      gender: this.employee.gender,
      add_employee_no: this.employee.add_employee_no,
      salarysys_user: this.employee.salarysys_user,
      worktime_data: this.employee.worktime_data,
      acceptLevel: this.employee.acceptLevel,
      lunchGroup: this.employee.lunchGroup,
      lunchDisable: this.employee.lunchDisable,
      additionalSupervisor: formModel.additionalsupervisor,
      additInfo: formModel.additinfo,
      companyId: formModel.companyId,
      company: formModel.company,
      companyName: formModel.companyName,
      factoryId: formModel.factoryId,
      factory: formModel.factory,
      factoryName: formModel.factoryName,
      departmentId: formModel.departmentId,
      department: formModel.department,
      departmentName: formModel.departmentName,
      sectionId: formModel.sectionId,
      section: formModel.section,
      sectionName: formModel.sectionName,
      lastChange: this.employee.lastChange,
      changemadeby: this.employee.changemadeby
    };
  }

  private saveSuccessHelper(empl?: Employee) {
    let mess: string;
    if (this.isNewEmployee) {
      mess = this.translationService.getTranslation('notifications.InsertRecordSucceeded');
    } else {
      mess = this.translationService.getTranslation('notifications.UpdateRecordSucceeded');
    }
    this.alertService.successMessage(mess);
    this.messageEvent.emit('Mess');
  }



  organizationClick(): void {
    const dialogConfig1 = new MatDialogConfig();
    dialogConfig1.disableClose = true;
    dialogConfig1.autoFocus = true;
    dialogConfig1.height = '600px';
    dialogConfig1.width = '600px';
    dialogConfig1.data = {
      companyNumber: this.employee.company,
      factoryNumber: this.employee.factory ?? -1,
      departmentNumber: this.employee.department??-1,
      sectionNumber: this.employee.section??-1
    };
    const dialogRef = this.dialog.open(OrganizationTreeComponent, dialogConfig1);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != null) {
        let company = result.companyNumber;
        let factory = (result.factoryNumber == -1) ? null : result.factoryNumber;
        let department = (result.departmentNumber == -1) ? null : result.departmentNumber;
        let section = (result.sectionNumber == -1) ? null : result.sectionNumber;

        this.organizationService.getOrganizationLeaf(company, factory, department, section)
          .subscribe(results => {
            if (result != null) {
              this.employee.company = results.companyNumber.toString();
              this.employee.factory = (results.factoryNumber == null) ? null : results.factoryNumber.toString();
              this.employee.department = (results.departmentNumber == null) ? null : results.departmentNumber.toString();
              this.employee.section = (results.sectionNumber == null) ? null : results.sectionNumber.toString();
              this.employee.companyId = results.companyId;
              this.employee.factoryId = (results.factoryNumber == null) ? null : results.factoryId;
              this.employee.departmentId = (results.departmentNumber == null) ? null : results.departmentId;
              this.employee.sectionId = (results.sectionNumber == null) ? null : results.sectionId;
              this.employee.companyName = results.companyDesc;
              this.employee.factoryName = results.factoryDesc;
              this.employee.departmentName = results.departmentDesc;
              this.employee.sectionName = results.sectionDesc;
              this.setOrganization();
              this.employeeForm.markAsDirty();
            }
          },           
          (error: any) => {
              console.log(error);
        });
      }
    });
  }

  private saveFailedHelper(empl?: Employee) {
  }

  onInputChange(field: string, event: Event) {
    const value = (event.target as HTMLInputElement).value;
    const rowId = this.employeeId.value; 
    //console.log("OnInputChange " + rowId +  " F " + field + " V " + value);
    this.sharedDataService.updateFieldValue(rowId, field, value);
  }


  private cancel() {
    this.resetForm();
  }

  private buildForm() {
    this.employeeForm = this.formBuilder.group({
      employeeId: null,
      employee_id: 0,
      surname: ['', Validators.required],
      forename: ['', Validators.required],
      employeeNo: ['', Validators.required],
      cardId: null,
      svid: null,
      additionalsupervisor:null,
      additinfo:null,
      ssn: null,
      text: [null, [Validators.maxLength(60)]],
      companyId: null,
      company: null,
      companyName: null,
      factoryId: null,
      factory: null,
      factoryName: null,
      departmentId: null,
      department: null,
      departmentName: null,
      sectionId: null,
      section: null,
      sectionName: null
    });
  }

  private resetForm(stopEditing = false) {
    if (stopEditing) {
      this.isEditMode = false;
    }

    if (this.isNewEmployee) {
      this.employee = new Employee();
    }
    this.employeeForm.reset({
      employeeId: this.employee.employeeId || '',
      employee_id: this.employee.employee_id || 0,
      surname: this.employee.surname || '',
      forename: this.employee.forename || '',
      employeeNo: this.employee.employeeNo || '',
      ssn: this.employee.ssn || null,
      text: this.employee.text || null,
      svid: this.employee.svId || null,
      additionalsupervisor: this.employee.additionalSupervisor ||null,
      additinfo: this.employee.additInfo || null,
      occupation: this.employee.occupation || null,
      professionId: this.employee.professionId || null,
      cardId: this.employee.cardId || null,
      companyId: this.employee.companyId || '',
      company: this.employee.company || '',
      companyName: this.employee.companyName || '',
      factoryId: this.employee.factoryId || null,
      factory: this.employee.factory || null,
      factoryName: this.employee.factoryName || '',
      departmentId: this.employee.departmentId || null,
      department: this.employee.department || null,
      departmentName: this.employee.departmentName || '',
      sectionId: this.employee.sectionId || null,
      section: this.employee.section || null,
      sectionName: this.employee.sectionName || ''
    });

  }

  private setOrganization() {
    this.employeeForm.patchValue({
      companyId: this.employee.companyId ,
      company: this.employee.company ,
      companyName: this.employee.companyName ,
      factoryId: this.employee.factoryId ,
      factory: this.employee.factory ,
      factoryName: this.employee.factoryName ,
      departmentId: this.employee.departmentId ,
      department: this.employee.department ,
      departmentName: this.employee.departmentName,
      sectionId: this.employee.sectionId,
      section: this.employee.section ,
      sectionName: this.employee.sectionName 
    });

  }

}
