<h1 mat-dialog-title>
  {{ 'common.SetPassword' | translate }}
</h1>
<div mat-dialog-content>
  <form #passwordForm="ngForm">
    <mat-form-field>
      <input placeholder="{{ 'common.NewPassword' | translate }}" matInput name="password1" [(ngModel)]="data.password1" required>
    </mat-form-field><br>
    <mat-form-field>
      <input placeholder="{{ 'common.ConfirmPassword' | translate }}" matInput name="password2" [(ngModel)]="data.password2" required>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="onZeroClick()" color="accent">{{ 'helps.Clear' | translate }}</button>
  <button mat-raised-button [mat-dialog-close]="data" color="primary" [disabled]="!passwordForm.form.valid">{{ 'helps.Update' | translate }}</button>
  <button mat-raised-button (click)="onNoClick()" color="warn">{{ 'helps.Cancel' | translate }}</button>
</div>
