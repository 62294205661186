import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { IMyDpOptions, IMyDate } from 'mydatepicker';
import { PersonNameService } from "../services/personname.service";
import { Person } from '../models/person.model';
import { Router } from '@angular/router';
import { OrganizationComponent } from './organization/organization.component';
import { Organization } from '../models/organization.model';
import { OrganizationService } from '../services/organization.service';
import { LocalStoreManager } from '../services/local-store-manager.service';
import { ReportParam } from '../models/reportparam.model';
import { PrintReportParam } from '../models/printreportparam.model';
import { AlertService } from '../services/alert.service';
import { AppTranslationService } from "../services/app-translation.service";
import { DataService } from '../services/data.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpResponse } from '@angular/common/http';

@Component({
  template: ''
})
export abstract class BaseReportComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    public dataservice: DataService,
    public personNameService: PersonNameService,
    public localStorage: LocalStoreManager,
    public translationService: AppTranslationService,
    public organizationService: OrganizationService,
    public alertService: AlertService,
    public router: Router,
    public spinner: NgxSpinnerService) {
  }

  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'dd.mm.yyyy',
    //disableSince: this.dateNow,
    width: "100%",
    showClearDateBtn: false,
    alignSelectorRight: false,
    showDecreaseDateBtn: true,
    showIncreaseDateBtn: true
  };
  organizationTree: OrganizationComponent = new OrganizationComponent(this.dataservice, this.organizationService, null, null, null);
  //newOrganization: Organization[] = [];
  reportid: number = 0;
  selectedDate: Date;
  dateNow: IMyDate = this.getToday();
  dateOlder: IMyDate = this.getOlderDate();
  personIndex: number;
  selectedPerson: Person;
  persons: Person[] = [];
  personids: Array<number>;
  guser: boolean;
  ownDataVisible: boolean;
  personCount: number;
  reportRights: number;
  personname: string;
  employeeId: string;
  params = new ReportParam();
  locale: string;
  hasOrganization: boolean;
  userRights: number;
  refreshToolTip: string;
  pdfToolTip: string;
  excelToolTip: string;
  withHeader: boolean;
  printparams = new PrintReportParam();
  startDate: any = { date: { year: this.dateOlder.year, month: this.dateOlder.month, day: this.dateOlder.day } };
  endDate: any = { date: { year: this.dateNow.year, month: this.dateNow.month, day: this.dateNow.day } };
  stampDate: any = { date: { year: this.dateNow.year, month: this.dateNow.month, day: this.dateNow.day } };
  emptyMess = this.translationService.getTranslation('helps.NoDataToDisplay');
  selectNameStr = this.translationService.getTranslation('stampreport.SelectName');
 
  ngOnInit(): void {
    this.locale = this.localStorage.getDataObject("language");
    this.guser = this.localStorage.getDataObject("guser");
    this.reportRights = this.localStorage.getDataObject("reportrights");
    this.personCount = this.localStorage.getDataObject("personcount");
    this.ownDataVisible = (this.reportRights&32768) == 32768;
    this.hasOrganization = this.localStorage.getDataObject("orgright");
    this.userRights = this.localStorage.getDataObject("userrights");
    this.refreshToolTip = this.translationService.getTranslation('common.refreshToolTip');
    this.pdfToolTip = this.translationService.getTranslation('common.pdfToolTip');
    this.excelToolTip = this.translationService.getTranslation('common.excelToolTip');
    this.personIndex = -1;
    this.employeeId = null;
    this.dataservice.StampDate = this.stampDate;
    if (this.dataservice.PersonIndex >= 0) {
      this.personIndex = this.dataservice.PersonIndex;
      this.startDate = this.dataservice.StartDate ?? this.startDate;
      this.endDate = this.dataservice.EndDate ?? this.endDate;
    }
    this.GUserSettings();
    if (!this.guser)
      this.personNameService.getpersonnames("")
        .subscribe(results => {
          this.persons = results;
          if (this.dataservice.PersonData) {
            this.selectedPerson = this.dataservice.PersonData;
            this.employeeId = this.dataservice.PersonData.employeeId;
            this.personIndex = this.dataservice.PersonIndex;
          }
          else {
            if (this.persons.length > 0) {
              this.selectedPerson = this.persons[0];
              this.employeeId = this.persons[0].employeeId;
              this.personIndex = 0;
            }
          }
        },
          (error: any) => {
            console.log(error);
          });

  }

  GUserSettings() {
    this.guser = this.guser && this.personCount <= 1 && this.ownDataVisible;
    if (this.guser) {
      this.personname = this.localStorage.getDataObject("personname");
      this.getPersonNames(this.localStorage.getDataObject("employee_id"));
      this.params.employee_id = this.localStorage.getDataObject("employee_id");
      this.params.lang = this.localStorage.getDataObject("language");
    }
  }

  getToday(): IMyDate {
    let date: Date = new Date();
    date.setDate(date.getDate());
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
  }

  getOlderDate(): IMyDate {
    let date: Date = new Date();
    date.setDate(date.getDate() - 7);
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
  }

  changePerson() {
    if (this.persons != null && this.selectedPerson != null) {
      this.personIndex = this.persons.indexOf(this.selectedPerson);
      if (this.personIndex < 0)
        this.personIndex = 0;
      this.employeeId = this.persons[this.personIndex].employeeId;
    }
  }

  clearPersonList() {
    this.persons = [];
    this.selectedPerson = null;
    this.personIndex = -1;
    this.employeeId = null;
  }

  clearPerson() {
    this.selectedPerson = null;
    this.personIndex = -1;
    this.employeeId = null;
  }

  previousname() {
    if (this.personIndex > 0) {
      this.personIndex--;
      this.selectedPerson = this.persons[this.personIndex];
      this.employeeId = this.persons[this.personIndex].employeeId;
    }
  }

  nextname() {
    if (this.personIndex < this.persons.length - 1) {
      this.personIndex++;
      this.selectedPerson = this.persons[this.personIndex];
      this.employeeId = this.persons[this.personIndex].employeeId;
    }
  }

  getPersonNames(personid: string) {
    this.personNameService.getpersonnames(personid)
      .subscribe(results => {
        this.persons = results;
      },
        (error: any) => {
          console.log(error);
        });
  }

  stampReportParameters() {
    this.params.lang = this.localStorage.getDataObject("language");
    this.params.startDate = this.startDate.formatted;
    if (!this.params.startDate) {
      var d = this.startDate.date;
      this.params.startDate = ("0" + d.day).slice(-2) + "." + ("0" + d.month).slice(-2) + "." + d.year;
    }
    this.params.endDate = this.endDate.formatted;
    if (!this.params.endDate) {
      var d = this.endDate.date;
      this.params.endDate = ("0" + d.day).slice(-2) + "." + ("0" + d.month).slice(-2) + "." + d.year;
    }
  }

  checkReportDates() {
    let sd = this.startDate.date.year * 10000 + this.startDate.date.month * 100 + this.startDate.date.day;
    let ed = this.endDate.date.year * 10000 + this.endDate.date.month * 100 + this.endDate.date.day;
    if (sd > ed) {
      this.alertService.showDialog(this.translationService.getTranslation('Errormessage.EndDateBeforeStartDate'));
      return false;
    }
    else return true;
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '600px';
    dialogConfig.width = '600px';
    dialogConfig.data = {
    };
    const dialogRef = this.dialog.open(OrganizationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result: string) => {
      this.organizationTree.newOrganization = this.dataservice.editedOrganization;
      if (this.organizationTree.newOrganization.length == 0) {
        this.persons = null;
        this.selectedPerson = null;
      }
      else
        this.personNameService.getorganizationpersonnames(this.organizationTree.newOrganization)
          .subscribe(results => {
            this.persons = results;
            if (this.persons.length == 0) {
              this.selectedPerson = null;
            }
            else {
              this.selectedPerson = this.persons[0];
            }
          },
            (error: any) => {
              console.log(error);
            });
    });
  }

  public printEmployeeParams(all: number) {
    if (!this.checkReportDates())
      return;
    if (!this.guser) {
      this.printparams.employee_id = [];
      if (all == 1) {
        for (var i = 0, len = this.persons.length; i < len; i++) {
          this.printparams.employee_id[i] = this.persons[i].employeeId;
        }
      }
      else {
        this.printparams.employee_id[0] = this.selectedPerson.employeeId;
        this.personname = this.selectedPerson.name;
      }
    }
    else {
      this.printparams.employee_id[0] = this.params.employee_id; // this.selectedPerson.employeeId;
    }
  }

  public printParameters() {
    this.stampReportParameters();
    this.printparams.reportType = this.reportid;
    this.printparams.startDate = this.params.startDate;
    this.printparams.endDate = this.params.endDate;
    this.printparams.lang = this.params.lang;
  }

  // Function to extract the filename from the response headers
  public getFilenameFromResponse(response: HttpResponse<Blob>): string {
    const contentDispositionHeader = response.headers.get('Content-Disposition');
    if (contentDispositionHeader) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDispositionHeader);
      if (matches != null && matches[1]) {
        return matches[1].replace(/['"]/g, '');
      }
    }
    return '';
  }
}
