import { Component, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectPerson } from '../../models/projectperson.model';
import { ProjectPersonEditorComponent } from './projectperson-editor.component';

@Component({
  selector: 'app-edit-projectperson-dialog',
  templateUrl: 'edit-projectperson-dialog.component.html',
  styleUrls: ['edit-projectperson-dialog.component.scss']
})
export class EditProjectPersonDialogComponent implements AfterViewInit {
  @ViewChild(ProjectPersonEditorComponent, { static: true })
  projectEditor: ProjectPersonEditorComponent;

  get projectName(): any {
    return this.data.projectperson.name ? { name: this.data.projectperson.name } : null;
  }

  constructor(
    public dialogRef: MatDialogRef<ProjectPersonEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { projectperson: ProjectPerson, projectNumber }
  ) {
    console.log("DIALOG " + data.projectperson.name + " " + data.projectNumber );
  }

  ngAfterViewInit() {
    this.projectEditor.projectSaved$.subscribe(project => this.dialogRef.close(project));
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

  get canManageProjectPersons() {
    return true;
  }
}
