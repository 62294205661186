<form (keydown.enter)="loadData()">
  <div class="page-content">
    <h3>{{'menu.PersonUsers' | translate}}</h3>
    <div fxLayout="row" fxLayoutGap="10px">
      <div fxFlex.gt-sm="40%" fxLayout="row" fxLayoutAlign="start align-center" fxLayoutGap="10px">
        <mat-form-field fxFlex="grow" [formGroup]="searchForm">
          <input matInput formControlName="searchName" placeholder="{{'common.personSearch' | translate}}">
          <button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchForm.get('searchName').value" (click)="clearSearchField()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <button mat-raised-button color="primary" [disabled]="isNewPersonUser" class="custom-button" (click)="loadData()">{{'common.Refresh' | translate}}</button>
      </div>
      <div fxFlex.gt-sm="60%" fxLayout="row" fxLayoutAlign="start align-center" fxLayoutGap="10px">
        <button mat-raised-button (click)="showFirstRecord()" [disabled]="isNewPersonUser || (currentIndex === 0)"><span class="material-icons">first_page</span></button>
        <button mat-raised-button (click)="showPreviousRecord()" [disabled]="isNewPersonUser || (currentIndex === 0)"><span class="material-icons">navigate_before</span></button>
        <button mat-raised-button (click)="showNextRecord()" [disabled]="isNewPersonUser || (currentIndex === personUsers.length - 1)"><span class="material-icons">navigate_nextr</span></button>
        <button mat-raised-button (click)="showLastRecord()" [disabled]="isNewPersonUser || (currentIndex === personUsers.length - 1)"><span class="material-icons">last_page</span></button>
        <button mat-raised-button color="primary" [disabled]="!writeRight" (click)="createPersonUser()">{{'personuser.NewPersonUser' | translate}}</button>
        <button mat-raised-button color="warn" [disabled]="!writeRight || isNewPersonUser" (click)="confirmDelete(personuser)">{{'personuser.DeletePersonUser' | translate}}</button>
      </div>
    </div>
    <div [formGroup]="personUserForm">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="2px">
        <div fxFlex.gt-sm="50%">
          <div fxLayout="column" fxLayoutGap="2px">
            <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
              <mat-form-field [floatLabel]="floatLabels" fxFlex="50">
                <mat-label>{{'personuser.Username' | translate}}</mat-label>
                <input matInput formControlName="username" [readonly]="!isNewPersonUser" [attr.tabindex]="!isNewPersonUser ? -1 : null" />
                <mat-error *ngIf="username.hasError('required')">
                  {{'notifications.FieldRequired' | translate}}
                </mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="50">
                <mat-label>{{'employee.GuserPwd' | translate}}</mat-label>
                <input matInput formControlName="password" (click)="openPasswordDialog()" type="password" readonly />
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
              <mat-form-field [floatLabel]="floatLabels" fxFlex="50">
                <mat-label>{{'personuser.UserTypeId' | translate}}</mat-label>
                <mat-select formControlName="userTypeId">
                  <mat-option *ngFor="let userType of userTypes" [value]="userType.user_type_id">
                    {{ userType.title }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field [floatLabel]="floatLabels" fxFlex>
                <mat-label>{{'common.Descr' | translate}}</mat-label>
                <input matInput formControlName="text" />
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
              <mat-form-field [floatLabel]="floatLabels" fxFlex="50">
                <mat-label>{{'personuser.RightsEndDate' | translate}}</mat-label>
                <input matInput formControlName="rightsEndDate" [matDatepicker]="picker1" (click)="picker1.open()" />
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
              <mat-form-field fxFlex="50">
                <mat-label>{{'personuser.NoWsUse' | translate}}</mat-label>
                <mat-select formControlName="noWsUse">
                  <mat-option *ngFor="let yesno of yesnos" [value]="yesno.id">
                    {{ yesno.descr }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div fxFlex.gt-sm="50%">
          <div fxLayout="column" fxLayoutGap="2px">
            <mat-form-field [floatLabel]="floatLabels" fxFlex>
              <mat-label>{{'common.Personname' | translate}}</mat-label>
              <mat-select formControlName="employeeId">
                <mat-option [value]="">{{'helps.NoValue' | translate}}</mat-option>
                <mat-option *ngFor="let pidname of personidnames" [value]="pidname.employeeId">
                  {{ pidname.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
              <mat-form-field [floatLabel]="floatLabels" fxFlex="50">
                <mat-label>{{'employee.EmplStart' | translate}}</mat-label>
                <input matInput formControlName="startDate" readonly />
              </mat-form-field>
              <mat-form-field fxFlex="50">
                <mat-label>{{'employee.EmplEnd' | translate}}</mat-label>
                <input matInput formControlName="endDate" readonly />
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start start">
              <mat-form-field [floatLabel]="floatLabels" fxFlex="50">
                <mat-label>{{'common.LastChange' | translate}}</mat-label>
                <input matInput formControlName="lastChange" readonly />
              </mat-form-field>
              <mat-form-field fxFlex="50">
                <mat-label>{{'common.Doer' | translate}}</mat-label>
                <input matInput formControlName="changemadeby" readonly />
              </mat-form-field>
            </div>

          </div>
        </div>
      </div>
      <mat-card class="framed-mat-card">
        <mat-card-header style="display: flex; justify-content: space-between; align-items: center;">
          <mat-card-title> {{'personuser.RelaesedRights' | translate}}</mat-card-title>
          <div>
            <button mat-raised-button  [disabled]="!writeRight" (click)="addRow()" color="primary" style="float: right;">{{'personuser.AddSubstitute' | translate}}</button>
          </div>
        </mat-card-header>
        <mat-card-content>
          <table mat-table [dataSource]="dataSource1">
            <ng-container [matColumnDef]="col.key" *ngFor="let col of columnsSchema1">
              <th mat-header-cell *matHeaderCellDef [ngSwitch]="col.key">
                <span *ngSwitchDefault>{{ col.label }}</span>
              </th>
              <mat-label>{{'common.ChooseDate' | translate}}</mat-label>
              <td mat-cell *matCellDef="let element">
                <div [ngSwitch]="col.type" *ngIf="!element.isEdit">
                  <div class="btn-edit" *ngSwitchCase="'isEdit'">
                    <button mat-button (click)="element.isEdit = !element.isEdit">
                      {{'common.Change' | translate}}
                    </button>
                    <button mat-button class="button-remove" (click)="confirmDeleteSubstitute(element)">
                      {{'common.Delete' | translate}}
                    </button>
                  </div>
                  <span *ngSwitchCase="'startdate'">
                    {{ element[col.key] | date: 'dd.MM.yyyy' }}
                  </span>
                  <span *ngSwitchCase="'enddate'">
                    {{ element[col.key] | date: 'dd.MM.yyyy' }}
                  </span>
                  <span *ngSwitchDefault>
                    {{ element[col.key] }}
                  </span>
                </div>
                <div [ngSwitch]="col.type" *ngIf="element.isEdit">
                  <div class="btn-edit" *ngSwitchCase="'isEdit'">
                    <button mat-button (click)="element.isEdit = !element.isEdit">
                      {{'common.Cancel' | translate}}
                    </button>
                    <button mat-button (click)="editRow(element)" [disabled]="disableSubmit(element.id)">
                      {{'common.Edit' | translate}}
                    </button>
                  </div>
                  <mat-form-field *ngSwitchCase="'select'" appearance="fill">
                    <mat-select [(ngModel)]="element[col.key]" [ngModelOptions]="{standalone: true}" required>
                      <mat-option *ngFor="let substituteRow of personUsers" [value]="substituteRow.username">
                        {{ substituteRow.username }} {{ substituteRow.personname }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="form-input" *ngSwitchCase="'startdate'" appearance="fill">
                    <mat-label>{{'common.ChooseDate' | translate}}</mat-label>
                    <input matInput
                           [required]="col.required"
                           [matDatepicker]="picker"
                           [(ngModel)]="element[col.key]"
                           [ngModelOptions]="{standalone: true}"/>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field class="form-input" *ngSwitchCase="'enddate'" appearance="fill">
                    <mat-label>{{'common.ChooseDate' | translate}}</mat-label>
                    <input matInput
                           [required]="col.required"
                           [matDatepicker]="picker"
                           [(ngModel)]="element[col.key]"
                           [ngModelOptions]="{standalone: true}"/>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
          </table>
        </mat-card-content>
      </mat-card>
      <mat-card class="framed-mat-card">
        <mat-card-header>
          <mat-card-title> {{'personuser.ObtainedRights' | translate}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <table mat-table [dataSource]="dataSource2">
            <ng-container [matColumnDef]="col.key" *ngFor="let col of columnsSchema2">
              <th mat-header-cell *matHeaderCellDef [ngSwitch]="col.key">
                <span *ngSwitchDefault>{{ col.label }}</span>
              </th>
              <td mat-cell *matCellDef="let element">
                <div [ngSwitch]="col.type">
                  <span *ngSwitchCase="'startdate'">
                    {{ element[col.key] | date: 'dd.MM.yyyy' }}
                  </span>
                  <span *ngSwitchCase="'enddate'">
                    {{ element[col.key] | date: 'dd.MM.yyyy' }}
                  </span>
                  <span *ngSwitchDefault>
                    {{ element[col.key] }}
                  </span>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns2"></tr>
          </table>
        </mat-card-content>
        </mat-card>
</div>
    <div>&nbsp;</div>
    <div mat-dialog-actions style="display: flex; justify-content: space-between; align-items: center;">
      <div>
        <button mat-raised-button (click)="savePersonUser();" [disabled]="!writeRight || !personUserForm.dirty" color="primary">{{'common.Save' | translate}}</button>
        <button mat-raised-button (click)="cancel()" color="warn"> {{'common.Cancel' | translate}}</button>
      </div>
      <div class="record-count">
        {{'common.Record' | translate}} {{currentIndex + 1}} / {{personUsers.length}}
      </div>
    </div>
  </div>
</form>
















