export class Project {
  id: number;
  number: number;
  name: string;
  description: string;
  startDate: Date;
  endDate: Date;
  employeeId: string;
  personName: string;
  costPlace: string;
}

