import { Component, Input, OnInit, ViewChild, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppTranslationService } from '../../services/app-translation.service';
import { AlertService, DialogType } from '../../services/alert.service';
import { PersonNameService } from "../../services/personname.service";
import { SiteService } from '../../services/site.service';
import { UserType } from '../../models/usertype.model';
import { UserTypeUright } from '../../models/usertypeuright.model';
import { AuthService } from '../../services/auth.service';
import { UserTypeService } from '../../services/usertype.service';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { YesNo } from '../../models/yesno.model';
import { UserRightsDialogComponent } from './usertypeuserrights.component';
import { AccessRightsDialogComponent } from './usertypeaccessrights.component';


@Component({
  selector: 'usertype.component.ts',
  templateUrl: './usertype.component.html',
  styleUrls: ['./usertype.component.scss',]
})

export class UserTypeComponent implements OnInit, OnChanges {
  @ViewChild('form', { static: true })
  public isNewUserType = false;
  userTypes: UserType[] = [];
  usertype: UserType = null;
  filterQuery: string = null;
  currentIndex: number = 0; // Starts from the first record
  lastIndex = 0;
  rights: number = 0;
  writeRights: number = 0;
  writeRight: boolean = false;
  yesnos: YesNo[] = [
    { id: 1, descr: this.translationService.getTranslation('common.No') },
    { id: 2, descr: this.translationService.getTranslation('common.Yes') }
  ];
  adding: boolean = false;
  valid: any = {};

  @Input() isEditMode = true;

  searchForm: FormGroup;
  userTypeForm: FormGroup;
  get searchName() {
    return this.searchForm.get('searchName');
  }

  get userTypeid() {
    return this.userTypeForm.get('userTypeid');
  }
  get title() {
    return this.userTypeForm.get('title');
  }

  get descr() {
    return this.userTypeForm.get('descr');
  }
  get text() {
    return this.userTypeForm.get('text');
  }

  get floatLabels(): string {
    return this.isEditMode ? 'auto' : 'always';
  }


  constructor(
    public dialog: MatDialog,
    private siteService: SiteService,
    private alertService: AlertService,
    private authService: AuthService,
    private userTypeService: UserTypeService,
    public personNameService: PersonNameService,
    private translationService: AppTranslationService,
    private formBuilder: FormBuilder,
    public localStorage: LocalStoreManager
  ) {
    this.searchForm = this.formBuilder.group({
      searchName: [''], // Initialize your search field
    });
    this.buildForm();
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.loadData();
    this.rights = this.localStorage.getDataObject("readrights");
    this.writeRights = this.localStorage.getDataObject("userrights");
    this.writeRight = (this.writeRights & 16384) == 16384;
  }

  clearSearchField(): void {
    this.searchForm.get('searchName').setValue(''); // This sets the searchName control to an empty string
  }

  loadData() {
    this.filterQuery = this.searchForm.get('searchName').value === '' ? null : this.searchForm.get('searchName').value;
    this.userTypeService.getUserTypes(this.filterQuery)
      .subscribe(data => {
        this.userTypes = data;
        if (this.userTypes.length > 0) {
          this.usertype = this.userTypes[this.currentIndex];
          this.lastIndex = this.userTypes.length - 1;
          this.updateFormValues();
        }
        else {
          var mess = this.translationService.getTranslation('notifications.NoDataAvailable');
          this.alertService.errorMessage(mess);
          this.resetForm();
        }
        this.authService.refreshLogin()
          .subscribe(data => {
            this.authService.refresh_userdata(data);
          },
            (error: any) => {
            });
      },
        (error: any) => {
          //console.log(error);
        });
  }

  showNextRecord() {
    if (this.currentIndex < this.lastIndex) {
      this.currentIndex++;
      this.usertype = this.userTypes[this.currentIndex];
      this.updateFormValues();
    }
  }

  showPreviousRecord() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.usertype = this.userTypes[this.currentIndex];
      this.updateFormValues();
    }
  }

  showFirstRecord() {
    if (this.currentIndex > 0) {
      this.currentIndex = 0;
      this.usertype = this.userTypes[this.currentIndex];
      this.updateFormValues();
    }
  }

  showLastRecord() {
    if (this.currentIndex < this.lastIndex) {
      this.currentIndex = this.lastIndex;
      this.usertype = this.userTypes[this.currentIndex];
      this.updateFormValues();
    }
  }

  updateFormValues() {
    if (this.isNewUserType) {
      this.resetForm();
    } else {
      const currentUserType = this.userTypes[this.currentIndex];
      this.userTypeForm.patchValue({
        searchName: null,
        user_type_id: currentUserType.user_type_id,
        title: currentUserType.title,
        descr: currentUserType.descr,
        text: currentUserType.text,
        admin_rights: currentUserType.admin_rights? 2 : 1 || '1',
        guser_type: currentUserType.guser_type ? 2 : 1 || '1',
      });
    }
  }

  ngOnChanges() {
    //if (this.employee) {
    //  this.isNewPersonUser = false;
    //} else {
    //  this.isNewPersonUser = true;
    //  this.employee = new Employee();
    //}
    this.resetForm();
  }

  //onFilterTextChanged(filterText: string) {
  //  if (this.filterTextChanged.observers.length === 0) {
  //    this.filterTextChanged
  //      .pipe(debounceTime(1000), distinctUntilChanged())
  //      .subscribe(query => {
  //        this.loadData(query);
  //      });
  //  }
  //  this.filterTextChanged.next(filterText);
  //}

  createUserType() {
    this.isNewUserType = true; // Set the flag to indicate creating a new PersonUser
    this.usertype = new UserType(); // Create a new PersonUser object
    this.resetForm(); // Reset the form values
    //this.clearFormFields();
    this.updateFormValues(); // Update form values based on the new personuser object

    //this.personUserService.newPersonUser(this.personuser);
  }

  AddUserRights(): void {
    const dialogRef = this.dialog.open(UserRightsDialogComponent, {
      width: '800px',
      data: { userType: this.usertype.user_type_id }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result != null) {
        //this.userTypeService.updateUserURights(result).subscribe({
        //  next: (response) => console.log('Succesfull', response),
        //  error: (error) => console.error('Update failed', error)
        //});
          //response => this.saveSuccessHelper(result),
          //error => {
          //  if (error == "Unauthorized")
          //    this.authService.logout();
          //  else
          //    this.saveFailedHelper(error);
          //});

        // this.userTypeForm.markAsDirty();
        //this.usertype.accessright = result;
      }
      // You can use result to update something or make API calls
    });
  }

  AddAccessRights() {
    const dialogRef = this.dialog.open(AccessRightsDialogComponent, {
      width: '500px',
      data: { userType: this.usertype.user_type_id }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result != null) {
        this.userTypeService.updateUserARights(result).subscribe(
          response => this.saveSuccessHelper(result),
          error => {
            if (error == "Unauthorized")
              this.authService.logout();
            else
              this.saveFailedHelper(error);
          });

        //this.userTypeForm.markAsDirty();
        //this.usertype.accessright = result;
      }
      // You can use result to update something or make API calls
    });
  }

  public saveUserType() {
    if (!this.userTypeForm.valid) {
      this.alertService.errorMessage("Validation error");
      return;
    }
    var editedUserType = this.getEditedUserType();
    this.userTypeForm.markAsPristine();

    if (this.isNewUserType) {
      editedUserType.user_type_id = 0;
      this.userTypeService.newUserType(editedUserType)
        .subscribe(id => {
          if (id != null) {
            this.usertype.user_type_id = id;
            this.userTypeForm.patchValue({
              user_type_id: this.usertype.user_type_id,
            })
            this.isNewUserType = false;
          }
          // Create empty user rights for new type
          this.userTypeService.newUserURight(this.usertype.user_type_id)
            .subscribe(responce => {
              console.log('Response from uright:', responce);
              // Create empty access rights for new type
              this.userTypeService.newUserARight(this.usertype.user_type_id)
                .subscribe(responce => {
                  console.log('Response from aright:', responce);
                },
                  (error: any) => {
                  });
            },
              (error: any) => {
              });
        },
         error => {
            if (error == "Unauthorized")
              this.authService.logout();
            else
              this.saveFailedHelper(error);
          });
    }
    else {
      this.searchForm.get('searchName').setValue(editedUserType.title);
      this.userTypeService.updateUserType(editedUserType).subscribe(
        response => this.saveSuccessHelper(editedUserType),
        error => {
          if (error == "Unauthorized")
            this.authService.logout();
          else
            this.saveFailedHelper(error);
        });
    }
    this.clearSearchField();
  }

  private getEditedUserType(): UserType {
    const formModel = this.userTypeForm.value;
    return {
      user_type_id: formModel.user_type_id,
      title: formModel.title,
      descr: formModel.descr,
      text: formModel.text,
      admin_rights: (formModel.admin_rights == 2) ? true : false,
      guser_type: (formModel.guser_type == 2) ? true : false, 
      //userrights: null,
      //accessright: null
    };
  }

  private saveSuccessHelper(usertype?: UserType) {
    let mess: string;
    this.loadData();
    if (this.isNewUserType) {
      mess = this.translationService.getTranslation('notifications.InsertRecordSucceeded');
    } else {
      mess = this.translationService.getTranslation('notifications.UpdateRecordSucceeded');
    }
    this.alertService.successMessage(mess);
  }

  private saveFailedHelper(usertype?: UserType) {
  }

  private buildForm() {
    this.userTypeForm = this.formBuilder.group({
      searchName: null,
      user_type_id: null,
      title:null,
      text: null,
      descr: null,
      admin_rights: null,
      guser_type: null
      //userrights: null,
      //accessrights: null
    });
  }

  private resetForm(stopEditing = false) {
    if (stopEditing) {
      this.isEditMode = false;
    }
    this.userTypeForm.reset({
      searchName: null,
      user_type_id: null,
      title: null,
      descr: null,
      text: null,
      admin_rights: '1', // Default to 'No' if applicable
      guser_type: '1' // Default to 'No' if applicable
      //userrights: null,
      //accessrights: null
    });

  }

  public confirmDelete(userType: UserType) {
    let delQ = this.translationService.getTranslation('notifications.DeleteRecord');
    this.alertService.showDialog(delQ + '?',
      DialogType.confirm, () => this.deleteUserType(userType));
  }

  deleteUserType(userType: UserType) {
    this.userTypeService.deleteUserType(userType.user_type_id)
      .subscribe(results => {
        var mess = this.translationService.getTranslation('notifications.DeleteRecordSucceeded');
        this.alertService.successMessage(mess);
        this.loadData();
      },
        error => {
        });
  }

  //addRow() {
  //  this.adding = true;
  //  const newRow: UserTypeUright = {
  //    user_type_id: -1,
  //    guiId: null,
  //    description: null,
  //    readRight: null,
  //    writeRight: null,
  //    isEdit: true
  //  }
  //}
  cancel(): void {
    this.isNewUserType = false;
    this.resetForm();
    this.usertype = this.userTypes[this.currentIndex];
    this.updateFormValues();
    this.loadData();
  }

  get canManageUserTypes() {
    return true; //this.accountService.userHasPermission(Permission.manageRolesPermission);
  }
}





