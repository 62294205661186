
import { Component, OnDestroy, Input, ViewChild, OnChanges } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { AuthService } from '../../services/auth.service';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { AppTranslationService } from '../../services/app-translation.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { PersonAuxDataService } from '../../services/personauxdata.service';
import { BaseTable } from '../../models/basetable.model';

@Component({
  selector: 'app-personauxdata-editor',
  templateUrl: './personauxdata-editor.component.html',
  styleUrls: ['./personauxdata-editor.component.scss']
})
export class PersonAuxDataEditorComponent implements OnChanges {
  @ViewChild('form', { static: true })
  private form: NgForm;

  public isNewTable = false;
  private onTableSaved = new Subject<string>();

  @Input() baseTable: BaseTable = new BaseTable();
  @Input() tableNumber: number;
  @Input() createNew: boolean;
  @Input() isEditMode = true;

  personAuxdataForm: FormGroup;
  tableSaved$ = this.onTableSaved.asObservable();

  get tablenumber() {
    return this.personAuxdataForm.get('tablenumber');
  }

  get number() {
    return this.personAuxdataForm.get('number');
  }

  get description() {
    return this.personAuxdataForm.get('description');
  }
   
  get floatLabels(): string {
    return this.isEditMode ? 'auto' : 'always';
  }

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private personauxdataService: PersonAuxDataService,
    private translationService: AppTranslationService,
    private formBuilder: FormBuilder
  ) {
    this.buildForm();
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.baseTable) {
      this.isNewTable = false;
    } else {
      this.isNewTable = true;
      this.baseTable = new BaseTable();
    }
    this.resetForm();
  }

  public save() {
    if (!this.form.submitted) {
      this.form.onSubmit(null);
      return;
    }

    if (!this.personAuxdataForm.valid) {
      /*      this.alertService.showValidationError();*/
      return;
    }

    var editedPersonAuxDataProject = this.getEditedPersonAuxData();
    editedPersonAuxDataProject.number = Number(editedPersonAuxDataProject.number);
    editedPersonAuxDataProject.tableNumber = Number(editedPersonAuxDataProject.tableNumber);

    if (this.isNewTable) {
      this.personauxdataService.newBaseTable(editedPersonAuxDataProject)
        .subscribe(response => this.saveSuccessHelper(0, response),
          error => {
            if (error == "Unauthorized")
              this.authService.logout();
            else
              this.saveFailedHelper(error);
          });
  }
    else {
      this.personauxdataService.updateBaseTable(editedPersonAuxDataProject).subscribe(
        response => this.saveSuccessHelper(1, response),
        error => {
          if (error == "Unauthorized")
            this.authService.logout();
          else
            this.saveFailedHelper(error);
        });
    }
  }

  private getEditedPersonAuxData(): BaseTable {
    const formModel = this.personAuxdataForm.value;

    return {
      number: formModel.number,
      numberStr: null,
      description: formModel.description,
      tableNumber: formModel.tablenumber,
      extraDate: null,
      extraNumber:null
    };
  }

  private saveSuccessHelper(eType: number, mess: string) {
    if (eType == 0)
      mess = this.translationService.getTranslation('notifications.InsertRecordSucceeded');
    else
      mess = this.translationService.getTranslation('notifications.UpdateRecordSucceeded');
    this.alertService.successMessage(mess);
    this.onTableSaved.next(mess);
  }

  private saveFailedHelper(mess: string) {
    //this.alertService.errorMessage(mess);
    this.onTableSaved.next(mess);
  }

  private cancel() {
    this.resetForm();

    //this.alertService.resetStickyMessage();
  }

  private buildForm() {
    this.personAuxdataForm = this.formBuilder.group({
      tablenumber: [''],
      number: ['', Validators.required],
      description: ['', Validators.required]
    });
  }

  private resetForm(stopEditing = false) {
    if (stopEditing) {
      this.isEditMode = false;
    } 

    if (!this.baseTable) {
      this.isNewTable = true;
      this.baseTable = new BaseTable();
    }

    this.personAuxdataForm.reset({
      tablenumber: this.tableNumber,
      number: this.baseTable.number || '',
      description: this.baseTable.description
    });
  }

  get canManageAuxData() {
    return true; //this.accountService.userHasPermission(Permission.manageRolesPermission);
  }
}
