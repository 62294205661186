import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';
import { OrganizationService } from '../../services/organization.service';
import { Organization } from 'src/app/models/organization.model';
import { DataService } from '../../services/data.service';
import { LocalStoreManager } from '../../services/local-store-manager.service';

@Component({
  selector: 'organizationtree',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})

export class OrganizationComponent implements OnInit {
  dropdownEnabled = true;
  orgTree: TreeviewItem[] = [];
  organizations: Organization[] = [];
  newOrganization: Organization[] = [];
  values: number[];

  config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: false,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 400
  });

  @ViewChild('f', { static: false })
  private form;
  public formResetToggle = true;
  public changesSavedCallback: () => void;
  public changesCancelledCallback: () => void;

  constructor(
    public dataservice: DataService,
    private organizationservice: OrganizationService,
    public localStorage: LocalStoreManager,
    public dialogRef: MatDialogRef<OrganizationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.getOrganizationTree();
    this.config.hasAllCheckBox = this.localStorage.getDataObject("isadmin");
  }

  public getOrganizationTree() {
    this.organizationservice.getOrganization()
      .subscribe(results => {
        this.organizations = results;
        this.fillOrganizationTree();
      },
        (error: any) => {
          console.log(error);
        });
  }

  fillOrganizationTree() {
    let len = this.organizations.length;
    let companyNumber = -1;
    let companyIndex = -1;
    let factoryIndex = -1;
    let departmentIndex = -1;
    let sectionIndex = -1;
    let factoryNumber = -1;
    let departmentNumber = -1;
    let sectionNumber = -1;
    for (let index = 0; index < len; index++) {
      if (this.organizations[index].type == 1) {
        if (this.organizations[index].companyNumber != companyNumber) {
          companyIndex = companyIndex + 1;
          companyNumber = this.organizations[index].companyNumber;
          this.orgTree[companyIndex] = new TreeviewItem({
            text: companyNumber + ' ' + this.organizations[index].description,
            value: this.organizations[index].id,
            disabled: (this.organizations[index].isAllowed == 2),
            children: [{ text: "", value: 0 }]
          });
          this.orgTree[companyIndex].children.pop();
          factoryIndex = -1;
          factoryNumber = -1;
        }
      }
      else if (this.organizations[index].type == 2) {
        if (this.organizations[index].factoryNumber != factoryNumber) {
          factoryNumber = this.organizations[index].factoryNumber;
          this.orgTree[companyIndex].children.push(new TreeviewItem({
            text: factoryNumber + ' ' + this.organizations[index].description,
            value: this.organizations[index].id,
            disabled: (this.organizations[index].isAllowed == 2),
            children: [{ text: "", value: 0 }]
          }));
          factoryIndex = factoryIndex + 1;
          this.orgTree[companyIndex].children[factoryIndex].children.pop();
          departmentIndex = -1;
          departmentNumber = -1;
        }
      }
      else if (this.organizations[index].type == 3) {
        if (this.organizations[index].departmentNumber != departmentNumber) {
          departmentNumber = this.organizations[index].departmentNumber;
          this.orgTree[companyIndex].children[factoryIndex].children.push(new TreeviewItem({
            text: departmentNumber + ' ' + this.organizations[index].description,
            value: this.organizations[index].id,
            disabled: (this.organizations[index].isAllowed == 2),
            children: [{ text: "", value: 0 }]
          }));
          departmentIndex = departmentIndex + 1;
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children.pop();
          sectionIndex = -1;
          sectionNumber = -1;
        }
      }
      else if (this.organizations[index].type == 4) {
        if (this.organizations[index].sectionNumber != sectionNumber) {
          sectionNumber = this.organizations[index].sectionNumber;
          this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children.push(new TreeviewItem({
            text: sectionNumber + ' ' + this.organizations[index].description,
            value: this.organizations[index].id,
            disabled: (this.organizations[index].isAllowed == 2)
          }));
          sectionIndex = sectionIndex + 1;
        }
      }
    }
  }

  public getAndFillOrganizationTree() {
    this.organizationservice.getOrganization()
      .subscribe(results => {
        this.organizations = results;
        this.fillOrganizationTree();
 /*       this.save();*/
      },
        (error: any) => {
          console.log(error);
        });
  }

  public cancel() {
    this.dialogRef.close();
  }

  public save() {
    this.getEditedTree();
    this.dataservice.editedOrganization = this.newOrganization;
    ////this.resetForm();
    //if (this.changesSavedCallback)
    //  this.changesSavedCallback();
    this.dialogRef.close();
/*    this.dialogRef.close(this.form.value);*/
  }

  getEditedTree() {
    this.newOrganization.length = 0;
    let complen = this.orgTree.length;
    let listIndex = 0;
    let companyNumber = 0;
    let factoryNumber = 0;
    let departmentNumber = 0;
    let sectionNumber = 0;
    for (let companyIndex = 0; companyIndex < complen; companyIndex++) {
      companyNumber = this.orgTree[companyIndex].value;
      if (this.orgTree[companyIndex].checked && !this.orgTree[companyIndex].disabled) {
        this.newOrganization[listIndex] = {
          type: 1, companyNumber: companyNumber, factoryNumber: null, departmentNumber: null, sectionNumber: null, description: null, isAllowed: 1, id: 0, user: null, system: null, showSvHierarchy: false
        };
        listIndex++;
      }
      if (this.orgTree[companyIndex].children != null) {
        let factorylen = this.orgTree[companyIndex].children.length;
        for (let factoryIndex = 0; factoryIndex < factorylen; factoryIndex++) {
          factoryNumber = this.orgTree[companyIndex].children[factoryIndex].value;
          if (this.orgTree[companyIndex].children[factoryIndex].checked && !this.orgTree[companyIndex].children[factoryIndex].disabled) {
            this.newOrganization[listIndex] = {
              type: 2, companyNumber: companyNumber, factoryNumber: factoryNumber, departmentNumber: null, sectionNumber: null, description: null, isAllowed: 1, id: 0, user: null, system: null, showSvHierarchy: false
            };
            listIndex++;
          }
          if (this.orgTree[companyIndex].children[factoryIndex].children != null) {
            let departmentlen = this.orgTree[companyIndex].children[factoryIndex].children.length;
            for (let departmentIndex = 0; departmentIndex < departmentlen; departmentIndex++) {
              departmentNumber = this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].value;
              if (this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].checked && !this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].disabled) {
                this.newOrganization[listIndex] = {
                  type: 3, companyNumber: companyNumber, factoryNumber: factoryNumber, departmentNumber: departmentNumber, sectionNumber: null, description: null, isAllowed: 1, id: 0, user: null, system: null, showSvHierarchy: false
                };
                listIndex++;
              }
              if (this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children != null) {
                let sectionlen = this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children.length;
                for (let sectionIndex = 0; sectionIndex < sectionlen; sectionIndex++) {
                  sectionNumber = this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children[sectionIndex].value;
                  if (this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children[sectionIndex].checked && !this.orgTree[companyIndex].children[factoryIndex].children[departmentIndex].children[sectionIndex].disabled) {
                    this.newOrganization[listIndex] = {
                      type: 4, companyNumber: companyNumber, factoryNumber: factoryNumber, departmentNumber: departmentNumber, sectionNumber: sectionNumber, description: null, isAllowed: 1, id: 0, user: null, system: null, showSvHierarchy: false
                    };
                    listIndex++;
                  }
                }
              }
            }
          }
        }
      }
    }
  }


  resetForm(replace = false) {
    if (!replace) {
      this.form.reset();
    }
    else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }
}
