import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AlertService, DialogType } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { ProjectService } from '../../services/project.service';
import { ProjectPerson } from '../../models/projectperson.model';
import { ProjectData } from '../../models/projectdata.model';
import { EditProjectPersonDialogComponent } from './edit-projectperson-dialog.component';
import { FormControl, FormGroup } from '@angular/forms';
import { LocalStoreManager } from '../../services/local-store-manager.service';

@Component({
  selector: 'projectperson-list',
  templateUrl: './projectperson-list.component.html',
  styleUrls: ['./projectperson-list.component.scss']
})
export class ProjectPersonListComponent implements OnInit {

  displayedColumns: string[] = ['personName', 'startDate', 'endDate', 'targetTime', 'actions'];

  public projectpersons: MatTableDataSource<ProjectPerson>;
  projectper: ProjectPerson;
  editingProjectName: { name: string };
  projectdatas: ProjectData[] = [];
  public show: boolean = true;
  defaultPageIndex: number = 0;
  defaultPageSize: number = 10;
  public defaultSortColumn: string = "number";
  public defaultSortOrder: string = "asc";
  projectData: FormGroup;
  projectId: number;
  rights: number = 0;
  writeRights: number = 0;
  writeRight: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private projectService: ProjectService,
    private dialog: MatDialog,
    public localStorage: LocalStoreManager
  ) {
    //this.dataSource = new MatTableDataSource();
    this.projectData = new FormGroup({
      project: new FormControl()
    });
  }

  get canManageProjectPersons() {
    return true;
  }

  get canInsertPersons() {
    return this.projectId != null;
  }

  ngOnInit() {
    this.getProjects();
    this.rights = this.localStorage.getDataObject("readrights");
    this.writeRights = this.localStorage.getDataObject("userrights");
    this.writeRight = (this.writeRights & 4096) == 4096;
  }

  getProjects() {
    this.projectService.getProjects("0")
      .subscribe(results => {
        this.projectdatas = results;
      },
        (error: any) => {
          console.log(error);
        });
  }

  projectChange(value) {
    this.projectId = value;
    this.loadPersonData(value);
  }

  loadPersonData(Id: number) {
    let projId = Id.toString();
    this.projectService.getProjectPerson(projId)
      .subscribe(results => {
        this.projectpersons = new MatTableDataSource<ProjectPerson>(results);
      },
        error => {
          console.log(error);
        });
  }

  public createProjectPerson(projectperson?: ProjectPerson) {
    projectperson = new ProjectPerson();
    const dialogRef = this.dialog.open(EditProjectPersonDialogComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { projectperson, projectNumber: this.projectId }
      });
    dialogRef.afterClosed().subscribe(r => {
      if (r && this.canManageProjectPersons) {
        this.projectService.newProjectPerson(r);
      }
      this.loadPersonData(this.projectId);
    });
  }

  public editProjectPerson(projectperson?: ProjectPerson) {
    projectperson.projectNumber = this.projectId;
    console.log("EDIT " + projectperson);
    const dialogRef = this.dialog.open(EditProjectPersonDialogComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { projectperson, projectNumber: projectperson.projectNumber }
      });
    dialogRef.afterClosed().subscribe(r => {
      if (r && this.canManageProjectPersons) {
        this.projectService.updateProjectPerson(r);
      }
      this.loadPersonData(this.projectId);
    });
  }

  public confirmDeleteProjectPerson(projectperson: ProjectPerson) {
    let delQ = this.translationService.getTranslation('notifications.DeleteProjectPerson');
    this.alertService.showDialog(delQ + '?',
      DialogType.confirm, () => this.deleteStamp(projectperson));
  }

  deleteStamp(project) {
    let id = project.id;
    this.projectService.deleteProjectPerson(id)
      .subscribe(results => {
        this.loadPersonData(this.projectId);
      },
        error => {
        });
  }
}

