<h1 mat-dialog-title>{{employeeName ? ('employee.EditEmployee' | translate) : ('employee.NewEmployee' | translate)}}</h1>
<mat-tab-group (selectedTabChange)="onTabChanged($event)" dynamicHeight>
  <mat-tab label="personEditor">
    <ng-template mat-tab-label>
      {{'menu.EmployeeBase' | translate}}
    </ng-template>
    <div class="example-big-box mat-elevation-z4">
      <div mat-dialog-content>
        <app-persons-editor #personsEditor [employee]="data.employee" [wr]="data.wr" (messageEvent)="receiveMessage($event)"></app-persons-editor>
      </div>
      <div mat-dialog-actions>
        <button mat-raised-button (click)="personsEditor.savePerson();" [disabled]="(!writeRight || (!personsEditor.employeeForm.dirty && !isNewRecord))" color="primary"> {{'common.Save' | translate}}</button>
        <button mat-raised-button (click)="cancel()" color="warn"> {{'common.Cancel' | translate}}</button>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="personTaEditor" [disabled]="isTab1Disabled">
    <ng-template mat-tab-label>
      {{'menu.EmployeeTA' | translate}}
    </ng-template>
    <div class="example-big-box mat-elevation-z4">
      <div mat-dialog-content>
        <app-persons-taeditor #personsTaEditor [employee]="data.employee"></app-persons-taeditor>
      </div>
      <div mat-dialog-actions>
        <button mat-raised-button (click)="personsTaEditor.saveTa();" [disabled]="!writeRight || !personsTaEditor.employeeTaForm.dirty" color="primary"> {{'common.Save' | translate}}</button>
        <button mat-raised-button (click)="cancel()" color="warn"> {{'common.Cancel' | translate}}</button>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="personCalEditor" [disabled]="isTab2Disabled">
    <ng-template mat-tab-label>
      {{'menu.EmployeeCalendar' | translate}}
    </ng-template>
    <div class="example-small-box mat-elevation-z4">
      <div mat-dialog-content>
        <app-persons-calendarlist #personsCalendarList [employee]="data.employee" [wr]="data.wr"></app-persons-calendarlist>
      </div>
      <div mat-dialog-actions>
        <button mat-raised-button (click)="cancel()" color="warn"> {{'common.Cancel' | translate}}</button>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>




